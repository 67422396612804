import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import NavBar from '../Component/NavBar'
import TitleBanner from '../Component/TitleBanner'
import axios from 'axios'
import { hostname } from '../App'

const Modelshowing = (props) => {
    let { brand } = useParams()
    let navigate = useNavigate()
    let [model, setModel] = useState()
    useEffect(() => {
        axios.get(`${hostname}/api/model/${brand}`).then((response) => {
            // console.log(response.data);
            setModel(response.data.sort((a,b)=>{
                if(a.modelName<b.modelName) return -1
                if(a.modelName>b.modelName) return 1
                return 0
            }))
        }).catch((error) => {
            // console.log(error);
        })
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='min-h-[70vh]'>
            <NavBar />
            {model != undefined && model.length > 0 ?
                <>
                    <h4 className='text-center'>Model in the {brand} </h4>
                    <div className='flex flex-wrap gap-3 my-10 justify-between container mx-auto'>
                        {model.map((x) => {
                            // console.log(x);
                            return (
                                <button onClick={() => navigate(`/availablemodels/${brand}/${x._id}`)} className='w-[400px] relative 
                                hover:scale-[1.05] duration-500 transition shadow rounded-2xl p-3 mx-auto '>
                                    {x.newStatus && <div className="absolute top-0 w-14 left-0 ">
                                        <img src={require('../Assest/popularBand.png')} alt="Band" />
                                        <p className='m-0 absolute top-4 text-white  text-whit left-1 text-xs -rotate-[41deg] '>NEW </p>
                                    </div>}
                                    {brand} {x.modelName}
                                </button>
                            )
                        })
                        }
                    </div>
                </> :
                <div className='h-[40vh] w-full  flex flex-col'>
                    <p className='m-auto text-2xl '>No models available in this Brand !!!! </p>
                    <button onClick={() => {
                        // console.log((sessionStorage.getItem('nav')));
                        navigate(`/category/${sessionStorage.getItem('nav')}`)
                    }
                    } className='p-2 px-3 mx-auto w-4/6 rounded bg-red-600 text-white'>Go Back</button>
                </div>
            }
        </div>
    )
}

export default Modelshowing
