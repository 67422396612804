import React, { useContext, useEffect, useState } from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { Storage } from '../Context/StateStore'
import ProfileIcon from '../SVG/ProfileIcon'
import UserLogin from '../User/Login'

const NavBar = () => {
  let [show, setshow] = useState(false)
  let { wishList, getCartList, userSet, Allcaetgory, setshowmain, cartList } = useContext(Storage)
  let navigate = useNavigate()
  let user = JSON.parse(localStorage.getItem('user'))
  useEffect(() => {
    getCartList()
    userSet()
  }, [])
  return (
    <div className='bg-white pt-lg-0 sticky-top poppins'>
      <Navbar key="lg" expand="lg" className=" sticky-top h-[10vh] ">
        <Container fluid className=''>
          <Navbar.Brand className='order-2 order-lg-0 mx-auto mx-lg-0 w-fit poppins' onClick={() => navigate('/')}>
            <img src={require('../Assest/DarkPrintLe.png')} className='cursor-pointer' alt="logo" width={170} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <div className='transtionuser'>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="start" className="transtionuser"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                  <img onClick={() => {
                    navigate('/');

                  }} src={require('../Assest/DarkPrintLe.png')} className='cursor-pointer' alt="logo" width={170} />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className='fw-semibold sm:h-[40px] fs-6 fontfam1 lg:w-[60vw]'>
                  <Nav className="justify-content-around scrolledit overflow-x-scroll gap-2 flex-grow-1 pe-3">
                    <Nav.Link onClick={() => navigate('/')} >Home</Nav.Link>
                    <Nav.Link onClick={() => navigate('/shop')}>Shop</Nav.Link>
                    <Nav.Link className='d-lg-none ' onClick={() => navigate('/wishlist')}>WishList</Nav.Link>
                    {
                      Allcaetgory != undefined && Allcaetgory.length > 0 ?

                        [...Allcaetgory].map((x, index) => {
                          return (
                            <Nav.Link key={index} className='sm:text-center sm:min-w-[150px] mx-1 ' onClick={() => navigate(`/category/${x.category}`)}>
                              {x.category}
                            </Nav.Link>
                          )
                        }) : ""
                    }
                  </Nav>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
          <div className='order-3 flex order-lg-0'>
            {/* Search */}
            {/* <div className='mx-3 d-none d-lg-block cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg></div> */}
            {/* Profile */}
            <div className='mx-3 d-none d-lg-block cursor-pointer'>
              {user ? <button onClick={() => navigate('/profile')}>
                <ProfileIcon size={25} />
              </button> : <>
                <button onClick={() => setshowmain(true)} className=''>Log In</button>

              </>}
            </div>
            {/* Cart */}
            <div onClick={() => navigate('/cartlist')} className='mx-3 relative cursor-pointer'>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-cart4" viewBox="0 0 16 16">
                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
              </svg>
              {cartList != undefined && cartList.length > 0 &&
                <span className='badge absolute -top-2 -right-2 bg-red-500 rounded-full text-white'>{cartList.length} </span>}
            </div>
            {/* Wishlist */}
            <div onClick={() => navigate('/wishlist')} className='mx-3 relative d-none d-lg-block cursor-pointer' >
              <span className={`badge text-sm text-center absolute -top-2 -right-2  bg-red-500 rounded-full text-white ${wishList != undefined && wishList.length > 0 ? '' : 'd-none'}`}> {wishList != undefined && wishList.length > 0 ? wishList.length : ''} </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi  bi-star" viewBox="0 0 16 16">
                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
              </svg>
            </div>
          </div>
        </Container>
      </Navbar>
      <Offcanvas show={show}>

      </Offcanvas>
    </div>
  )
}

export default NavBar