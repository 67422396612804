import React, { useContext } from "react";
import Slider from "react-slick";
import { categories } from "./Data";
import { Storage } from "../Context/StateStore";
import { useNavigate } from "react-router";

const HomeCategory = () => {
  const { Allcaetgory } = useContext(Storage)
  let navigate = useNavigate()
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    arrows: "false",
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col min-h-[70vh] ">
      {
        Allcaetgory != undefined ? <>
         
          {/* Rating */}
          <div className="xl:min-h-[74vh] my-10 xl:my-0">
            {/* <div className="w-fit my-5 flex flex-col flex-sm-row flex-wrap gap-2 items-center poppins mx-auto">
              <p className="fw-semibold w-fit mx-auto mb-0">Great</p>
              <img className="w-24 h-fit" src={require('../Assest/rating (2).png')} alt="" />
              <p className="mb-0">2,564 reviews from the costumer</p>
            </div> */}

            <div className="container px-5">
              {Allcaetgory.length >= 3 && <Slider {...settings} className=" my-5">
                {Allcaetgory.map((data) => {
                  return (
                    <div onClick={() => navigate(`/category/${data.category}`)} style={{ backgroundColor: 'rgb(218,52,246)' }}
                      className="cursor-pointer w-100   rounded-3xl relative
                           h-[20rem] hover:scale-105 shadow-xl transition duration-500 border-1 overflow-hidden  flex ">
                      <img src={data.image} className="w-100 h-[20rem]  object-contain rounded-3xl" alt="image" />
                      <div className="absolute  bg-transparent flex flex-col  justify-end p-6 top-0 w-100 h-100 ">

                        <button className="px-4 w-fit p-3 bg-violet-500 text-slate-50 rounded-full">Explore more </button>
                      </div>
                    </div>
                  );
                })}
              </Slider>}
              {
                Allcaetgory.length < 3 &&
                <div className="flex justify-around ">
                  {Allcaetgory.map((data) => {
                    return (
                      <div onClick={() => navigate(`/category/${data.category}`)}
                        className="cursor-pointer w-[20rem] rounded-3xl relative
                           h-[20rem] hover:scale-105 shadow-xl transition duration-500 border-1 overflow-hidden  flex ">
                        <img src={data.image} className="w-[20rem] h-[20rem]  object-contain rounded-3xl" alt="image" />
                        <div className="absolute  bg-transparent flex flex-col  justify-end p-6 top-0 w-100 h-100 ">
                          <button className="px-4 w-fit p-3 bg-violet-500 text-slate-50 rounded-full">Explore more </button>
                        </div>
                      </div>
                    );
                  })}

                </div>
              }

            </div>
          </div>
        </> : ""
      }
    </div>
  );
};

export default HomeCategory;
