import React, { useContext, useEffect, useState } from 'react'
import TitleBanner from '../Component/TitleBanner'
import { useNavigate, useParams } from 'react-router'
import NavBar from '../Component/NavBar'
import axios from 'axios'
import { hostname } from '../App'
import Shop from './Shop'
import { Storage } from '../Context/StateStore'
import { logDOM } from '@testing-library/react'

const Brands = () => {
    const { category } = useParams()
    const {getBanners,banners }=useContext(Storage)
    const [brand, setbrands] = useState()
    let navigate=useNavigate()
    useEffect(() => {
        getBanners()
       if(category) { axios.get(`${hostname}/api/brandCategory/${category}`).then((response) => {
            setbrands(response.data)
            // console.log(response.data);
        }).catch((error) => {
            // console.log(error);
        })}
    }, [category])
   if(banners){
    var banner=[...banners].find((obj)=>obj.bannerName=='Shop')
   }
    return (
        <div className='poppins'>
            {
                category != undefined && brand!=undefined && brand.length>0  ? <>
                {/* category.toLowerCase() == 'mobile back covers' */}
                    <NavBar />
                   {banner&& <TitleBanner page={category} img={banner.imageUrl} />}
                    {
                        brand != undefined && brand.length > 0 ? <>

                            <h5 className='text-center my-10 text-3xl'>Brands under {category} </h5>
                            <div className='flex justify-around sm:justify-start container mx-auto my-10 flex-wrap gap-3'>
                                {brand.map((x) => {
                                    // console.log(x);
                                    return (
                                        <div onClick={()=>{navigate(`/availablemodels/${x.brand}`);sessionStorage.setItem('nav',category)}} 
                                        className='cursor-pointer hoverbtn border-1 rounded shadow border-slate-200'>
                                            <img className='w-[100px] h-[100px] object-contain ' src={x.image} alt="logo" />
                                        </div>
                                    )
                                })
                                }
                            </div> </>
                            : <div className='my-10'>
                               <p className='text-center'>No Brands Found  </p>
                            </div>
                    }

                </> : <Shop />
            }
        </div>
    )
}

export default Brands
