import React from 'react'
import {Routes,Route} from 'react-router-dom'
import AddressChoose from './AddressChoose'
const CheckOutRouter = () => {
  return (
    <div>
        <Routes>
            <Route path='/*' element={<AddressChoose/>} />
        </Routes>
      
    </div>
  )
}

export default CheckOutRouter
