import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Storage } from '../Context/StateStore'
import axios from 'axios'
import { hostname } from '../App'
const AddressModal = (props) => {
    const { aid, setaid } = props
    const { addressModal, setAddressModal, getAllAddress, addressArry, setAddressArry } = useContext(Storage)
    const [country, setcountry] = useState()
    const [addressDetails, setAddressDetails] = useState({
        userId: JSON.parse(localStorage.getItem('user')),
        fullName: null,
        line1: null,
        line2: null,
        phone: null,
        landMark: '',
        state: '',
        district: '',
        pincode: null,
        defaultStatus: false,
        country: 'India'
    })
    useEffect(() => {
        axios.get(`https://restcountries.com/v3.1/all`).then((response) => {
            const countryNames = response.data.map(country => country.name.common);
            // console.log(countryNames);
            setcountry(countryNames)
        }).catch((eror) => {
            // console.log(eror);
        })
        if (aid) {
            setAddressDetails(aid)
        }
    }, [aid])
    const handleChange = (e) => {
        const { value, name } = e.target;
        if (name === 'defaultStatus') {
            setAddressDetails((prev) => ({
                ...prev,
                [name]: !prev.defaultStatus
            }));
        } else {
            setAddressDetails((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    }
    let [errorMessage,setErrorMessage]=useState()
    let handleSave = (e) => {
        e.preventDefault()
        if (addressDetails.district && addressDetails.line1 && addressDetails.pincode && addressDetails.phone && addressDetails.state) {
            setErrorMessage('')
            axios.post(`${hostname}/api/userAddress`, addressDetails).then((response) => {
                // console.log(response.data);
                getAllAddress()
                setAddressModal(false)
                setAddressDetails({
                    userId: JSON.parse(localStorage.getItem('user')),
                    fullName: null,
                    line1: null,
                    line2: null,
                    phone: null,
                    landMark: '',
                    state: '',
                    district: '',
                    pincode: null,
                    defaultStatus: false,
                    country: 'India'
                })
            }).catch((error) => {
                // console.log(error);
            })
        }
        else{
            setErrorMessage("*Fill all the inputs")
        }
    }
    let handleUpdate=(e)=>{
        e.preventDefault()
        if (addressDetails.district && addressDetails.line1 && addressDetails.pincode && addressDetails.phone && addressDetails.state) {
            setErrorMessage('')
            axios.put(`${hostname}/api/userAddress/${aid._id}`, addressDetails).then((response) => {
                // console.log(response.data);
                
                getAllAddress()
                setAddressModal(false)
                setAddressDetails({
                    userId: JSON.parse(localStorage.getItem('user')),
                    fullName: null,
                    line1: null,
                    line2: null,
                    phone: null,
                    landMark: '',
                    state: '',
                    district: '',
                    pincode: null,
                    defaultStatus: false,
                    country: 'India'
                })
            }).catch((error) => {
                // console.log(error);
            })
        }
        else{
            setErrorMessage("*Fill all the inputs")
        }
       
    }
    return (
        <Modal centered show={addressModal} onHide={() => { setAddressModal(false); setaid(null) }}>
            <Modal.Header closeButton >
                <p className='text-xl fw-bold m-0'>  {aid != undefined ? "Edit the Address" : "Add the Address"}</p>
            </Modal.Header>
            <Modal.Body>
                <div className='mb-3'>
                    <p className='fw-semibold mb-1'> Country : </p>
                    <input type="text" onChange={handleChange} value={addressDetails.country} name='country' className='outline-none block w-full rounded p-1 border-1 ' />
                </div>
                <div className='my-3'>
                    <p className='fw-semibold mb-1'>Full Name : <span className='text-red-600'>* </span> </p>
                    <input type="text" onChange={handleChange} value={addressDetails.fullName} name='fullName' className='outline-none block w-full rounded p-1 border-1 ' />

                </div>
                <div className='my-3'>
                    <p className='fw-semibold mb-1'>Phone : <span className='text-red-600'>* </span>  </p>
                    <input type="number" onChange={handleChange} value={addressDetails.phone} name='phone' className='outline-none block w-full rounded p-1 border-1 ' />
                </div>
                <div className='my-3'>
                    <p className='fw-semibold mb-1'>Address Line 1 : <span className='text-red-600'>* </span>  </p>
                    <input type="text" onChange={handleChange} value={addressDetails.line1} name='line1' className='outline-none block w-full rounded p-1 border-1 ' />
                </div>
                <div className='my-3'>
                    <p className='fw-semibold mb-1'> Address Line 2 : </p>
                    <input type="text" onChange={handleChange} value={addressDetails.line2} name='line2' className='outline-none block w-full rounded p-1 border-1 ' />
                </div>
                <div className='my-3'>
                    <p className='fw-semibold mb-1'> LandMark : </p>
                    <input type="text" onChange={handleChange} value={addressDetails.landMark} name='landMark' className='outline-none block w-full rounded p-1 border-1 ' />
                </div>
                <div className='my-3'>
                    <p className='fw-semibold mb-1'>City / District :  <span className='text-red-600'>* </span> </p>
                    <input type="text" onChange={handleChange} value={addressDetails.district} name='district' className='outline-none block w-full rounded p-1 border-1 ' />
                </div>
                <div className='my-3'>
                    <p className='fw-semibold mb-1'> State / Province / Region : <span className='text-red-600'>* </span>  </p>
                    <input type="text" onChange={handleChange} value={addressDetails.state} name='state' className='outline-none block w-full rounded p-1 border-1 ' />
                </div>
                <div className='my-3'>
                    <p className='fw-semibold mb-1'>Pincode :  <span className='text-red-600'>* </span> </p>
                    <input type="number" onChange={handleChange} value={addressDetails.pincode} name='pincode' className='outline-none block w-full rounded p-1 border-1 ' />
                </div>
               <p className='text-red-600 '>{errorMessage} </p>

                <input type="checkbox" checked={addressDetails.defaultStatus} onChange={handleChange} value={addressDetails.defaultStatus} name='defaultStatus' id='defaultaddress' className='  m-2' />
               
               
                <label htmlFor="defaultaddress"> Make this as a Default Address </label>
                <button onClick={handleSave} className={`p-2 px-3 bg-yellow-500 shadow shadow-yellow-400 text-white rounded block my-2 ${aid ? 'd-none' : ''}`}> Save</button>
                <button onClick={handleUpdate} className={`p-2 px-3 bg-yellow-500 shadow shadow-yellow-400 text-white rounded block my-2 ${aid ? '' : 'd-none'}`}> Update</button>

            </Modal.Body>
        </Modal>
    )
}

export default AddressModal
