import React, { useContext, useLayoutEffect } from 'react'
import NavBar from '../Component/NavBar'
import { useRef, useEffect, useState } from 'react';

import { Stage, Layer, Image, Transformer, Text } from 'react-konva';
import { useParams } from 'react-router';
import StateStore, { Storage } from '../Context/StateStore';
import axios from 'axios';
import { hostname } from '../App';
import UserLogin from '../User/Login';
import MessageCom from '../Component/MessageCom';
const Personalize = (props) => {
    const { id } = useParams()
    const {getCartList, setshowmain ,messageDetails,setMessageDetails} = useContext(Storage)
    const [selectedProduct, setSelectedProduct] = useState()
    const [template, setTemplate] = useState()
    const stageRef = useRef(null);
    const [uploadImageUrl, setuploadImageUrl] = useState(null);
    const [textInput, setTextInput] = useState(''); // State to hold user input text
    const uploadImageRef = useRef(null);
    const textRef = useRef(null);
    const trRef = useRef(null);
    const [uploadedImageFile,setuploadedImageFile]=useState([])
    const symboluploadImageRef = useRef(null);
    const [selectedId, selectShape] = useState(null);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const cameraRef = useRef(null);
    const [colorchange, setColor] = useState('#000'); // Initial color state
    const [textStyle, setTextStyle] = useState({
        bold: '',
        style: '',
        size: 36,
    })
   
    useEffect(() => {
        window.scrollTo(0,0)
        axios.get(`${hostname}/api/productID?id=${id}`).then((response) => {
            setSelectedProduct(response.data)
            setTemplate(response.data.template)
            // console.log(response.data);
            
        }).catch((error) => {
            // console.log(error);
        })
    }, [id])
    // // Handler function to update color state when a color is picked
    const handleColorChange = (newColor) => {
        setColor(newColor.hex); // Update color state with the new color
    };
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setuploadedImageFile((prev)=>[...prev,e.target.files[0]])
        const reader = new FileReader();
        reader.onload = function (event) {
            setuploadImageUrl(event.target.result);
            // setuploadImageUrl(selectedProduct.uploadImageUrl[0])
            // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
    };
    const handleSave = () => {
        const stage = stageRef.current.getStage();
        const dataURL = stage.toDataURL();
        // console.log(dataURL);
        // setDownloadUrl(dataURL);
        const userid = JSON.parse(localStorage.getItem('user'))
        const formData=new FormData()
        formData.append('upload',uploadedImageFile[0])
        formData.append('userId',userid)
        formData.append('productId',id)
        formData.append('previewImage',dataURL)
        formData.append('quantity',1)
        if (userid ) {
            if( uploadedImageFile.length>0){
                // console.log(uploadedImageFile);
            axios.post(`${hostname}/api/cart`, formData).then((response) => {
                // console.log(response.data);
                getCartList()
                setMessageDetails({
                    visible:true,
                    message : "Your Customized Mobile case has been Added to the Cart",
                    title:'',
                    page:'Customization',
                    status:'success'
                })
            }).catch((error) => {
                // console.log(error);
                setMessageDetails({
                    visible:true,
                    message : "There has been a error in server",
                    title:'',
                    page:'Customization',
                    status:'error'
                })
            })
        }else{
            setMessageDetails({
                visible:true,
                message : "Upload a Image Before Save",
                title:'',
                page:'Customization',
                status:'error'
            })
        }
        }
        else {
            setshowmain(true)
        }
    };
    const handleTextChange = (e) => {
        const text = e.target.value;
        setTextInput(text);
        if (textRef.current) {
            textRef.current.text(text);
            stageRef.current.batchDraw();
        }
    };


    useEffect(() => {
        if (selectedProduct && selectedProduct.modelName.image) {
            const cameraImage = new window.Image();
            cameraImage.src = selectedProduct.modelName.image;
            cameraImage.crossOrigin = 'Anonymous';

            cameraImage.onload = () => {
                // console.log("cameraRef loaded successfully:", cameraImage);
                cameraRef.current.image(cameraImage);
                stageRef.current.batchDraw();
                const originalWidth = cameraImage.naturalWidth;
                const originalHeight = cameraImage.naturalHeight;
                const targetWidth = selectedProduct.modelName.width;
                const targetHeight = (targetWidth / originalWidth) * originalHeight;
                cameraRef.current.height(targetHeight);
            };
            cameraImage.onerror = (error) => {
                console.error("Error loading second image:", error);
            };
        }
    }, [selectedProduct, uploadImageUrl, textInput]);

    useEffect(() => {
        if (template && template.symbolImageData) {
            const symbolImage = new window.Image();
            symbolImage.src = template.symbolImageData[0].image;
            symbolImage.crossOrigin = 'Anonymous';

            symbolImage.onload = () => {
                // console.log("Symbol image loaded successfully:", symbolImage);
                symboluploadImageRef.current.image(symbolImage);
                stageRef.current.batchDraw();
                const originalWidth = symbolImage.naturalWidth;
                const originalHeight = symbolImage.naturalHeight;
                const targetWidth = template.symbolImageData[0].width;
                const targetHeight = (targetWidth / originalWidth) * originalHeight;
                symboluploadImageRef.current.height(targetHeight)
                //    setsymbolheight(targetHeight)
            };
            symbolImage.onerror = (error) => {
                console.error("Error loading second image:", error);
            };
        }
    }, [template, uploadImageUrl, textInput,]);
    useEffect(() => {
        try {
            if (uploadImageUrl) {
                // console.log(uploadImageUrl);
                const imageuploaded = new window.Image();
                imageuploaded.crossOrigin = 'Anonymous';
                imageuploaded.src = uploadImageUrl;
                imageuploaded.onload = () => {
                    // uploadImageRef.current.image(image);
                    // stageRef.current.batchDraw();
                    // console.log(imageuploaded);
                    uploadImageRef.current.image(imageuploaded);
                    stageRef.current.batchDraw();
                    const cameraImageWidth = 320;
                    const cameraImageHeight = (cameraImageWidth / imageuploaded.width) * imageuploaded.height;
                    // Set the second image width and height
                    uploadImageRef.current.width(cameraImageWidth);
                    uploadImageRef.current.height(cameraImageHeight);

                };
            }
        } catch (error) {
            // console.log(error);
        }
    }, [uploadImageUrl, textInput, selectedId]);

    useEffect(() => {
        
        if (trRef.current) {
            if (selectedId) {
                const selectedNode = selectedId === 'image' ? uploadImageRef.current :
                    (selectedId === 'text' ? textRef.current : null);
                if (selectedNode) {
                    trRef.current.nodes([selectedNode]);
                }
            } else {
                // If selectedId becomes null, clear nodes to deselect the area
                trRef.current.nodes([]);
            }
            const layer = trRef.current.getLayer();
            if (layer) {
                layer.batchDraw();
            }
        }
    }, [selectedId]);

    return (
        <>
        <NavBar />
        <div className='min-h-[100vh] font-serif row container mx-auto'>
            
            {
                selectedProduct != undefined && selectedProduct != null && <>


                    <div className='col-lg-6 m-auto'>
                        <div className='w-[320px] h-[650px] border-3 border-slate-900 shadow  
        rounded-3xl relative overflow-hidden'>
                            <Stage
                                width={320}
                                height={650}
                                className=''
                                onMouseDown={(e) => {
                                    // deselect when clicked on empty area
                                    const clickedOnEmpty = e.target === e.target.getStage();
                                    if (clickedOnEmpty) {
                                        selectShape(null);
                                    }
                                }}
                                ref={stageRef}
                            >
                                {/* Layer for uploaded image */}
                                <Layer>
                                    {uploadImageUrl && (
                                        <Image
                                            // image={uploadImageRef.current}
                                            ref={uploadImageRef}
                                            draggable
                                            onDragStart={() => {
                                                selectShape('image');
                                            }}
                                            onClick={() => {
                                                selectedId === 'image' ? selectShape(null) : selectShape('image');
                                            }}
                                        />
                                    )}
                                </Layer>

                                {/* Layer for static image */}
                                <Layer>
                                    {template && template.symbolImageData && (
                                        <Image
                                            // image={symboluploadImageRef.current}
                                            ref={symboluploadImageRef}
                                            width={Number(template.symbolImageData[0].width)}
                                            x={template.symbolImageData[0].x}
                                            y={template.symbolImageData[0].y}
                                        />
                                    )}
                                </Layer>

                                {/* Layer for text */}
                                {textInput && template && (<Layer width={Number(template.textData[0].width)}
                                    height={Number(template.textData[0].height)}>

                                    <Text
                                        ref={textRef}
                                        fill={colorchange}

                                        x={template.textData[0].x}
                                        y={template.textData[0].y}
                                        // fontSize={24}
                                        fontVariant={textStyle.bold !== undefined ? textStyle.bold : 'bold'}
                                        fontStyle='italic'

                                    />
                                </Layer>
                                )}


                                {/* Layer for second image */}
                                <Layer>
                                    {selectedProduct && selectedProduct.modelName.image && (
                                        <Image
                                            // image={cameraRef.current}
                                            ref={cameraRef}
                                            width={Number(selectedProduct.modelName.width)}
                                            x={selectedProduct.modelName.x}
                                            y={selectedProduct.modelName.y}
                                        />
                                    )}
                                </Layer>

                                {/* Transformer layer */}
                                <Layer>
                                    <Transformer
                                        ref={trRef}
                                    // enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']} 
                                    />
                                </Layer>
                            </Stage>

                        </div>


                    </div>
                    {/* Right side */}
                    <div className='col-lg-6 flex py-5 item-center flex-col'>
                        <div className=''>

                            <div className='my-3'>
                                Upload a Image :
                                <input type='file' accept='image/*' className='mx-2' onChange={handleImageUpload} />
                            </div>


                            {uploadImageUrl && <>Enter the Text :
                                <input type='text' value={textInput}
                                    className='border-1 bg-slate-100' onChange={handleTextChange} /></>}
                            {/* <button onClick={addText}>Add Text</button> */}

                            {/* Save Button */}
                            <button onClick={handleSave} className='block p-2 px-3 bg-slate-700 rounded text-white my-2'>Add to the Cart</button>
                            {/* Get a Image Button */}
                        </div>
                        {/* Font editing */}
                        {textInput && <div className='' >
                            <p>Font Editor</p>
                            {/* Font size */}
                            <div>

                            </div>
                            <div className='flex  items-center gap-2'>
                                Color Picker :
                                <input type="color" className=' ' value={colorchange} onChange={(e) => setColor(e.target.value)} />
                            </div>
                            <div className='my-4'>
                                <button onClick={() => {
                                    if (textStyle.bold != 'bold')
                                        setTextStyle((prev) => ({
                                            ...prev,
                                            bold: 'bold'
                                        }))
                                    else
                                        setTextStyle((prev) => ({
                                            ...prev,
                                            bold: ''
                                        }))

                                }} className={`rounded mx-2 text-white p-1 px-3  ${textStyle.bold == 'bold' ? 'bg-opacity-100 bg-slate-600' : 'bg-opacity-50'} bg-slate-600 `}>B </button>

                                <button onClick={() => {
                                    if (textStyle.style != 'italic')
                                        setTextStyle((prev) => ({
                                            ...prev,
                                            style: 'italic'
                                        }))
                                    else
                                        setTextStyle((prev) => ({
                                            ...prev,
                                            style: ''
                                        }))

                                }} className={`rounded mx-2 text-white p-1 px-3  ${textStyle.style == 'italic' ? 'bg-opacity-100 bg-slate-600' : 'bg-opacity-50'} bg-slate-600 `}>I </button>


                            </div>
                            {/* <SketchPicker color={colorchange} onChange={handleColorChange} /> */}
                            {/* <div style={{ marginTop: '20px', backgroundColor: colorchange, width: '100px', height: '100px' }}></div> */}
                        </div>
                        }



                        {/* <FontSelector/> */}
                        {downloadUrl && (
                            <div>
                                <a href={uploadImageUrl} download="Original_image.png" >
                                    <button className='bg-amber-700 rounded text-white p-2 px-3'>Download the Original Image</button>
                                </a>
                                <a href={downloadUrl} download='canvas_image.png'>
                                    <button className='text-white rounded mx-3 p-2 px-3 bg-red-800'> Download Image</button>
                                </a>
                                <img src={`${downloadUrl}`} alt="" />
                            </div>
                        )}

                    </div>
                </>
            }
        </div></>
    );
};

export default Personalize
