import React from 'react'
import {Routes,Route, Router} from 'react-router-dom'
import ProfilePage from './ProfilePage'
import Address from './Address'
import NavBar from '../Component/NavBar'
import EditAccount from './EditAccount'
import OrderPage from './OrderPage'
import ParticularOrderPage from './Ordering/ParticularOrderPage'
import TrackingProcess from './Ordering/TrackingProcess'
const ProfileRouting = () => {
  return (
    <div>
        <NavBar/>
        <Routes>
            <Route path='/*' element={<ProfilePage/>}/>
            <Route path='/address' element={<Address/>}/>
            <Route path='/editAccount' element={<EditAccount/>}/>
            <Route path='/orders' element={<OrderPage/>}/>
            <Route path='/orders/:id' element={<ParticularOrderPage/>}/>
            <Route path='/tracking/:id' element={<TrackingProcess/>}/>


        </Routes>
      
    </div>
  )
}

export default ProfileRouting
