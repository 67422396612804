import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import WishlistButton from './WishlistButton';

const ProductCard = ({ value, name, type }) => {
  let navigate = useNavigate()
  const renderTooltip = (props) => (
    <Tooltip className='bg-white' id="button-tooltip" {...props}>
      WishList
    </Tooltip>
  );
  let change = () => {
    if (name == 'product')
      navigate(`/shop/`)
  }
  return (
    <div className={` ${name == 'product' ? '  w-[12rem] h-[22rem] sm:w-[18rem] mx-auto  shadow' :
      type === 'related' ? 'w-[11rem] sm:w-[15rem] mx-auto min-h-[15rem]  bg-white ' :
        'min-h-[22rem] md:min-h-[20rem] lg:min-h-[22rem]'} 
    showishlist hover:scale-105 hover:bg-white transition my-1 duration-500 z-0 
    rounded-3xl hover:shadow relative cursor-pointer`}>   
      <img onClick={() => {
        navigate(`/product/${value._id}`);
      }} src={value.mainImage}
        className={`rounded-3xl h-[15rem] object-cover w-full
      ${name == 'product' ? 'w-100 object-contain mx-auto ' :
            type === 'related' ? 'w-[10rem] sm:w-[15rem] mx-auto h-[9rem] lg:h-[13rem]  pb-2  ' :
              ' object-contain'} `} alt="image" />
      <div onClick={() => {
        navigate(`/product/${value._id}`);
      }} className='pb-2'>
        <p className="d-none d-sm-block text-center mt-3">{value.productName} </p>
        <p className="d-sm-none text-center mt-3">{ value.productName && value.productName.slice(0,25) }... </p>

        <p className="text-center">Price : ₹ {value.price} </p>
      </div>

      <div className="absolute  top-0 p-6 flex flex-col justify-between opacity-hover  ">
        <WishlistButton pid={value._id} />
      </div>
    </div>
  )
}

export default ProductCard