import React, { useContext, useEffect, useState } from 'react'
import Footer from '../Component/Footer'
import PlusIcon from '../SVG/PlusIcon'
import { Storage } from '../Context/StateStore'
import AddressModal from '../Component/AddressModal'
import axios from 'axios'
import { hostname } from '../App'
import MessageCom from '../Component/MessageCom'
import { Modal } from 'react-bootstrap'

const Address = () => {
    const { addressModal, setAddressModal,getAllAddress,addressArry, setMessageDetails,setAddressArry } = useContext(Storage)
    let [aid,setaid]=useState()
    let user = JSON.parse(localStorage.getItem('user'))
    let changeDefault=(id)=>{
        axios.put(`${hostname}/api/defaultAddress?Uid=${user}&Aid=${id}`).then((response)=>{
            // console.log(response.data);
            getAllAddress()
        }).catch((error)=>{
            // console.log(error);
        })
    }
    let handleDelete=(id,status)=>{
        if(status!=true){axios.delete(`${hostname}/api/userAddress/${id}`).then((response)=>{
            getAllAddress()
        }).catch((error)=>{
            // console.log(error);
        })}
        else{
            setMessageDetails({
                visible: true,
                message: `You'r trying to delete the Default Address , Make any other address as a Default and Delete it`,
                page: 'Address deletion',
                status: 'error',
                title: ''
              })
            
        }
        
    }
    useEffect(() => {
        window.scrollTo(0,0)
        if (user) {
           getAllAddress()
        }

    }, [user])
    let [confirmation,setConfirmation]=useState(false)
    return (
        <>
            <AddressModal aid={aid} setaid={setaid}/>
            {
                confirmation && <Modal centered className='' show={confirmation} onHide={()=>setConfirmation(false)} >
                    <Modal.Header closeButton>
                        Confirmation
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure , wanna delete the address?
                        <div>
                            <button onClick={()=>setConfirmation(false)} className='bg-red-600 text-white p-2 px-3 rounded  '>Close </button>
                            <button onClick={()=>setConfirmation(false)} className='bg-slate-600 text-white p-2 px-3 rounded  '>Delete  </button>

                        </div>
                    </Modal.Body>

                </Modal>
            }
            <div className='container mx-auto min-h-[60vh]'>
                <p className='text-xl fw-semibold'>Your Addresses</p>
                <section className='flex flex-wrap gap-3 justify-center my-3 sm:justify-between'>
                    <div onClick={() => setAddressModal(true)} className='border-dotted flex flex-col w-[16rem] items-center justify-center cursor-pointer h-[16rem] border-3 border-slate-400 '>
                        <div className=' text-center'>
                            <PlusIcon size={60} />
                        </div>
                        <p className='text-xl'>Add Address</p>
                    </div>

                    {/* Dynamic Address */}
                    {addressArry && addressArry.map((x) => {
                        return (
                            <div className=' p-3 w-[16rem] min-h-[16rem] border-1 flex flex-col justify-between rounded border-slate-400 '>
                                {x.defaultStatus == true && <>
                                    <p> Default </p>
                                    <hr className='m-0' />
                                </>}
                                <div className='fs-6 '>
                                    <p className='m-0 fw-semibold'>{x.fullName}</p>
                                    <address className='m-0'>
                                        {x.line1 + ", " + x.line2 + ", "+ x.state+", "+x.district +", "}
                                        <span className='block'>{x.country+ " - "+x.pincode}</span>
                                    </address>
                                    <p className='m-0'>Phone no : <span> {x.phone} </span> </p>
                                </div>
                                {/* Button */}
                                <div style={{ color: "#4d8e8f" }} className='flex items-center'>
                                    <button onClick={()=>{
                                        setaid(x)
                                        setAddressModal(true)
                                    }} className='hover:underline hover:text-red-600'> Edit </button>
                                    <hr className='rotate-90 w-4 border-2 opacity-100  border-slate-900' />
                                   {x.defaultStatus != true && <button onClick={()=>{handleDelete(x._id,x.defaultStatus)}} className='hover:underline hover:text-red-600'> Delete </button>}
                                    {
                                        x.defaultStatus ? '' : <>

                                            <hr className='rotate-90 w-4 border-2 opacity-100  border-slate-900' />
                                            <button onClick={()=>changeDefault(x._id)} className='hover:underline hover:text-red-600'>Set as Default </button>
                                        </>
                                    }
                                </div>
                            </div>)
                    })}
                </section>
            </div>
        </>
    )
}

export default Address
