import axios from 'axios'
import React, { useContext, useState } from 'react'
import { hostname } from '../App'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import VerificationEmailModel from '../Component/VerificationEmailModel'
import { Storage } from '../Context/StateStore'
import CloseIcon from '../SVG/CloseIcon'

const UserLogin = () => {
    let { showmain, setshowmain,setMessageDetails, getCartList, userSet, user, setuser } = useContext(Storage)
    let navigate = useNavigate()
    let [erorlogin, seterrorlogin] = useState()
    let [email, setemail] = useState("")
    let [show, setshow] = useState(false)
    let [forgotModal, setForgotmodal] = useState(false)
    let [showPassword, setShowpassword] = useState('password')
    let [password, setpassword] = useState("")
    let [otp, setotp] = useState()
    let [EnterOtp, setEnterotp] = useState()
    let [showmodal, setshowmodal] = useState(false)
    let [showadminregister, setshowadmin] = useState(false)
    let [adminSignup, setAdminSignUp] = useState({
        username: "",
        email: "",
        phone: null,
        password: ""
    })
    let handlechangeAdmin = (e) => {
        let { name, value } = e.target
        setAdminSignUp((prev) => ({
            ...prev,
            [name]: value

        }))
    }
    let Login = (e) => {
        e.preventDefault()
        if (email && password)
            axios.get(`${hostname}/api/user?emailNum=${email}&password=${password}`)
                .then((response) => {
                    setMessageDetails({
                        visible: true,
                        message: "Login Successfull",
                        title: '',
                        page: 'Login Validation',
                        status: 'success'
                      })
                    localStorage.setItem('user', JSON.stringify(response.data._id))
                    userSet()
                    getCartList()
                    setuser(response.data._id)
                    setshowmain(false)
                })
                .catch((error) => {
                    document.getElementById('errorlogin').innerHTML = "error"
                    seterrorlogin(error.response.data.message)
                    // console.log(error.response.data.message);
                })
        else
            seterrorlogin("Enter All the input")
    }
    let optcheck = (e) => {
        e.preventDefault()
        if (adminSignup.username && adminSignup.email && adminSignup.password && adminSignup.phone) {
            document.getElementById('errorRegister').innerHTML = ""
            axios.post(`${hostname}/api/userVerify`, adminSignup).then((response) => {
                alert(response.data.message)
                setotp(response.data.otp)
                setshowmain(false)
                // console.log(response.data.otp);
                setshowmodal(true)
            }).catch((error) => {
                // console.log(error);
            })
        }
        else {
            setMessageDetails({
                visible: true,
                message: "Error aquired ",
                title: '',
                page: 'Error',
                status: 'error'
              })
            document.getElementById('errorRegister').innerHTML = "Enter all the inputs"
        }
    }
    let Register = (e) => {
        e.preventDefault()
        if (otp == EnterOtp) {
            axios.post(`${hostname}/api/user`, adminSignup).then((response) => {
                setMessageDetails({
                    visible: true,
                    message: "Account has been registered ",
                    title: '',
                    page: 'Account Registeration',
                    status: 'success'
                  })
                localStorage.setItem('user', JSON.stringify(response.data._id))
                setuser(response.data)
                userSet()
                // console.log(response.data);
                setshowmodal(false)
                setshowmain(false)
            }).catch((err) => {
                setMessageDetails({
                    visible: true,
                    message:err.response.data.message ,
                    title: '',
                    page: 'Error Aquired',
                    status: 'error'
                  })
            })
        }
    }
    return (
        <>{showmain && <>
            <div className='flex poppins'>
                {showmain && <Modal centered className='z-10'
                    onHide={() => setshowmain(false)} placement='end' show={showmain}>
                    <div className={` ${showadminregister ? "d-none" : ""} w-full shadow relative border-1 p-4 my-auto text-center mx-auto rounded`} >
                        <h3>User Login</h3>
                        <button onClick={() => setshowmain(false)} className='absolute top-5 right-8'>
                            <CloseIcon size="20" />

                        </button>
                        <form onSubmit={Login} action="">

                            <div className='my-3'>
                                <p className='text-start'>User mail or phone <sup className='text-red-500 text-lg'>*</sup></p>
                                <input onChange={(e) => setemail(e.target.value)} type="text" placeholder='Enter the Mail or the phone'
                                    className='p-2 px-3 w-full text-slate-950 outline-none rounded border-1  ' />
                            </div>
                            <div className='my-3'>
                                <p className='text-start'>Password <sup className='text-red-500 text-lg'>*</sup></p>
                                <div className='border-1 p-2 px-3 w-full flex items-center'>
                                    <input onChange={(e) => setpassword(e.target.value)} type={`${showPassword}`} placeholder='Enter the password'
                                        className=' rounded  text-slate-950 outline-none ' /> <span
                                            onClick={() => {
                                                if (showPassword == 'password') {
                                                    setShowpassword('text')
                                                }
                                                else {
                                                    setShowpassword('password')
                                                }
                                            }} className='ms-auto cursor-pointer'> {showPassword == 'password' ? "show" : "hide"} </span>
                                </div>
                            </div>
                            <p id='errorlogin' className='h-[30px] text-red-700 '>{erorlogin} </p>
                            <div className='flex justify-between my-3 '>
                                <button type='button' onClick={() => setshowadmin(true)}
                                    className='text-blue-600 text-decoration-underline'>Sign up</button>
                                <button type='button' className='text-blue-600 text-decoration-underline'
                                    onClick={() => { setshowmain(false); setshow(true) }} >Forgot password? </button>
                            </div>

                            <button type='submit' className='px-3 p-2 bg-red-500 text-white rounded'>Log in</button>
                        </form>
                    </div>
                    {/* Registeration form */}

                    <div className={` ${showadminregister ? "" : "d-none"} w-full shadow border-1 p-3 my-auto text-center mx-auto rounded`} >
                        <h3>Registeration</h3>
                        <div className='my-3'>
                            <p className='text-start'> Name  <sup className='text-red-500 text-lg'>*</sup></p>
                            <input name='username' onChange={(e) => handlechangeAdmin(e)} type="text" placeholder='Enter the Username'
                                className='p-2 px-3 text-slate-950  w-full rounded border-1  ' />
                        </div>
                        <div className='my-3'>
                            <p className='text-start'>Phone  <sup className='text-red-500 text-lg'>*</sup></p>
                            <input value={adminSignup.phone} name='phone' onChange={(e) => {
                                const value = e.target.value;
                                // Only allow numbers and ensure the length is 10 digits
                                if (/^\d*$/.test(value) && value.length <= 10) {
                                    // console.log('hellow');
                                    setAdminSignUp((prev) => ({
                                        ...prev,
                                        phone: value
                                    }))
                                }
                            }}
                                type="number" placeholder='Enter the phone'
                                className='p-2 px-3 text-slate-950  w-full rounded border-1  ' />
                        </div>
                        <div className='my-3'>
                            <p className='text-start'> Email  <sup className='text-red-500 text-lg'>*</sup></p>
                            <input name='email' onChange={(e) => handlechangeAdmin(e)} type="email" placeholder='Enter the Email'
                                className='p-2 px-3 text-slate-950  w-full rounded border-1  ' />
                        </div>
                        <div className='my-3'>
                            <p className='text-start'>Password <sup className='text-red-500 text-lg'>*</sup></p>
                            <input name='password' onChange={(e) => handlechangeAdmin(e)} type="password" placeholder='Enter the password'
                                className='p-2 px-3 text-slate-950  rounded border-1 w-full ' />
                        </div>
                        <p id='errorRegister' className='h-[30px] text-red-700 '></p>
                        <div className='flex justify-end my-3 '>
                            <button className='text-blue-700 text-decoration-underline ' onClick={() => setshowadmin(false)} >Log in page</button>

                        </div>
                        <button onClick={optcheck} className='px-3 p-2 bg-red-500 text-white rounded'>Register</button>
                    </div>



                </Modal>}
                {/* OTP model */}


            </div></>}
            <VerificationEmailModel obj={adminSignup} who="user" show={show} onHide={() => setshow(false)} />
            {showmodal && <Modal
                show={showmodal}
                onHide={() => setshowmodal(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className=''
            >
                <Modal.Header closeButton>
                    Log in with your otp
                </Modal.Header>
                <Modal.Body className=''>

                    <h4 className='text-center'>Enter the OTP</h4>
                    <input onChange={(e) => setEnterotp(e.target.value)} placeholder='Enter the OTP' type="email"
                        className='rouned-full mt-4 bg-slate-100 w-fit text-center mx-auto flex rounded-full p-3 outline-violet-600 border-0 ' />
                    <p className='h-[30px] text-center mt-3 text-red-500' id="errorOTP" ></p>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-around'>
                    <button onClick={optcheck} className='px-3 bg-blue-500 text-white p-2 rounded'>Regenerate</button>
                    <button onClick={Register} className='px-3 bg-green-500 text-white p-2 rounded'>Register</button>
                </Modal.Footer>
            </Modal>}

        </>
    )
}

export default UserLogin