import React, { useEffect } from 'react';
import NavBar from '../../Component/NavBar';

const ShippingPolicy = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <NavBar/>
    <div className="shipping-policy p-6 pt-0 bg-white rounded container mx-auto">
    
      <h1 className="text-2xl font-bold mb-4">Shipping Policy</h1>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">1. General Information</h2>
        <p>
          At printlele.com, we strive to provide you with high-quality, customized products and excellent service. This shipping policy outlines the terms and conditions regarding the shipping of our products. By placing an order on our website, you agree to the terms set forth in this policy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">2. Processing Time</h2>
        <ul className="list-disc list-inside ml-4">
          <li>Customization and Processing: All orders for customized products are processed within 1 business day. This time includes the creation and customization of your product.</li>
          <li>Order Confirmation: Once your order is placed, you will receive an order confirmation email. If there are any issues with your order, we will contact you promptly.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">3. Shipping Rates and Delivery Estimates</h2>
        <ul className="list-disc list-inside ml-4">
          <li>Shipping Rates: Shipping charges for your order will be calculated and displayed at checkout. We offer various shipping options to meet your needs, including standard, expedited, and express shipping.</li>
          <li>Delivery Estimates: Delivery times depend on your selected shipping method and location. Standard shipping typically takes 3-4 business days, while expedited and express options are faster. Please note that delivery times are estimates and may be affected by factors beyond our control, such as carrier delays or customs processing.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">4. Shipping Carriers</h2>
        <p>
          We partner with reputable shipping carriers to deliver your orders safely and promptly. Our primary carriers include Amazon Easyship, Ekart, Dtdc, etc. You will receive a tracking number once your order has shipped, allowing you to monitor the delivery status.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">5. International Shipping</h2>
        <p>
          We offer international shipping to many countries. Please be aware that international orders may be subject to additional customs fees, import duties, and taxes levied by the destination country. These charges are the responsibility of the recipient. Delivery times for international shipments vary depending on the destination and customs processing.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">6. Order Tracking</h2>
        <p>
          Once your order has shipped, you will receive a shipping confirmation email with a tracking number. You can use this number to track your order through our carrier’s website.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">7. Shipping Address</h2>
        <p>
          Please ensure that your shipping address is correct at the time of purchase. We are not responsible for orders shipped to incorrect addresses provided by the customer. If you realize there is an error in your shipping address, please contact us immediately at <a href="mailto:info@printlele.com" className="text-blue-600 underline">info@printlele.com</a>.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">8. Shipping Restrictions</h2>
        <p>
          Some products may have shipping restrictions due to size, weight, or destination. If your order cannot be shipped to your location, we will notify you as soon as possible.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">9. Damaged or Lost Shipments</h2>
        <ul className="list-disc list-inside ml-4">
          <li>Damaged Shipments: If your order arrives damaged, please contact us immediately at <a href="mailto:info@printlele.com" className="text-blue-600 underline">info@printlele.com</a> with photos of the damaged product and packaging. We will work with you to resolve the issue.</li>
          <li>Lost Shipments: If your order is lost in transit, please contact us, and we will assist in tracking the package and resolving the issue.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">10. Returns and Exchanges</h2>
        <p>
          Please refer to our <a href="/return-policy" className="text-blue-600 underline">Return Policy</a> for information on returns and exchanges. Customized products are typically non-returnable unless they arrive damaged or defective.
        </p>
      </section>
      <section className="mb-6">
        <h2 className="text-xl font-semibold">11. Contact Us</h2>
        <p>
          If you have any questions about our shipping policy or need assistance with your order, please contact us at:
        </p>
        <ul className="list-none ml-4">
          <li>Email: <a href="mailto:info@printlele.com" className="text-blue-600 underline">info@printlele.com</a></li>
          <li>Phone: <a href="tel:8147474848" className="text-blue-600 underline">8147474848</a></li>
          <li>Address: PrintLele, No.01, Office no.01, 2nd Floor, Sree Krishna Building, Tent Bus Stop, Begur Main Road, Hongasandra, Bengaluru- 560068.</li>
        </ul>
      </section>
    </div></>
  );
};

export default ShippingPolicy;
