import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { hostname } from "../App";
import { Storage } from "../Context/StateStore";

const Footer = () => {
  let navigate = useNavigate()
  let { setMessageDetails } = useContext(Storage)
  let [subscriptionPhone, setSubcription] = useState()
  let subscriptionsPost = () => {
    axios.post(`${hostname}/api/subcription`, {
      phone: subscriptionPhone
    }).then((response) => {
      setSubcription('')
      setMessageDetails({
        visible: true,
        message: `You have subscribed to the Printle , You will get the Notification of the products`,
        page: 'Subscription',
        status: 'success',
        title: ''
      })

    }).catch((error) => {
      setMessageDetails({
        visible: true,
        message: error.response.data,
        page: 'Subscription',
        status: 'error',
        title: ''
      })
    })
  }
  let count = 0
  useEffect(() => {
    if (count == 0) {
      const script = document.createElement('script');
      script.src = 'https://cdn.trustindex.io/loader.js?bf04f0230c95122c4d068a1105a';
      script.async = true;
      script.defer = true;
      document.getElementById('reviews').appendChild(script)
      const script2 = document.createElement('script');
      script2.src = 'https://cdn.trustindex.io/loader-feed.js?70b204e30480948347569569719';
      script2.async = true;
      script2.defer = true;
      document.getElementById('feeds').appendChild(script2)
      count++;

    }
  }, []);
  return (
    <>
      
      <div className="flex  flex-wrap gap-3 justify-around my-4">
        <div className="flex  items-center poppins fw-semibold gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16">
            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
          </svg> Delivery Available All over India
        </div>
        <div className="flex  items-center poppins fw-semibold gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-box-seam" viewBox="0 0 16 16">
            <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2zm3.564 1.426L5.596 5 8 5.961 14.154 3.5zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464z" />
          </svg> Best quality of package
        </div>
        <div className="flex  items-center poppins fw-semibold gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-headset" viewBox="0 0 16 16">
            <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5" />
          </svg> Great customer support
        </div>
        <div className="flex  items-center poppins fw-semibold gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0" />
            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1m0 5.586 7 7L13.586 9l-7-7H2z" />
          </svg> Low Price in the market
        </div>

      </div>
      <h5 className="poppins text-center my-4 fw-semibold text-xl xl:text-3xl">Google Reviews </h5>
      <div className=" container mx-auto " id='reviews'>
        
      </div>
      <h5 className="poppins text-center my-4 fw-semibold text-xl xl:text-3xl">Instagram Feeds </h5>
      <div className=" container mx-auto " id='feeds'>
       
      </div>
      <div id="" className="p-5 bg-slate-100">

        <Row className="">
          <Col sm={6} lg={3}>
            <img src={require('../Assest/DarkPrintLe.png')} width={170} onClick={() => navigate('/')} className="my-2 cursor-pointer" alt="logo" />
            <p>
              Printlele (Aaglecha Enterprises), No.01, Office no.02 2nd Floor, Sree Krishna Building, Begur Rd, Maruthi Layout, Mico Layout, Hongasandra, Bengaluru, <br />
              Karnataka - 560068
            </p>
            <a className=" text-decoration-none text-slate-950 " href="tel:+918147474848" >
              <span className=" text-decoration-none text-slate-950 ">+91 8147474848</span>
            </a>
            <div className="flex gap-2 ">
              <a rel="noreferrer" href="https://www.facebook.com/profile.php?id=61556291281274&mibextid=ZbWKwL" target="_blank"
                className="cursor-pointer border-1 transition duration-300 text-slate-950 hover:scale-95 hover:text-violet-700 hover:border-violet-700 p-2 h-fit rounded-full w-fit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-facebook"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                </svg>
              </a>
              <a rel="noreferrer" href="https://g.co/kgs/E85ga8p" target="_blank" className="cursor-pointer h-fit border-1 text-slate-950 transition duration-300 hover:scale-95 hover:text-violet-700 hover:border-violet-700 p-2 rounded-full w-fit">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16">
                  <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
                </svg>
              </a>

              <a rel="noreferrer" href="https://www.instagram.com/printlele?igsh=Ym4yNHI5cW1yYXZy" target="_blank" className="cursor-pointer h-fit border-1 text-slate-950 transition duration-300 hover:scale-95 hover:text-violet-700 hover:border-violet-700 p-2 rounded-full w-fit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-instagram"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                </svg>
              </a>
              <a rel="noreferrer" href="https://www.youtube.com/channel/UCbjfnWuUg0gjqk2NsOF2Y4A" target="_blank" className="cursor-pointer h-fit border-1 text-slate-950 transition duration-300 hover:scale-95 hover:text-violet-700 hover:border-violet-700 p-2 rounded-full w-fit">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                </svg>
              </a>
            </div>
          </Col>
          <Col sm={6} lg={2} className="">
            <h4 className="text-xl poppins">Information</h4>
            <div className="">
              <p onClick={() => navigate('/terms-condition')} className="text-slate-500 hover:text-violet-700 cursor-pointer ">Terms & Condition</p>
              <p onClick={() => navigate('/shipping-condition')} className="text-slate-500 hover:text-violet-700 cursor-pointer ">Shipping</p>
              <p onClick={() => navigate('/privacy-policy')} className="text-slate-500 hover:text-violet-700 cursor-pointer "> Privacy </p>
              <p onClick={() => navigate('/return-policy')} className="text-slate-500 hover:text-violet-700 cursor-pointer ">Returns</p>

              <p onClick={() => navigate('/order-workflow')} className="text-slate-500 hover:text-violet-700 cursor-pointer ">WorkFlow</p>


              {/* <p className="text-slate-500 hover:text-violet-700 cursor-pointer mt-2">Gift card</p> */}
            </div>
          </Col>
          <Col sm={6} lg={2} className="">
            <h4 className="text-xl poppins">Useful links</h4>
            <div className="">
              <p onClick={() => navigate('/profile')} className="text-slate-500 hover:text-violet-700 cursor-pointer ">My account</p>
              <p onClick={() => navigate('/profile/orders')} className="text-slate-500 hover:text-violet-700 cursor-pointer ">Order Tracking</p>
              <p onClick={() => navigate('/shop')} className="text-slate-500 hover:text-violet-700 cursor-pointer ">All products</p>
              <p onClick={() => navigate('/wishlist')} className="text-slate-500 hover:text-violet-700 cursor-pointer ">Wishlist </p>
              {/* <p className="text-slate-500 hover:text-violet-700 cursor-pointer ">Service Detail</p> */}
            </div>
          </Col>
          <Col sm={6} lg={2} className="">
            <h4 className="text-xl poppins">About us</h4>
            <div className="">
              <p onClick={() => navigate('/about-us')} className="text-slate-500 hover:text-violet-700 cursor-pointer ">About Us</p>
              <p onClick={() => navigate('/contact-us')} className="text-slate-500 hover:text-violet-700 cursor-pointer ">Contacts</p>
              <p className="text-slate-500 hover:text-violet-700 cursor-pointer ">Careers</p>
              <a rel="noreferrer" href="https://www.google.com/search?q=PrintLele+(Aaglecha+Enterprises)&rlz=1C1ONGR_en-GBIN1106IN1106&oq=printlel&gs_lcrp=EgZjaHJvbWUqCAgAEEUYJxg7MggIABBFGCcYOzIGCAEQRRhAMgYIAhBFGDkyBggDECMYJzIGCAQQRRg8MgYIBRBFGDwyBggGEEUYPDIGCAcQRRg8qAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x3bae15a656c6644b:0xbc50f10a14b0a179,3,,,,"
                target="_blank" className="text-slate-500 hover:text-violet-700 text-decoration-none cursor-pointer "> Reviews </a>
            </div>
          </Col>
          <Col sm={6} lg={3} className="">
            <p>Subscribe our newsletter for hints, tips and the latest product news.</p>
            <input type="number" value={subscriptionPhone}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  setSubcription(e.target.value)
              }
              } placeholder="Subscription by number" className="p-2 text-slate-600 px-3 w-full rounded-full border-0 focus-within:border-1 focus-within:outline-violet-700 bg-white outline-none " />
            <button onClick={subscriptionsPost} className=" p-2 px-4 bg-purple-600 hover:bg-purple-700 text-white fw-semibold my-5 rounded-full ">Subscribe</button>
          </Col>
        </Row>
        <Row className=" poppins justify-between ">
          <Col lg={4} className="h-fit ">
            <p className="text-slate-500 mb-0">Copyright &copy; 2023 <span className="text-black">PrintLele. </span> All rights reserved</p>
          </Col>
          <Col lg={4} className="h-fit ">
            {/* <img src={require('../Assest/cardsmoney.png')} alt="Money Cards" /> */}

          </Col>
          <Col lg={4} className="h-fit flex justify-end ">
            <a href="#" className="mb-0 text-decoration-none">Back to top </a>
          </Col>
        </Row>
      </div></>
  );
};

export default Footer;
