import React from 'react'

const Subcripstion = () => {
  return (
    <div>
        <div className='row flex min-h-[30vh] container mx-auto bg-white justify-between px-4 rounded-3xl' >
            <div className='col-lg-5'>

            </div>

        </div>
    </div>
  )
}

export default Subcripstion