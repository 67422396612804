import React, { useContext } from 'react'
import HomeIcon from '../SVG/HomeIcon'
import ProfileIcon from '../SVG/ProfileIcon'
import StarIcon from '../SVG/StarIcon'
import PackageIcon from '../SVG/PackageIcon'
import {useNavigate} from 'react-router'
import { Storage } from '../Context/StateStore'
import UserLogin from '../User/Login'
const MobileBottomNav = () => {
    let navigate=useNavigate()
    let {setshowmain}=useContext(Storage)
    let user=JSON.parse(localStorage.getItem('user'))
  return (
    <div className='sticky-bottom d-sm-none text-xs poppins min-h-[7vh] flex justify-stretch bg-white'>
        <button onClick={()=>navigate('/shop')} className='w-[25%] flex flex-col items-center justify-center'>
            <HomeIcon/>
            <p className='m-0 fw-semibold'>shop</p>
        </button>
        <button onClick={()=>navigate('/profile')} className={`w-[25%] border-l-2 flex flex-col items-center justify-center ${user?'':'d-none '} `}>
            <ProfileIcon/>
            <p className='m-0 fw-semibold'>Profile</p>

        </button>
        <button onClick={()=>setshowmain(true)} className={`w-[25%] border-l-2 flex flex-col items-center justify-center ${user?'d-none':''} `}>
            <ProfileIcon/>
           
            <p className='m-0 fw-semibold'>Log In</p>
        </button>


        <button onClick={()=>navigate('/profile/orders')} className='w-[25%] border-l-2  flex flex-col items-center justify-center'>
            <PackageIcon/>
            <p className='m-0 fw-semibold'>Orders</p>
        </button> 
        <button onClick={()=>navigate('/wishlist')} className='w-[25%]  border-l-2 flex flex-col items-center justify-center'>
            <StarIcon size="20"/>
            <p className='m-0 fw-semibold'>Wishlist</p>
        </button>
        <UserLogin/>
    </div>
  )
}

export default MobileBottomNav
