import React, { useContext } from 'react'
import { Storage } from '../Context/StateStore'
import { Modal } from 'react-bootstrap'
const MessageCom = () => {
    let { messageDetails, setMessageDetails } = useContext(Storage)
    return (
        <div className={`${messageDetails.visible ? '' : 'd-none'} absolute top-0 `}>
            <Modal centered show={messageDetails.visible} onHide={() => setMessageDetails((prev) => ({ ...prev, visible: false }))} >
                <Modal.Header className={`${messageDetails.status == 'success' ? 'bg-green-600 text-white ' : messageDetails.status == 'error' ? 'bg-red-600 text-white' : ''}  text-xl`} closeButton>
                    {messageDetails.page}
                </Modal.Header>
                <Modal.Body className='min-h-[15vh] flex'>
                    <p className='text-center m-auto '>  {messageDetails.message}</p>
                </Modal.Body>
            </Modal>


        </div>
    )
}

export default MessageCom
