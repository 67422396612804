import React, { useEffect } from 'react'
import NavBar from '../Component/NavBar'
import ProfileCards from '../Component/ProfileCards'
import Footer from '../Component/Footer'

const ProfilePage = () => {
  let userId=localStorage.getItem('user')
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
      <h4 className='text-center text-2xl'>Your Account</h4>
      {/* Services */}
     {userId&& <div className='container mx-auto flex flex-wrap gap-3 justify-between'>
           <ProfileCards/>
      </div>}

    </div>
  )
}
export default ProfilePage

