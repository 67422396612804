import React, { useEffect } from 'react'
import NavBar from '../../Component/NavBar'

const ReturnRefund = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <>
            <NavBar/>

        <div className="cancellation-refund-policy container p-6 pt-0 bg-white rounded ">
            <h1 className="text-2xl font-bold mb-4">Cancellation and Refund Policy</h1>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">1. Order Cancellation</h2>
                <section className="mb-4">
                    <h3 className="text-lg font-semibold">1.1 Before Customization Begins:</h3>
                    <ul className="list-disc list-inside ml-4">
                        <li>
                            <strong>Eligibility:</strong> Orders for customized products can be canceled if the customization process has not yet begun. Please contact us as soon as possible at <a href="mailto:info@printlele.com" className="text-blue-600 underline">info@printlele.com</a> with your order details to request a cancellation.
                        </li>
                        <li>
                            <strong>Process:</strong> If the order has not entered the customization phase, we will process your cancellation request and issue a full refund to your original method of payment.
                        </li>
                    </ul>
                </section>
                <section className="mb-4">
                    <h3 className="text-lg font-semibold">1.2 After Customization Begins:</h3>
                    <ul className="list-disc list-inside ml-4">
                        <li>
                            <strong>Non-Cancellable:</strong> Once the customization process has started, orders cannot be canceled. Due to the personalized nature of our products, we are unable to offer cancellations or refunds once customization has begun.
                        </li>
                    </ul>
                </section>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">2. Refund Policy</h2>
                <section className="mb-4">
                    <h3 className="text-lg font-semibold">2.1 Customized Products:</h3>
                    <ul className="list-disc list-inside ml-4">
                        <li>
                            <strong>Non-Refundable:</strong> Customized products are made specifically to your specifications. Therefore, we do not offer refunds or exchanges unless the product is defective or damaged upon arrival.
                        </li>
                    </ul>
                </section>
                <section className="mb-4">
                    <h3 className="text-lg font-semibold">2.2 Defective or Damaged Products:</h3>
                    <ul className="list-disc list-inside ml-4">
                        <li>
                            <strong>Eligibility:</strong> If you receive a defective or damaged product, please contact us within 2 days of receiving your order at <a href="mailto:info@printlele.com" className="text-blue-600 underline">info@printlele.com</a>. Provide your order number and include photos and a description of the issue.
                        </li>
                        <li>
                            <strong>Resolution:</strong> Upon verification of the defect or damage, we will offer you a choice of a replacement product or a full refund. If a refund is chosen, it will be processed to your original method of payment.
                        </li>
                    </ul>
                </section>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">3. Return Process</h2>
                <section className="mb-4">
                    <h3 className="text-lg font-semibold">3.1 Contact Us:</h3>
                    <ul className="list-disc list-inside ml-4">
                        <li>
                            <strong>Notification:</strong> To initiate a return for a defective or damaged product, contact our customer service team at <a href="mailto:info@printlele.com" className="text-blue-600 underline">info@printlele.com</a> within 2 days of receiving your order.
                        </li>
                        <li>
                            <strong>Information Required:</strong> Include your order number, a description of the defect or damage, and photographs.
                        </li>
                    </ul>
                </section>
                <section className="mb-4">
                    <h3 className="text-lg font-semibold">3.2 Return Authorization:</h3>
                    <ul className="list-disc list-inside ml-4">
                        <li>
                            <strong>Approval:</strong> If your return request is approved, we will provide you with a return authorization and instructions for returning the product.
                        </li>
                        <li>
                            <strong>Shipping Costs:</strong> We will cover the cost of return shipping for defective or damaged products.
                        </li>
                    </ul>
                </section>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">4. Processing Time</h2>
                <ul className="list-disc list-inside ml-4">
                    <li>
                        <strong>Refund Processing:</strong> Refunds for approved returns will be processed within 5 business days after we receive the returned product.
                    </li>
                    <li>
                        <strong>Replacement Processing:</strong> Replacement products will be processed and shipped within 5 business days after the return is received and verified.
                    </li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">5. Contact Information</h2>
                <p>If you have any questions or concerns about our Cancellation and Refund Policy, please contact us at:</p>
                <ul className="list-none ml-4">
                    <li>Email: <a href="mailto:info@printlele.com" className="text-blue-600 underline">info@printlele.com</a></li>
                    <li>Phone: 8147474848</li>
                    <li>Address: PrintLele, No.01, Office no.01, 2nd Floor, Sree Krishna Building, Tent Bus Stop, Begur Main Road, Hongasandra, Bengaluru- 560068.</li>
                </ul>
            </section>
        </div>
        </>
    )
}

export default ReturnRefund