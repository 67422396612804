import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { BestSeller } from "./Data";
import ProductCard from "./ProductCard";
import { hostname } from "../App";
import axios from "axios";

const HomeBestSeller = () => {
  let [bestsellerDB, setbestsellerDB] = useState()
  let getBestSellers = () => {
    axios.get(`${hostname}/api/bestseller/true`).then((response) => {
      setbestsellerDB(response.data)
    }).catch((error) => {
      // console.log(error);
    })
  }
  useEffect(() => {
    getBestSellers()
  }, [])
  let [tooltip, settooltip] = useState(false);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    arrows: false,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="xl:min-h-[72vh] sm:flex py-5 container">
      <div className="my-auto mx-auto">
        <h3 className="poppins fw-bold text-center">Best Sellers</h3>
        <p className="text-center">
          These products are currently leading the print on demand market, offering a mix of practicality,
          personalization, and appeal across various customer segments.
        </p>
        {/* Card */}
        <Slider {...settings} className=" container mx-auto my-5 p-2">
          {bestsellerDB && bestsellerDB.map((value) => {
            return (
              <ProductCard value={value} />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default HomeBestSeller;
