import React, { useEffect } from 'react'
import NavBar from '../../Component/NavBar'

const TermsCondition = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <NavBar/>
            <div className='container'>
                <h2><strong>Terms and Conditions</strong></h2>

                <h4>Introduction</h4>

                <p>Welcome to printlele.com. These Terms and Conditions (“Terms”) govern your use of our website www.printlele.com and the purchase of customized products through our site. By accessing or using our website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not use our website.

                </p>
                <h4>Use of the Website</h4>
                <ul>
                    <li>Eligibility: You must be at least 18 years old or have the legal capacity to enter into a binding agreement in your jurisdiction.</li>
                    <li>Account: To purchase products, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
                    <li>Prohibited Activities: You agree not to use the website for any unlawful purpose or in a way that could harm our site, services, or other users.</li>
                </ul>
                <h4>Orders and Payments</h4>
                <ul>
                    <li>Order Acceptance: All orders are subject to acceptance and availability. We reserve the right to refuse or cancel any order at our discretion.</li>
                    <li>Payment: Payment must be made at the time of order placement. We accept [list payment methods, e.g., credit/debit cards, upi, internet banking].</li>
                    <li>Pricing: All prices are listed in ₹ (Rupees) and are subject to change without notice. Taxes and shipping fees will be added at checkout.</li>
                </ul>
                <h4>Customization and Product Information</h4>
                <ul>
                    <li>Customization: Our products are customized based on the information and specifications you provide. Please review your customization details carefully before placing your order.</li>
                    <li>Product Descriptions: We strive to provide accurate product descriptions. However, we do not warrant that product descriptions or other content are accurate, complete, or error-free.</li>
                </ul>
                <h4>Shipping and Delivery</h4>

                <ul>
                    <li>Processing Time: Customized orders are processed within 1 business days.</li>
                    <li>Shipping: We offer various shipping options. Delivery times and costs depend on the selected shipping method and destination.</li>
                    <li>International Shipping: International orders may be subject to customs fees, import duties, and taxes, which are the responsibility of the recipient.</li>

                </ul>
                <h4>Returns and Exchanges</h4>

                <ul>
                    <li>Customized Products: Due to the personalized nature of customized products, returns or exchanges are not accepted unless the product arrives damaged or defective.</li>
                    <li>Damage or Defects: If your product arrives damaged or defective, please contact us within 2 days of receipt with photos and a description of the issue.</li>
                </ul>

                <h4>Intellectual Property</h4>
                <ul>
                    <li>Ownership: All content on the website, including text, graphics, logos, images, and software, is the property of printlele.com or its licensors and is protected by intellectual property laws.</li>
                    <li>License: You are granted a limited, non-exclusive, non-transferable license to access and use the website for personal, non-commercial purposes.</li>
                </ul>
                <h4>Limitation of Liability</h4>
                <p>To the fullest extent permitted by law, [Your Website Name] shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the website; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from the website; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the website by any third party; (e) any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the website; and/or (f)
                    the defamatory, offensive, or illegal conduct of any third party.</p>
                <h4>Changes to Terms</h4>
                <p>We reserve the right to modify these Terms at any time. Any changes will be posted on this page with an updated date. Your continued use of the website
                    after any such changes constitutes your acceptance of the new Terms.</p>
                <h4>Contact Us</h4>
                <p> If you have any questions about these Terms, please contact us at:</p>
                <ul>
                    <li>Email: <a href="mailto:info@printlele.com">info@printlele.com</a> </li>
                    <li>Phone: <a href="tel:8147474848">8147474848</a></li>
                    <li>Address: PrintLele, No.01, Office no.01, 2nd Floor, Sree Krishna Building, Tent Bus Stop, Begur Main Road, Hongasandra, Bengaluru- 560068.</li>
                </ul>
            </div></>
    )
}

export default TermsCondition