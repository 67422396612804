import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { hostname } from '../../App'
import HomeBestSeller from '../../Component/HomeBestSeller'
import RelatedProduct from '../../Shoping/RelatedProduct'

const ParticularOrderPage = () => {
  const { id } = useParams()
  let navigate = useNavigate()
  let [orderDetails, setOrderDetails] = useState()
  let getParticularOrder = () => {
    axios.get(`${hostname}/api/particularOrderID/${id}`).then((response) => {
      // console.log(response.data);
      setOrderDetails(response.data)
    }).catch((error) => {
      // console.log(error);
    })
  }
  useEffect(() => {
    getParticularOrder()
  }, [])
  return (
    <div className='min-h-[70vh]  '>
      {orderDetails &&
        <>
          <div className='container mx-auto poppins col-xl-9'>
            <h4>Order Details </h4>
            <div className='flex text-sm flex-wrap w-fit'>
              <p className='border-e-2 pe-3 py-1 '>Ordered on {new Date(orderDetails.orderedTime).toString().slice(0, 15)}</p>
              <p className='py-1 ps-3'>Ordered # {orderDetails.orderId}</p>
            </div>
            {/* section2 */}
            <div className='my-4 p-3 rounded-xl border-1 justify-between flex flex-wrap'>
              <div className=''>
                <p className='fw-semibold text-slate-950'>Shipping Address </p>
                <p className='text-sm text-slate-600 w-[300px]'>
                  {orderDetails.shippingAddress.fullName}, <br />
                  {orderDetails.shippingAddress.line1} , <br />
                  {orderDetails.shippingAddress.line2 ? orderDetails.shippingAddress.line2 : ''} ,
                  {orderDetails.shippingAddress.landMark ? orderDetails.shippingAddress.landMark : ''} ,
                  {orderDetails.shippingAddress.district} ,<br />
                  {orderDetails.shippingAddress.state} -
                  {" " + orderDetails.shippingAddress.pincode}
                </p>
              </div>
              <div className=' w-[240px]'>
                <p className='fw-semibold text-slate-950'>Payment Id </p>
                {orderDetails.payment_Id}
              </div>
              <div className=' w-[240px]'>
                <p className='fw-semibold text-slate-950'>ShipMent Id </p>
                {orderDetails.shipment_Id}
              </div>
              <div className='font-sans w-[240px]'>
                <p className='fw-semibold poppins text-slate-950'> Order summary </p>
                <p>Amount Paid:   ₹  {orderDetails.amountPaid} </p>
                <p>Total Quantity:  {orderDetails.totalQuantity}  {orderDetails.totalQuantity > 1 ? 'items' : 'item'}  </p>

              </div>

            </div>
            <div className='my-4 p-3 flex flex-col sm:flex-row  rounded-xl justify-between border-1'>
              <div className='mx-auto'>
                <p className='fw-semibold poppins text-slate-950'> Order Status : <span className='fw-normal  '> {orderDetails.status} </span> </p>
                {
                  orderDetails.productDetails.map((x, index) => {
                    return (
                      <div className='my-2 flex '>
                        <div className='lg:flex'>
                          <img src={x.productId.mainImage} className=' sm:w-40 w-32 h-32 sm:h-40 object-contain ' alt="MainImage" />
                          <div className='mx-2'>
                            <p onClick={() => navigate(`/product/${x.productId._id}`)} className='text-blue-500 text-decoration-underline cursor-pointer'>{x.productId.productName} </p>
                            <p>Quantity : {x.quantity} </p>
                            <p > Price: <span className='font-sans'> ₹ {x.productId.price}</span> </p>
                          </div>
                        </div>
                        <div className='flex flex-col flex-sm-row  mx-3'>
                          <span className=''>Preview Image </span>
                          {
                            x.previewImage && x.previewImage.map((img)=>(
                              <img src={img} className=' lg:w-40 w-32 h-32 md:h-40 m-1 object-contain ' alt="Preview Image" />
                            ))
                          }

                        </div>
                      </div>
                    )
                  })
                } </div>
              {/* section 2 tracking section */}
              <div className='flex mx-auto flex-col justify-center'>
                {/* <button onClick={() => navigate(`/profile/tracking/${orderDetails.orderId}`)} className='bg-yellow-400 bg-opacity-80 w-[200px] shadow-sm rounded text-sm p-1 px-3 '>
                  Tracking Order </button> */}
              <a href={`https://printlele.shiprocket.co/tracking/order/${orderDetails.orderId}`} target='_blank'>
                  <button className='bg-yellow-400 bg-opacity-80 w-[200px] text-black shadow-sm rounded text-sm p-1 px-3 '> Track Package </button>
                </a>

              </div>
            </div>
          </div>
          <RelatedProduct category={orderDetails.productDetails[0].productId.category} />
        </>
      }

      <HomeBestSeller />
    </div>
  )
}

export default ParticularOrderPage