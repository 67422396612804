import React, { useContext, useEffect, useState } from 'react'
import { Storage } from '../Context/StateStore'
import axios from 'axios'
import { useNavigate } from 'react-router'

const SideProduct = () => {
    const { Allproduct, getAllProduct } = useContext(Storage)
    let [arry, setarry] = useState([])
    useEffect(() => {
        if (Allproduct && Allproduct.length>3) {
            let total = Allproduct.length
            let newSet = new Set()
            while ([...newSet].length < 3) {
                let randomNumber = Math.floor(Math.random() * total)
                newSet.add(randomNumber)
            }
            setarry([...newSet])
        }
    }, [Allproduct])
  let navigate=useNavigate()
    return (
        <div className='my-2'>
            {
                Allproduct && <>
                    <div>
                        {arry && arry.length > 0 && [...arry].map((x, index) => {
                            let obj = Allproduct[x]
                            // console.log(obj);
                            return (
                                <div onClick={()=>navigate(`/product/${obj._id}`)} className='flex gap-2 cursor-pointer hover:scale-[1.02] transition duration-500 items-center my-2 '>
                                    <img loading='lazy' src={obj.mainImage} className='w-28 h-28 object-cover' alt="MainImage" />
                                    <div>
                                        <h6>{obj.productName} </h6>
                                        <p> Price : {obj.price} </p>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </>
            }

        </div>
    )
}

export default SideProduct