import React, { useContext, useLayoutEffect } from 'react'
import NavBar from '../Component/NavBar'
import { useRef, useEffect, useState } from 'react';

import { Stage, Layer, Image, Transformer, Clip, Text, Rect, Group } from 'react-konva';
import { useNavigate, useParams } from 'react-router';
import StateStore, { Storage } from '../Context/StateStore';
import axios from 'axios';
import { hostname } from '../App';
import UserLogin from '../User/Login';
import MessageCom from '../Component/MessageCom';
import UploadIcon from '../SVG/UploadIcon';
import Dustbin from '../SVG/Dustbin';
const PersonalizeClipath = (props) => {
    const { id } = useParams()
    let [flipside, setflipside] = useState('flip1')
    const { getCartList, setshowmain, messageDetails, setMessageDetails, allFont } = useContext(Storage)
    const [selectedProduct, setSelectedProduct] = useState()
    const [clipath, setclipath] = useState()
    const [textAlignments, setTextAlignments] = useState()
    const [uploadAlignments, setuploadAligments] = useState()
    const stageRef = useRef(null);
    // For the second Upload
    const [clipath1, setclipath1] = useState()
    const [textAlignments1, setTextAlignments1] = useState()
    const [uploadAlignments1, setuploadAligments1] = useState()
    const stageRef1 = useRef(null);
    const uploadImageRef = useRef(null);
    const [uploadImageUrl, setuploadImageUrl] = useState(null);
    // Image for the second one single image without boundary
    const uploadImageRef1 = useRef(null);
    const [uploadImageUrl1, setuploadImageUrl1] = useState(null);


    const [freeText, setFreeText] = useState({
        text: '',
        color: '',
        fontFamily: 'Satisfy',
        size: 24,
    });

    const textRef = useRef(null);
    const textRef2 = useRef(null);
    const textRef3 = useRef(null);
    const freeTextRef = useRef(null)
    // For the Second side 
    const textRef11 = useRef(null);
    const textRef12 = useRef(null);
    const textRef13 = useRef(null);
    const freeTextRef1 = useRef(null)
    const [freeText1, setFreeText1] = useState({
        text: '',
        color: '',
        fontFamily: 'Satisfy',
        size: 24,
    });

    const trRef = useRef(null);
    const imageUploadRef1 = useRef(null)
    const imageUploadRef2 = useRef(null)
    const imageUploadRef3 = useRef(null)
    const imageUploadRef4 = useRef(null)
    const [imageUrl1, setImageUrl1] = useState()
    const [imageUrl2, setImageUrl2] = useState()
    const [imageUrl3, setImageUrl3] = useState()
    const [imageUrl4, setImageUrl4] = useState()
    // For the second clipath
    const trRef1 = useRef(null);
    const imageUploadRef11 = useRef(null)
    const imageUploadRef12 = useRef(null)
    const imageUploadRef13 = useRef(null)
    const imageUploadRef14 = useRef(null)
    const [imageUrl11, setImageUrl11] = useState()
    const [imageUrl12, setImageUrl12] = useState()
    const [imageUrl13, setImageUrl13] = useState()
    const [imageUrl14, setImageUrl14] = useState()



    const cameraRef = useRef(null);
    const [imageFilledIndex, setImageFilledIndex] = useState([])
    const [uploadedImageFile, setuploadedImageFile] = useState([])
    const [selectedId, selectShape] = useState(null);
    const [textDetails, setTextDetails] = useState([])

    // For the second clipath 
    const cameraRef1 = useRef(null);
    const [imageFilledIndex1, setImageFilledIndex1] = useState([])
    const [uploadedImageFile1, setuploadedImageFile1] = useState([])
    const [selectedId1, selectShape1] = useState(null);
    const [textDetails1, setTextDetails1] = useState([])

    const [downloadUrl, setDownloadUrl] = useState(null);

    const [textStyle, setTextStyle] = useState({
        bold: '',
        style: '',
        size: 36,
    })
    let navigate = useNavigate()
    //getting details of the product from the API 
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get(`${hostname}/api/productID?id=${id}`).then((response) => {
            // console.log(response.data.clipPaths[0].textAlignments);
            // console.log([].map((object) => console.log(object)));
            setSelectedProduct(response.data)
            setclipath(response.data.clipPaths[0])
            setTextAlignments(response.data.clipPaths[0].textAlignments)
            setuploadAligments(response.data.clipPaths[0].userUploadAlignments)
            if (response.data.clipPaths[0].textAlignments && response.data.clipPaths[0].textAlignments.length > 0) {
                setTextDetails(response.data.clipPaths[0].textAlignments.map((alignment, index) => ({
                    id: index + 1,
                    text: 'Text Here',
                    color: '',
                    fontFamily: 'Satisfy',
                    size: 24,
                })));
            }
            // Checking the second is there or not
            if (response.data.clipPaths[1]) {
                setclipath1(response.data.clipPaths[1])
                setTextAlignments1(response.data.clipPaths[1].textAlignments)
                setuploadAligments1(response.data.clipPaths[1].userUploadAlignments)
                if(response.data.clipPaths[1].textAlignments && response.data.clipPaths[1].textAlignments.length>0)
                {setTextDetails(response.data.clipPaths[1].textAlignments.map((alignment, index) => ({
                    id: index + 1,
                    text: 'Text Here',
                    color: '',
                    fontFamily: 'Satisfy',
                    size: 24,
                })));}
            }
        }).catch((error) => {
            // console.log(error);
        })
    }, [id])
    //for the clipath one
    const handleImageUpload = (e, index) => {
        setImageFilledIndex([...imageFilledIndex, index])
        const file = e.target.files[0];
        // setuploadedImageFile((prev) => [...prev, e.target.files[0]])
        const reader = new FileReader();
        reader.onload = function (event) {
            if (index == 1) {
                setImageUrl1(event.target.result)
                setuploadedImageFile((prev) => {
                    const newArray = [...prev]; // Create a copy of the previous state array
                    newArray[0] = e.target.files[0]; // Update the first element
                    return newArray; // Return the new array
                });
            }
            else if (index == 2) {
                setImageUrl2(event.target.result)
                setuploadedImageFile((prev) => {
                    const newArray = [...prev]; // Create a copy of the previous state array
                    newArray[1] = e.target.files[0]; // Update the first element
                    return newArray; // Return the new array
                });
            }
            else if (index == 3) {
                setImageUrl3(event.target.result)
                setuploadedImageFile((prev) => {
                    const newArray = [...prev]; // Create a copy of the previous state array
                    newArray[2] = e.target.files[0]; // Update the first element
                    return newArray; // Return the new array
                });
            }
            else if (index == 4) {
                setImageUrl4(event.target.result)
                setuploadedImageFile((prev) => {
                    const newArray = [...prev]; // Create a copy of the previous state array
                    newArray[3] = e.target.files[0]; // Update the first element
                    return newArray; // Return the new array
                });
            }
            else {
                setuploadImageUrl(event.target.result);
                setuploadedImageFile((prev) => {
                    const newArray = [...prev]; // Create a copy of the previous state array
                    newArray[0] = e.target.files[0]; // Update the first element
                    return newArray; // Return the new array
                });
            }
            // setuploadImageUrl(selectedProduct.uploadImageUrl[0])
            // console.log(event.target.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    //for the clipath second
    const handleImageUploadSecond = (e, index) => {
        setImageFilledIndex1([...imageFilledIndex1, index])
        const file = e.target.files[0];
        // setuploadedImageFile((prev) => [...prev, e.target.files[0]])
        const reader = new FileReader();
        reader.onload = function (event) {
            if (index == 1) {
                setImageUrl11(event.target.result)
                setuploadedImageFile1((prev) => {
                    const newArray = [...prev]; // Create a copy of the previous state array
                    newArray[0] = e.target.files[0]; // Update the first element
                    return newArray; // Return the new array
                });
            }
            else if (index == 2) {
                setImageUrl12(event.target.result)
                setuploadedImageFile1((prev) => {
                    const newArray = [...prev]; // Create a copy of the previous state array
                    newArray[1] = e.target.files[0]; // Update the first element
                    return newArray; // Return the new array
                });
            }
            else if (index == 3) {
                setImageUrl13(event.target.result)
                setuploadedImageFile1((prev) => {
                    const newArray = [...prev]; // Create a copy of the previous state array
                    newArray[2] = e.target.files[0]; // Update the first element
                    return newArray; // Return the new array
                });
            }
            else if (index == 4) {
                setImageUrl14(event.target.result)
                setuploadedImageFile1((prev) => {
                    const newArray = [...prev]; // Create a copy of the previous state array
                    newArray[3] = e.target.files[0]; // Update the first element
                    return newArray; // Return the new array
                });
            }
            else {
                setuploadImageUrl1(event.target.result);
                setuploadedImageFile1((prev) => {
                    const newArray = [...prev]; // Create a copy of the previous state array
                    newArray[0] = e.target.files[0]; // Update the first element
                    return newArray; // Return the new array
                });
            }
            // setuploadImageUrl(selectedProduct.uploadImageUrl[0])
            // console.log(event.target.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    let [loading, setloading] = useState(false)
    let [message, setMessage] = useState('')
    const handleSave = () => {
        // Stage 1
        let arry = []
        const stage = stageRef.current.getStage();
        const dataURL = stage.toDataURL();
        arry.push(dataURL)
        // Stage 2
        let stage1 = null
        let dataUrl1 = null
        if (clipath1) {
            stage1 = stageRef1.current.getStage();
            dataUrl1 = stage1.toDataURL();
            arry.push(dataUrl1)
            // console.log(arry);
        }

        const userid = JSON.parse(localStorage.getItem('user'))
        const formData = new FormData()
        formData.append('message', message)
        formData.append('upload', uploadedImageFile[0])
        formData.append('userId', userid)
        formData.append('productId', id)
        for (let index = 0; index < arry.length; index++) {
            formData.append('previewImage', arry[index])

        }
        formData.append('quantity', 1)
        formData.append('ordered', false)
        if (textDetails.length > 0) {
            formData.append('textDetails', JSON.stringify(textDetails))
        }
        else if (textDetails.length == 0 && freeText.text != '') {
            formData.append('textDetails', JSON.stringify(freeText))
        }
        // Checking for the second Clipath and adding the text
        if (clipath1 != undefined && clipath1 != null) {
            if (textDetails1.length > 0) {
                formData.append('textDetails', JSON.stringify(textDetails1))
            }
            else if (textDetails1.length == 0 && freeText1.text != '') {
                formData.append('textDetails', JSON.stringify(freeText1))
            }
        }

        // console.log(uploadedImageFile);
        if (userid) {
            if ((uploadedImageFile.length > 1 && uploadedImageFile.length == uploadAlignments.length) || clipath1) {

                //check the condition for the clipath second is present or not 
                if (clipath1 && uploadedImageFile1[0]) {
                    formData.append('upload', uploadedImageFile1[0])
                }
                else if (clipath && uploadAlignments1 && uploadedImageFile1.length == uploadAlignments1.lenght) {
                    for (let index = 1; index < uploadedImageFile1.length; index++) {
                        formData.append('upload', uploadedImageFile1[index])
                    }
                }
                else if (uploadedImageFile <= 0 || uploadedImageFile.length < uploadAlignments.length) {
                    setMessageDetails({
                        visible: true,
                        message: "Upload a Image Before Save",
                        title: '',
                        page: 'Customization',
                        status: 'error'
                    })
                    return
                }
                else if (uploadAlignments1.length != uploadedImageFile1.length) {
                    setMessageDetails({
                        visible: true,
                        message: "Upload a Image in back flip Before Save",
                        title: '',
                        page: 'Customization',
                        status: 'error'
                    })
                    setloading(false)
                    return

                }
                for (let index = 1; index < uploadedImageFile.length; index++) {
                    formData.append('upload', uploadedImageFile[index])
                }
                setloading(true)

                axios.post(`${hostname}/api/cartMulti`, formData).then((response) => {
                    // console.log(response.data);
                    getCartList()
                    setMessageDetails({
                        visible: true,
                        message: "Your Customized Mobile case has been Added to the Cart",
                        title: '',
                        page: 'Customization',
                        status: 'success'
                    })
                    navigate('/cartlist')
                    setloading(false)
                }).catch((error) => {
                    // console.log(error);
                })
            }
            else if (uploadedImageFile <= 0 || uploadedImageFile.length < uploadAlignments.length) {
                setMessageDetails({
                    visible: true,
                    message: "Upload a Image Before Save",
                    title: '',
                    page: 'Customization',
                    status: 'error'
                })
            }
            else if (uploadedImageFile.length == 1 && (clipath1 == undefined || clipath == null)) {
                // console.log(uploadedImageFile);
                setloading(true)
                axios.post(`${hostname}/api/cart`, formData).then((response) => {
                    // console.log(response.data);
                    getCartList()
                    setMessageDetails({
                        visible: true,
                        message: "Your Customized Mobile case has been Added to the Cart",
                        title: '',
                        page: 'Customization',
                        status: 'success'
                    })
                    navigate('/cartlist')
                    setloading(false)
                }).catch((error) => {
                    // console.log(error);
                    setMessageDetails({
                        visible: true,
                        message: "There has been a error in server",
                        title: '',
                        page: 'Customization',
                        status: 'error'
                    })
                })
            }
        }
        else {
            setshowmain(true)
        }
    };
    const handleTextChange = (e) => {
        const word = e.target.value;
        selectShape('freetext')
        setFreeText((prev) => ({
            ...prev,
            text: word
        }))
        if (freeTextRef.current) {
            freeTextRef.current.text(word);
            // freeTextRef.current.batchDraw();
        }
    };
    const handleTextChange1 = (e) => {
        const word = e.target.value;
        selectShape1('freetext1')
        setFreeText1((prev) => ({
            ...prev,
            text: word
        }))
        if (freeTextRef1.current) {
            freeTextRef1.current.text(word);
            // freeTextRef.current.batchDraw();
        }
    };


    useEffect(() => {
        if (clipath && flipside == 'flip1') {
            // console.log(clipath.clipImage);
            const cameraImage = new window.Image();
            cameraImage.src = clipath.clipImage;
            cameraImage.crossOrigin = 'Anonymous';
            cameraImage.onload = () => {
                // console.log("cameraRef loaded successfully:", cameraImage);
                cameraRef.current.image(cameraImage);
                stageRef.current.batchDraw();
                const originalWidth = cameraImage.naturalWidth;
                const originalHeight = cameraImage.naturalHeight;
                const targetWidth = 320;
                const targetHeight = (targetWidth / originalWidth) * originalHeight;
                cameraRef.current.height(targetHeight);
                stageRef.current.height(targetHeight)
            };
            cameraImage.onerror = (error) => {
                console.error("Error loading second image:", error);
            };
        }
    }, [clipath, flipside, uploadImageUrl,]);
    // Second clipath image
    useEffect(() => {
        if (clipath1 && flipside == 'flip2') {
            // console.log(clipath.clipImage);
            const cameraImage = new window.Image();
            cameraImage.src = clipath1.clipImage;
            cameraImage.crossOrigin = 'Anonymous';
            cameraImage.onload = () => {
                // console.log("cameraRef loaded successfully:", cameraImage);
                cameraRef1.current.image(cameraImage);
                stageRef1.current.batchDraw();
                const originalWidth = cameraImage.naturalWidth;
                const originalHeight = cameraImage.naturalHeight;
                const targetWidth = 320;
                const targetHeight = (targetWidth / originalWidth) * originalHeight;
                cameraRef1.current.height(targetHeight);
                stageRef1.current.height(targetHeight);
            };
            cameraImage.onerror = (error) => {
                console.error("Error loading second image:", error);
            };
        }
    }, [clipath1, flipside, uploadImageUrl1,]);
    //first clipath
    useEffect(() => {
        try {
            if (uploadImageUrl && flipside == 'flip1') {
                const imageuploaded = new window.Image();
                imageuploaded.crossOrigin = 'Anonymous';
                imageuploaded.src = uploadImageUrl;
                imageuploaded.onload = () => {
                    // uploadImageRef.current.image(image);
                    // stageRef.current.batchDraw();
                    // console.log(imageuploaded);
                    uploadImageRef.current.image(imageuploaded);
                    stageRef.current.batchDraw();
                    const cameraImageWidth = 320;
                    const cameraImageHeight = (cameraImageWidth / imageuploaded.width) * imageuploaded.height;
                    // Set the second image width and height
                    uploadImageRef.current.width(cameraImageWidth);
                    uploadImageRef.current.height(cameraImageHeight);

                };
            }
        } catch (error) {
            // console.log(error);
        }
    }, [uploadImageUrl, flipside, selectedId]);

    //second clipath upload image
    useEffect(() => {
        try {
            if (uploadImageUrl1 && flipside == 'flip2') {
                const imageuploaded = new window.Image();
                imageuploaded.crossOrigin = 'Anonymous';
                imageuploaded.src = uploadImageUrl1;
                imageuploaded.onload = () => {
                    // uploadImageRef.current.image(image);
                    // stageRef.current.batchDraw();
                    // console.log(imageuploaded);
                    uploadImageRef1.current.image(imageuploaded);
                    stageRef1.current.batchDraw();
                    const cameraImageWidth = 320;
                    const cameraImageHeight = (cameraImageWidth / imageuploaded.width) * imageuploaded.height;
                    // Set the second image width and height
                    uploadImageRef1.current.width(cameraImageWidth);
                    uploadImageRef1.current.height(cameraImageHeight);

                };
            }
        } catch (error) {
            // console.log(error);
        }
    }, [uploadImageUrl1, flipside, selectedId1]);
    //First clipath alignment 1
    useEffect(() => {
        try {
            if (imageUrl1) {
                const imageuploaded = new window.Image();
                imageuploaded.crossOrigin = 'Anonymous';
                imageuploaded.src = imageUrl1;
                imageuploaded.onload = () => {
                    // uploadImageRef.current.image(image);
                    // stageRef.current.batchDraw();
                    // console.log(imageuploaded);
                    imageUploadRef1.current.image(imageuploaded);
                    stageRef.current.batchDraw();
                    const cameraImageWidth = 320;
                    const cameraImageHeight = (cameraImageWidth / imageuploaded.width) * imageuploaded.height;
                    // Set the second image width and height
                    imageUploadRef1.current.width(cameraImageWidth);
                    imageUploadRef1.current.height(cameraImageHeight);

                };
            }
        } catch (error) {
            // console.log(error);
        }
    }, [imageUrl1, flipside, selectedId]);
    //Second clipath alignment 1
    useEffect(() => {
        try {
            if (imageUrl11 && flipside == 'flip2') {
                const imageuploaded = new window.Image();
                imageuploaded.crossOrigin = 'Anonymous';
                imageuploaded.src = imageUrl11;
                imageuploaded.onload = () => {
                    // uploadImageRef.current.image(image);
                    // stageRef.current.batchDraw();
                    // console.log(imageuploaded);
                    imageUploadRef11.current.image(imageuploaded);
                    stageRef1.current.batchDraw();
                    const cameraImageWidth = 320;
                    const cameraImageHeight = (cameraImageWidth / imageuploaded.width) * imageuploaded.height;
                    // Set the second image width and height
                    imageUploadRef11.current.width(cameraImageWidth);
                    imageUploadRef11.current.height(cameraImageHeight);
                };
            }
        } catch (error) {
            // console.log(error);
        }
    }, [imageUrl11, flipside, selectedId1]);
    //clipath first 2nd alignment
    useEffect(() => {
        try {
            if (imageUrl2) {
                const imageuploaded = new window.Image();
                imageuploaded.crossOrigin = 'Anonymous';
                imageuploaded.src = imageUrl2;
                imageuploaded.onload = () => {
                    // uploadImageRef.current.image(image);
                    // stageRef.current.batchDraw();
                    // console.log(imageuploaded);
                    imageUploadRef2.current.image(imageuploaded);
                    stageRef.current.batchDraw();
                    const cameraImageWidth = 320;
                    const cameraImageHeight = (cameraImageWidth / imageuploaded.width) * imageuploaded.height;
                    // Set the second image width and height
                    imageUploadRef2.current.width(cameraImageWidth);
                    imageUploadRef2.current.height(cameraImageHeight);

                };
            }
        } catch (error) {
            // console.log(error);
        }
    }, [imageUrl2, flipside, selectedId]);
    //clipath second 2nd alignment
    useEffect(() => {
        try {
            if (imageUrl12 && flipside == 'flip2') {
                const imageuploaded = new window.Image();
                imageuploaded.crossOrigin = 'Anonymous';
                imageuploaded.src = imageUrl12;
                imageuploaded.onload = () => {
                    // uploadImageRef.current.image(image);
                    // stageRef.current.batchDraw();
                    // console.log(imageuploaded);
                    imageUploadRef12.current.image(imageuploaded);
                    stageRef1.current.batchDraw();
                    const cameraImageWidth = 320;
                    const cameraImageHeight = (cameraImageWidth / imageuploaded.width) * imageuploaded.height;
                    // Set the second image width and height
                    imageUploadRef12.current.width(cameraImageWidth);
                    imageUploadRef12.current.height(cameraImageHeight);

                };
            }
        } catch (error) {
            // console.log(error);
        }
    }, [imageUrl12, flipside, selectedId1]);
    //First clipath 3rd alignment
    useEffect(() => {
        try {
            if (imageUrl3) {
                const imageuploaded = new window.Image();
                imageuploaded.crossOrigin = 'Anonymous';
                imageuploaded.src = imageUrl3;
                imageuploaded.onload = () => {
                    // uploadImageRef.current.image(image);
                    // stageRef.current.batchDraw();
                    // console.log(imageuploaded);
                    imageUploadRef3.current.image(imageuploaded);
                    stageRef.current.batchDraw();
                    const cameraImageWidth = 320;
                    const cameraImageHeight = (cameraImageWidth / imageuploaded.width) * imageuploaded.height;
                    // Set the second image width and height
                    imageUploadRef3.current.width(cameraImageWidth);
                    imageUploadRef3.current.height(cameraImageHeight);

                };
            }
        } catch (error) {
            // console.log(error);
        }
    }, [imageUrl3, flipside, selectedId]);
    //second clipath 3rd alignment
    useEffect(() => {
        try {
            if (imageUrl13 && flipside == 'flip2') {
                const imageuploaded = new window.Image();
                imageuploaded.crossOrigin = 'Anonymous';
                imageuploaded.src = imageUrl13;
                imageuploaded.onload = () => {
                    // uploadImageRef.current.image(image);
                    // stageRef.current.batchDraw();
                    // console.log(imageuploaded);
                    imageUploadRef13.current.image(imageuploaded);
                    stageRef1.current.batchDraw();
                    const cameraImageWidth = 320;
                    const cameraImageHeight = (cameraImageWidth / imageuploaded.width) * imageuploaded.height;
                    // Set the second image width and height
                    imageUploadRef13.current.width(cameraImageWidth);
                    imageUploadRef13.current.height(cameraImageHeight);
                };
            }
        } catch (error) {
            // console.log(error);
        }
    }, [imageUrl13, flipside, selectedId1]);
    //First clipath 4th alignment
    useEffect(() => {
        try {
            if (imageUrl4) {
                const imageuploaded = new window.Image();
                imageuploaded.crossOrigin = 'Anonymous';
                imageuploaded.src = imageUrl4;
                imageuploaded.onload = () => {
                    // uploadImageRef.current.image(image);
                    // stageRef.current.batchDraw();
                    // console.log(imageuploaded);
                    imageUploadRef4.current.image(imageuploaded);
                    stageRef.current.batchDraw();
                    const cameraImageWidth = 320;
                    const cameraImageHeight = (cameraImageWidth / imageuploaded.width) * imageuploaded.height;
                    // Set the second image width and height
                    imageUploadRef4.current.width(cameraImageWidth);
                    imageUploadRef4.current.height(cameraImageHeight);

                };
            }
        } catch (error) {
            // console.log(error);
        }
    }, [imageUrl4, flipside, selectedId]);
    //Second clipath 4th alignment
    useEffect(() => {
        try {
            if (imageUrl14 && flipside == 'flip2') {
                const imageuploaded = new window.Image();
                imageuploaded.crossOrigin = 'Anonymous';
                imageuploaded.src = imageUrl14;
                imageuploaded.onload = () => {
                    // uploadImageRef.current.image(image);
                    // stageRef.current.batchDraw();
                    // console.log(imageuploaded);
                    imageUploadRef14.current.image(imageuploaded);
                    stageRef1.current.batchDraw();
                    const cameraImageWidth = 320;
                    const cameraImageHeight = (cameraImageWidth / imageuploaded.width) * imageuploaded.height;
                    // Set the second image width and height
                    imageUploadRef14.current.width(cameraImageWidth);
                    imageUploadRef14.current.height(cameraImageHeight);
                };
            }
        } catch (error) {
            // console.log(error);
        }
    }, [imageUrl4, flipside, selectedId]);

    useEffect(() => {
        if (trRef.current && flipside == 'flip1') {
            if (selectedId) {
                const selectedNode = selectedId === 'image' ? uploadImageRef.current :
                    selectedId === 'freetext' ? freeTextRef.current : selectedId === 'image1' ? imageUploadRef1.current :
                        selectedId === 'image2' ? imageUploadRef2.current :
                            selectedId === 'image3' ? imageUploadRef3.current :
                                selectedId === 'image4' ? imageUploadRef4.current : null;
                if (selectedNode) {
                    trRef.current.nodes([selectedNode]);
                }
            } else {
                // If selectedId becomes null, clear nodes to deselect the area
                trRef.current.nodes([]);
            }
            const layer = trRef.current.getLayer();
            if (layer) {
                layer.batchDraw();
            }
        }
    }, [selectedId, flipside]);
    useEffect(() => {
        if (trRef1.current && flipside == 'flip2') {
            if (selectedId1) {
                const selectedNode = selectedId1 === 'image' ? uploadImageRef1.current :
                    selectedId1 === 'freetext' ? freeTextRef1.current : selectedId1 === 'image1' ? imageUploadRef11.current :
                        selectedId1 === 'image2' ? imageUploadRef12.current :
                            selectedId1 === 'image3' ? imageUploadRef13.current :
                                selectedId1 === 'image4' ? imageUploadRef14.current : null;
                if (selectedNode) {
                    trRef1.current.nodes([selectedNode]);
                }
            } else {
                // If selectedId becomes null, clear nodes to deselect the area
                trRef1.current.nodes([]);
            }
            const layer = trRef1.current.getLayer();
            if (layer) {
                layer.batchDraw();
            }
        }
    }, [selectedId1, flipside]);
    return (
        <>
            <NavBar />
            <div className='min-h-[90vh]  font-serif row container mx-auto'>

                {
                    selectedProduct != undefined && selectedProduct != null && <>
                        <div className='col-lg-6 sm:flex items-end justify-between m-auto'>
                            {<div className={`h-fit ${flipside == 'flip1' ? '' : 'hidden'} relative w-[320px] overflow-hidden rounded-3xl `}>
                                <Stage
                                    width={320}
                                    className=' mx-auto'
                                    // onMouseLeave={() => {
                                    //     selectShape(null)
                                    // }}
                                    onMouseDown={(e) => {
                                        // deselect when clicked on empty area
                                        const clickedOnEmpty = e.target === e.target.getStage();
                                        if (clickedOnEmpty) {
                                            selectShape(null);
                                        }
                                    }}
                                    ref={stageRef}
                                >
                                    {/* Layer for uploaded image */}
                                    <Layer >
                                        {uploadImageUrl && (
                                            <Image
                                                y={300}
                                                ref={uploadImageRef}
                                                draggable
                                                onDragStart={() => {
                                                    selectShape('image');
                                                }}
                                                onClick={() => {
                                                    selectedId === 'image' ? selectShape(null) : selectShape('image');
                                                }}
                                                onTouchStart={() => {
                                                    selectedId === 'image' ? selectShape(null) : selectShape('image');

                                                }}
                                            />
                                        )}
                                    </Layer>


                                    {
                                        uploadAlignments && uploadAlignments[0] && imageUrl1 &&

                                        <Layer x={uploadAlignments[0].x} y={uploadAlignments[0].y} width={uploadAlignments[0].width} height={uploadAlignments[0].height} >
                                            <Group clipFunc={(context) => {
                                                context.beginPath();
                                                context.rect(0, 0, uploadAlignments[0].width, uploadAlignments[0].height);
                                                context.closePath();
                                            }}>
                                                <Image
                                                    ref={imageUploadRef1}
                                                    draggable
                                                    onDragStart={() => {
                                                        selectShape('image1');
                                                        // console.log('move');
                                                    }}
                                                    onClick={() => {
                                                        selectedId === 'image1' ? selectShape(null) : selectShape('image1');
                                                    }}
                                                    onTouchStart={() => {
                                                        selectedId === 'image1' ? selectShape(null) : selectShape('image1');
                                                    }}
                                                // image={`https://backendapi.meridatechminds.com/media/images/Digitalmarketing.jpeg`}
                                                />
                                            </Group>

                                        </Layer>


                                    }
                                    {
                                        uploadAlignments && uploadAlignments[1] && imageUrl2 &&

                                        <Layer x={uploadAlignments[1].x} y={uploadAlignments[1].y} width={uploadAlignments[1].width} height={uploadAlignments[1].height} >
                                            <Group clipFunc={(context) => {
                                                context.beginPath();
                                                context.rect(0, 0, uploadAlignments[1].width, uploadAlignments[1].height);
                                                context.closePath();
                                            }}>
                                                <Image
                                                    ref={imageUploadRef2}
                                                    draggable
                                                    onDragStart={() => {
                                                        selectShape('image2');
                                                    }}
                                                    onClick={() => {
                                                        selectedId === 'image2' ? selectShape(null) : selectShape('image2');
                                                    }}
                                                    onTouchStart={() => {
                                                        selectedId === 'image2' ? selectShape(null) : selectShape('image2');
                                                    }}
                                                // image={`https://backendapi.meridatechminds.com/media/images/Digitalmarketing.jpeg`}
                                                />
                                            </Group>

                                        </Layer>


                                    }
                                    {
                                        uploadAlignments && uploadAlignments[2] && imageUrl3 &&

                                        <Layer x={uploadAlignments[2].x} y={uploadAlignments[2].y} width={uploadAlignments[2].width} height={uploadAlignments[2].height} >
                                            <Group clipFunc={(context) => {
                                                context.beginPath();
                                                context.rect(0, 0, uploadAlignments[2].width, uploadAlignments[2].height);
                                                context.closePath();
                                            }}>
                                                <Image
                                                    ref={imageUploadRef3}
                                                    draggable
                                                    onDragStart={() => {
                                                        selectShape('image3');
                                                    }}
                                                    onClick={() => {
                                                        selectedId === 'image3' ? selectShape(null) : selectShape('image3');
                                                    }}
                                                    onTouchStart={() => {
                                                        selectedId === 'image3' ? selectShape(null) : selectShape('image3');
                                                    }}
                                                // image={`https://backendapi.meridatechminds.com/media/images/Digitalmarketing.jpeg`}
                                                />
                                            </Group>

                                        </Layer>


                                    }
                                    {
                                        uploadAlignments && uploadAlignments[3] && imageUrl4 &&

                                        <Layer x={uploadAlignments[3].x} y={uploadAlignments[3].y} width={uploadAlignments[3].width} height={uploadAlignments[3].height} >
                                            <Group clipFunc={(context) => {
                                                context.beginPath();
                                                context.rect(0, 0, uploadAlignments[3].width, uploadAlignments[3].height);
                                                context.closePath();
                                            }}>
                                                <Image
                                                    ref={imageUploadRef4}
                                                    draggable
                                                    onDragStart={() => {
                                                        selectShape('image4');
                                                    }}
                                                    onClick={() => {
                                                        selectedId === 'image4' ? selectShape(null) : selectShape('image4');
                                                    }}
                                                    onTouchStart={() => {
                                                        selectedId === 'image4' ? selectShape(null) : selectShape('image4');
                                                    }}
                                                // image={`https://backendapi.meridatechminds.com/media/images/Digitalmarketing.jpeg`}
                                                />
                                            </Group>

                                        </Layer>


                                    }
                                    {
                                        textAlignments && textAlignments.length == 0 && <Layer>
                                            <Text
                                                ref={freeTextRef}
                                                x={50}
                                                y={400}
                                                width={80}
                                                fontSize={25}
                                                wrap='wrap'
                                                align='center'
                                                verticalAlign='middle'
                                                text={freeText.text}
                                                draggable
                                                fill={freeText.color}
                                                fontFamily={freeText.fontFamily}
                                                // fontSize={freeText.size}
                                                onClick={() => {
                                                    selectedId == 'freetext' ? selectShape(null) : selectShape('freetext')
                                                }}
                                                onTouchStart={() => {
                                                    selectedId == 'freetext' ? selectShape(null) : selectShape('freetext')
                                                }}
                                                onDragStart={() => {
                                                    selectedId == 'freetext' ? selectShape(null) : selectShape('freetext')
                                                }}



                                            />
                                        </Layer>
                                    }

                                    {/* Layer for text */}
                                    {textAlignments && textAlignments[0] &&

                                        <Layer width={Number(textAlignments[0].width)}
                                            height={Number(textAlignments[0].height)}  >
                                            {selectedId == 'text1' && <Rect
                                                width={Number(textAlignments[0].width)}
                                                height={Number(textAlignments[0].height)}
                                                stroke="black"  // Border color
                                                strokeWidth={1} // Border width
                                                x={textAlignments[0].x}
                                                y={textAlignments[0].y}
                                            />}
                                            <Text
                                                width={Number(textAlignments[0].width)}
                                                height={Number(textAlignments[0].height)}
                                                ref={textRef}
                                                fill={textDetails[0].color}
                                                x={textAlignments[0].x}
                                                y={textAlignments[0].y}
                                                fontFamily={`${textDetails[0].fontFamily}`}
                                                wrap='wrap'
                                                text={textDetails[0].text}
                                                fontSize={textDetails[0].size}
                                                align='center'
                                                verticalAlign='middle'
                                                fontVariant={textStyle.bold !== undefined ? textStyle.bold : ''}
                                                fontStyle={textStyle.style != undefined ? textStyle.style : ''}
                                                onClick={() => {
                                                    selectShape('text1')
                                                }}
                                                onTouchStart={() => {
                                                    selectShape('text1')
                                                }}

                                            />
                                        </Layer>
                                    }
                                    {textAlignments && textAlignments[1] &&

                                        <Layer width={Number(textAlignments[1].width)}
                                            height={Number(textAlignments[1].height)} >
                                            {selectedId == 'text2' && <Rect
                                                width={Number(textAlignments[1].width)}
                                                height={Number(textAlignments[1].height)}
                                                stroke="black"  // Border color
                                                strokeWidth={1} // Border width
                                                x={textAlignments[1].x}
                                                y={textAlignments[1].y}
                                            />}
                                            <Text
                                                width={Number(textAlignments[1].width)}
                                                height={Number(textAlignments[1].height)}
                                                ref={textRef2}
                                                fill={textDetails[1].color}
                                                x={textAlignments[1].x}
                                                y={textAlignments[1].y}
                                                fontFamily={`${textDetails[1].fontFamily}`}
                                                wrap='wrap'
                                                text={textDetails[1].text}
                                                fontSize={textDetails[1].size}
                                                align='center'
                                                verticalAlign='middle'
                                                fontVariant={textStyle.bold !== undefined ? textStyle.bold : ''}
                                                fontStyle={textStyle.style != undefined ? textStyle.style : ''}
                                                onClick={() => {
                                                    selectShape('text2')
                                                }}
                                                onTouchStart={() => {
                                                    selectShape('text2')
                                                }}

                                            />
                                        </Layer>
                                    }
                                    {textAlignments && textAlignments[2] &&

                                        <Layer width={Number(textAlignments[2].width)}
                                            height={Number(textAlignments[2].height)} onClick={() => {
                                                selectShape('text3')
                                            }} >
                                            {selectedId == 'text3' && <Rect
                                                width={Number(textAlignments[2].width)}
                                                height={Number(textAlignments[2].height)}
                                                stroke="black"  // Border color
                                                strokeWidth={1} // Border width
                                                x={textAlignments[2].x}
                                                y={textAlignments[2].y}
                                            />}
                                            <Text
                                                width={Number(textAlignments[2].width)}
                                                height={Number(textAlignments[2].height)}
                                                ref={textRef3}
                                                fill={textDetails[2].color}
                                                x={textAlignments[2].x}
                                                y={textAlignments[2].y}
                                                fontFamily={`${textDetails[2].fontFamily}`}
                                                wrap='wrap'
                                                text={textDetails[2].text}
                                                fontSize={textDetails[2].size}
                                                align='center'
                                                verticalAlign='middle'
                                                fontVariant={textStyle.bold !== undefined ? textStyle.bold : ''}
                                                fontStyle={textStyle.style != undefined ? textStyle.style : ''}
                                                onClick={() => {
                                                    selectShape('text3')
                                                }}
                                                onTouchStart={() => {
                                                    selectShape('text3')
                                                }}

                                            />
                                        </Layer>
                                    }

                                    {/* Layer for second image */}
                                    <Layer>
                                        {clipath && (
                                            <Image
                                                // image={cameraRef.current}
                                                ref={cameraRef}
                                                width={320}
                                                listening={false}
                                            />
                                        )}
                                    </Layer>

                                    {/* Transformer layer */}
                                    <Layer>
                                        <Transformer
                                            ref={trRef}
                                            enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                                        />
                                    </Layer>
                                </Stage>
                                {uploadAlignments && uploadAlignments.map((x, index) => (
                                    <div key={index} style={{ width: `${x.width}px`, height: `${x.height}px`, position: 'absolute', left: `${x.x}px`, top: `${x.y}px` }}
                                        className={`border-1 flex items-center justify-center ${[...imageFilledIndex].indexOf(index + 1) != -1 ? 'd-none' : ''} z-10`}>
                                        <button className='p-2 px-3 bg-violet-500 shadow rounded text-white text-xs my-auto '>
                                            <label className='' htmlFor={`uploadimg${index}`}>Upload image</label>
                                            <input type="file" className='hidden' id={`uploadimg${index}`} onChange={(e) => handleImageUpload(e, index + 1)} />
                                        </button>
                                    </div>
                                ))
                                }
                                {!uploadImageUrl && uploadAlignments.length == 0 &&
                                    <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
                                        <button className='p-3 text-white shadow bg-violet-500 w-28 rounded-xl '>
                                            <label htmlFor="uploadimg" className='cursor-pointer'>Upload image</label>
                                            <input type="file" className='hidden' id='uploadimg' onChange={handleImageUpload} />
                                        </button>
                                    </div>
                                }
                            </div>}

                            {/* Second clipath Upload */}
                            {clipath1 && <div className={`${flipside == 'flip2' ? '' : 'hidden'} h-fit relative w-[320px] overflow-hidden rounded-3xl`}>
                                <Stage
                                    width={320}
                                    className=' mx-auto'
                                    // onMouseLeave={() => {
                                    //     selectShape(null)
                                    // }}
                                    onMouseDown={(e) => {
                                        // deselect when clicked on empty area
                                        const clickedOnEmpty = e.target === e.target.getStage();
                                        if (clickedOnEmpty) {
                                            selectShape1(null);
                                        }
                                    }}
                                    ref={stageRef1}
                                >
                                    {/* Layer for uploaded image */}
                                    <Layer >
                                        {uploadImageUrl1 && (
                                            <Image
                                                y={300}
                                                ref={uploadImageRef1}
                                                draggable
                                                onDragStart={() => {
                                                    selectShape1('image');
                                                }}
                                                onClick={() => {
                                                    selectedId1 === 'image' ? selectShape1(null) : selectShape1('image');
                                                }}
                                                onTouchStart={() => {
                                                    selectedId1 === 'image' ? selectShape1(null) : selectShape1('image');

                                                }}
                                            />
                                        )}
                                    </Layer>


                                    {
                                        uploadAlignments1 && uploadAlignments1[0] && imageUrl11 &&

                                        <Layer x={uploadAlignments1[0].x} y={uploadAlignments1[0].y} width={uploadAlignments1[0].width} height={uploadAlignments1[0].height} >
                                            <Group clipFunc={(context) => {
                                                context.beginPath();
                                                context.rect(0, 0, uploadAlignments1[0].width, uploadAlignments1[0].height);
                                                context.closePath();
                                            }}>
                                                <Image
                                                    ref={imageUploadRef11}
                                                    draggable
                                                    onDragStart={() => {
                                                        selectShape1('image1');
                                                        // console.log('move');
                                                    }}
                                                    onClick={() => {
                                                        selectedId1 === 'image1' ? selectShape1(null) : selectShape1('image1');
                                                    }}
                                                    onTouchStart={() => {
                                                        selectedId1 === 'image1' ? selectShape1(null) : selectShape1('image1');
                                                    }}
                                                // image={`https://backendapi.meridatechminds.com/media/images/Digitalmarketing.jpeg`}
                                                />
                                            </Group>
                                        </Layer>
                                    }
                                    {
                                        uploadAlignments1 && uploadAlignments1[1] && imageUrl12 &&

                                        <Layer x={uploadAlignments1[1].x} y={uploadAlignments1[1].y} width={uploadAlignments1[1].width} height={uploadAlignments1[1].height} >
                                            <Group clipFunc={(context) => {
                                                context.beginPath();
                                                context.rect(0, 0, uploadAlignments1[1].width, uploadAlignments1[1].height);
                                                context.closePath();
                                            }}>
                                                <Image
                                                    ref={imageUploadRef12}
                                                    draggable
                                                    onDragStart={() => {
                                                        selectShape1('image2');
                                                    }}
                                                    onClick={() => {
                                                        selectedId1 === 'image2' ? selectShape1(null) : selectShape1('image2');
                                                    }}
                                                    onTouchStart={() => {
                                                        selectedId1 === 'image2' ? selectShape1(null) : selectShape1('image2');
                                                    }}
                                                // image={`https://backendapi.meridatechminds.com/media/images/Digitalmarketing.jpeg`}
                                                />
                                            </Group>

                                        </Layer>


                                    }
                                    {
                                        uploadAlignments1 && uploadAlignments1[2] && imageUrl13 &&

                                        <Layer x={uploadAlignments1[2].x} y={uploadAlignments1[2].y} width={uploadAlignments1[2].width} height={uploadAlignments1[2].height} >
                                            <Group clipFunc={(context) => {
                                                context.beginPath();
                                                context.rect(0, 0, uploadAlignments1[2].width, uploadAlignments1[2].height);
                                                context.closePath();
                                            }}>
                                                <Image
                                                    ref={imageUploadRef13}
                                                    draggable
                                                    onDragStart={() => {
                                                        selectShape1('image3');
                                                    }}
                                                    onClick={() => {
                                                        selectedId1 === 'image3' ? selectShape1(null) : selectShape1('image3');
                                                    }}
                                                    onTouchStart={() => {
                                                        selectedId1 === 'image3' ? selectShape1(null) : selectShape1('image3');
                                                    }}
                                                // image={`https://backendapi.meridatechminds.com/media/images/Digitalmarketing.jpeg`}
                                                />
                                            </Group>

                                        </Layer>


                                    }
                                    {
                                        uploadAlignments1 && uploadAlignments1[3] && imageUrl14 &&

                                        <Layer x={uploadAlignments1[3].x} y={uploadAlignments1[3].y} width={uploadAlignments1[3].width} height={uploadAlignments1[3].height} >
                                            <Group clipFunc={(context) => {
                                                context.beginPath();
                                                context.rect(0, 0, uploadAlignments1[3].width, uploadAlignments1[3].height);
                                                context.closePath();
                                            }}>
                                                <Image
                                                    ref={imageUploadRef14}
                                                    draggable
                                                    onDragStart={() => {
                                                        selectShape1('image4');
                                                    }}
                                                    onClick={() => {
                                                        selectedId === 'image4' ? selectShape1(null) : selectShape1('image4');
                                                    }}
                                                    onTouchStart={() => {
                                                        selectedId === 'image4' ? selectShape1(null) : selectShape1('image4');
                                                    }}
                                                // image={`https://backendapi.meridatechminds.com/media/images/Digitalmarketing.jpeg`}
                                                />
                                            </Group>

                                        </Layer>


                                    }
                                    {
                                        textAlignments1 && textAlignments1.length == 0 && <Layer>
                                            <Text
                                                ref={freeTextRef1}
                                                x={50}
                                                y={400}
                                                width={80}
                                                fontSize={25}

                                                wrap='wrap'
                                                align='center'
                                                verticalAlign='middle'
                                                text={freeText1.text}
                                                draggable
                                                fill={freeText1.color}
                                                fontFamily={freeText1.fontFamily}
                                                // fontSize={freeText.size}
                                                onClick={() => {
                                                    selectedId1 == 'freetext' ? selectShape1(null) : selectShape1('freetext')
                                                }}
                                                onTouchStart={() => {
                                                    selectedId1 == 'freetext' ? selectShape1(null) : selectShape1('freetext')
                                                }}
                                                onDragStart={() => {
                                                    selectShape1('freetext')
                                                }}
                                            />
                                        </Layer>
                                    }
                                    {/* Layer for text */}
                                    {textAlignments1 && textAlignments1[0] &&

                                        <Layer width={Number(textAlignments1[0].width)}
                                            height={Number(textAlignments1[0].height)}  >
                                            {selectedId1 == 'text1' && <Rect
                                                width={Number(textAlignments1[0].width)}
                                                height={Number(textAlignments1[0].height)}
                                                stroke="black"  // Border color
                                                strokeWidth={1} // Border width
                                                x={textAlignments1[0].x}
                                                y={textAlignments1[0].y}
                                            />}
                                            <Text
                                                width={Number(textAlignments1[0].width)}
                                                height={Number(textAlignments1[0].height)}
                                                ref={textRef11}
                                                fill={textDetails1[0].color}
                                                x={textAlignments1[0].x}
                                                y={textAlignments1[0].y}
                                                fontFamily={`${textDetails1[0].fontFamily}`}
                                                wrap='wrap'
                                                text={textDetails1[0].text}
                                                fontSize={textDetails1[0].size}
                                                align='center'
                                                verticalAlign='middle'
                                                onClick={() => {
                                                    selectShape1('text1')
                                                }}
                                                onTouchStart={() => {
                                                    selectShape1('text1')
                                                }}
                                            />
                                        </Layer>
                                    }
                                    {textAlignments1 && textAlignments1[1] &&

                                        <Layer width={Number(textAlignments1[1].width)}
                                            height={Number(textAlignments1[1].height)} >
                                            {selectedId1 == 'text2' && <Rect
                                                width={Number(textAlignments1[1].width)}
                                                height={Number(textAlignments1[1].height)}
                                                stroke="black"  // Border color
                                                strokeWidth={1} // Border width
                                                x={textAlignments1[1].x}
                                                y={textAlignments1[1].y}
                                            />}
                                            <Text
                                                width={Number(textAlignments1[1].width)}
                                                height={Number(textAlignments1[1].height)}
                                                ref={textRef12}
                                                fill={textDetails1[1].color}
                                                x={textAlignments1[1].x}
                                                y={textAlignments1[1].y}
                                                fontFamily={`${textDetails1[1].fontFamily}`}
                                                wrap='wrap'
                                                text={textDetails1[1].text}
                                                fontSize={textDetails1[1].size}
                                                align='center'
                                                verticalAlign='middle'
                                                onClick={() => {
                                                    selectShape1('text2')
                                                }}
                                                onTouchStart={() => {
                                                    selectShape1('text2')
                                                }}
                                            />
                                        </Layer>
                                    }
                                    {textAlignments1 && textAlignments1[2] &&

                                        <Layer width={Number(textAlignments1[2].width)}
                                            height={Number(textAlignments1[2].height)} onClick={() => {
                                                selectShape1('text3')
                                            }} >
                                            {selectedId1 == 'text3' && <Rect
                                                width={Number(textAlignments1[2].width)}
                                                height={Number(textAlignments1[2].height)}
                                                stroke="black"  // Border color
                                                strokeWidth={1} // Border width
                                                x={textAlignments1[2].x}
                                                y={textAlignments1[2].y}
                                            />}
                                            <Text
                                                width={Number(textAlignments1[2].width)}
                                                height={Number(textAlignments1[2].height)}
                                                ref={textRef13}
                                                fill={textDetails1[2].color}
                                                x={textAlignments1[2].x}
                                                y={textAlignments1[2].y}
                                                fontFamily={`${textDetails1[2].fontFamily}`}
                                                wrap='wrap'
                                                text={textDetails1[2].text}
                                                fontSize={textDetails1[2].size}
                                                align='center'
                                                verticalAlign='middle'
                                                onClick={() => {
                                                    selectShape1('text3')
                                                }}
                                                onTouchStart={() => {
                                                    selectShape1('text3')
                                                }}
                                            />
                                        </Layer>
                                    }
                                    {/* Layer for second image */}
                                    <Layer>
                                        {clipath1 && (
                                            <Image
                                                // image={cameraRef.current}
                                                ref={cameraRef1}
                                                width={320}
                                                listening={false}
                                            />
                                        )}
                                    </Layer>

                                    {/* Transformer layer */}
                                    <Layer>
                                        <Transformer
                                            ref={trRef1}
                                            enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                                        />
                                    </Layer>
                                </Stage>
                                {uploadAlignments1 && uploadAlignments1.map((x, index) => (
                                    <div key={index} style={{ width: `${x.width}px`, height: `${x.height}px`, position: 'absolute', left: `${x.x}px`, top: `${x.y}px` }}
                                        className={`border-1 flex items-center justify-center ${[...imageFilledIndex1].indexOf(index + 1) != -1 ? 'd-none' : ''} z-10`}>
                                        <button className='p-2 px-3 bg-red-500 shadow rounded text-white text-xs my-auto '>
                                            <label className='' htmlFor={`Suploadimg${index}`}>Upload image</label>
                                            <input type="file" className='hidden' id={`Suploadimg${index}`}
                                                onChange={(e) => { handleImageUploadSecond(e, index + 1) }} />
                                        </button>
                                    </div>
                                ))
                                }
                                {!uploadImageUrl1 && uploadAlignments1.length == 0 &&
                                    <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
                                        <button className='p-3 text-white shadow bg-violet-500 w-28 rounded-xl '>
                                            <label htmlFor="SEuploadimg" className='cursor-pointer'>Upload image</label>
                                            <input type="file" className='hidden' id='SEuploadimg' onChange={handleImageUploadSecond} />
                                        </button>
                                    </div>
                                }
                            </div>}
                            {/* Flip button */}
                            {
                                clipath1 && <div className='px-4 '>
                                    <button onClick={() => setflipside('flip1')} className={`${flipside == 'flip1' ? 'bg-blue-500 text-white' : 'bg-slate-100'} 
                                    p-2 px-3 transition duration-300  rounded-full w-fit h-fit mx-2`}>
                                        1
                                    </button>
                                    <button onClick={() => setflipside('flip2')} className={`${flipside == 'flip2' ? 'bg-blue-500 text-white' : 'bg-slate-100'} 
                                    p-2 px-3 rounded-full w-fit h-fit mx-2`}>
                                        2
                                    </button>
                                </div>
                            }
                        </div>
                        {/*  */}

                        {/* Right side */}
                        <div className='col-lg-6  rounded bg-opacity-10 flex py-2 item-center flex-col'>
                            {<div className={` py-3 ${flipside == 'flip1' ? '' : 'hidden'}`}>
                                {/* Image Upload icon */}
                                {clipath1 && <h4>First flip </h4>}
                                <div className='border-1 shadow rounded bg-white p-2'>
                                    <h6 className='fw-semibold '>Upload Image</h6>
                                    {uploadAlignments && uploadAlignments.length == 0 &&
                                        <div className='flex justify-around items-center'>
                                            <div>
                                                <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full' htmlFor="upload">
                                                    <img src={uploadImageUrl ? uploadImageUrl : require('../Assest/emptyimage.jpg')} className='w-28 h-28 object-contain ' alt="" />
                                                </label>
                                                <input id='upload' type='file' accept='image/*' className='mx-2 hidden '
                                                    onChange={handleImageUpload} />
                                            </div>
                                            {uploadImageUrl &&
                                                <div onClick={() => setuploadImageUrl('')} className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'>
                                                    <Dustbin />
                                                    Remove
                                                </div>}
                                            <div className='my-3 cursor-pointer w-fit '>
                                                <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'
                                                    htmlFor="upload">
                                                    <UploadIcon size={20} /> {uploadImageUrl ? "Replace" : "Upload"} </label>
                                                <input id='upload' type='file' accept='image/*' className='mx-2 hidden '
                                                    onChange={handleImageUpload} />
                                            </div>
                                        </div>
                                    }
                                    {/* For the multiple image and layouts */}
                                    <div className=''>
                                        {
                                            uploadAlignments && uploadAlignments.length > 0 &&
                                            <div className='my-3 cursor-pointer w-fit flex-wrap flex gap-3 '>
                                                {/* <div>
                                                    <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'
                                                        htmlFor="upload"> <img className='w-28 h-28 object-contain '
                                                            src={((selectedId == null || selectedId == 'image1') && imageUrl1) ? imageUrl1
                                                                : selectedId == 'image2' ? imageUrl2
                                                                    : selectedId == 'image3' ? imageUrl3
                                                                        : selectedId == 'image4' ? imageUrl4
                                                                            : require('../Assest/emptyimage.jpg')}
                                                            alt="Upload image" />
                                                    </label>
                                                    <input id='upload' type='file' accept='image/*' className='mx-2 hidden ' onChange={(e) => {
                                                        if (selectedId == null || selectedId == 'image1')
                                                            handleImageUpload(e, 1)
                                                        else if (selectedId == 'image2')
                                                            handleImageUpload(e, 2)
                                                        else if (selectedId == 'image3')
                                                            handleImageUpload(e, 3)
                                                        else if (selectedId == 'image4')
                                                            handleImageUpload(e, 4)
                                                    }} />
                                                </div> */}
                                                {/* {(imageUrl1 || imageUrl2 || imageUrl3 || imageUrl4) &&
                                                 <div
                                                    onClick={() =>{
                                                        if (selectedId == null || selectedId == 'image1'){
                                                            setImageUrl1('');selectShape(null);
                                                        setImageFilledIndex((prev)=>prev.filter((i)=>i!=1) )}
                                                        else if (selectedId == 'image2'){
                                                            setImageUrl2('');selectShape(null);
                                                        setImageFilledIndex((prev)=>prev.filter((i)=>i!=2) )}
                                                        else if (selectedId == 'image3'){
                                                            setImageUrl3('');selectShape(null);
                                                        setImageFilledIndex((prev)=>prev.filter((i)=>i!=3) )}
                                                        else if (selectedId == 'image4'){
                                                            setImageUrl4('');selectShape(null); 
                                                        setImageFilledIndex((prev)=>prev.filter((i)=>i!=4) )}
                                                    }} className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'>
                                                    <Dustbin />
                                                    Remove
                                                </div>} */}
                                                {/* <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'
                                                    htmlFor="upload">
                                                    <UploadIcon />  Upload </label>
                                                <input id='upload' type='file' accept='image/*' className='mx-2 hidden ' onChange={(e) => {
                                                    if (selectedId == null || selectedId == 'image1')
                                                        handleImageUpload(e, 1)
                                                    else if (selectedId == 'image2')
                                                        handleImageUpload(e, 2)
                                                    else if (selectedId == 'image3')
                                                        handleImageUpload(e, 3)
                                                    else if (selectedId == 'image4')
                                                        handleImageUpload(e, 4)
                                                }} /> */}

                                                {/* Upload image 1 */}
                                                <div className={`items-center ${uploadAlignments[0] ? '' : 'd-none'}  flex gap-2`}>
                                                    Image 1 :
                                                    <input onChange={(e) => { handleImageUpload(e, 1) }} type="file" className='hidden' id='upload1' />
                                                    <label htmlFor="upload1">
                                                        <img src={imageUrl1 ? imageUrl1 : require('../Assest/emptyimage.jpg')} className='w-16 object-contain h-16 ' alt="" /> </label>
                                                    <div onClick={() => {
                                                        setImageUrl1(''); selectShape(null); setImageFilledIndex((prev) => prev.filter((i) => i != 1))
                                                    }} className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'>
                                                        <Dustbin /> Remove
                                                    </div>
                                                    <div>
                                                        <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full' htmlFor="upload1">
                                                            <UploadIcon /> Upload</label>
                                                        <input onChange={(e) => { handleImageUpload(e, 1) }} type="file" className='hidden' id='upload1' />
                                                    </div>
                                                </div>
                                                {/* Upload image 2 */}
                                                <div className={`items-center ${uploadAlignments[1] ? '' : 'd-none'}  flex gap-2`}>
                                                    Image 2 :
                                                    <input onChange={(e) => { handleImageUpload(e, 2) }} type="file" className='hidden' id='upload2' />
                                                    <label htmlFor="upload2">
                                                        <img src={imageUrl2 ? imageUrl2 : require('../Assest/emptyimage.jpg')} className='w-16 object-contain h-16 ' alt="" /> </label>
                                                    <div onClick={() => {
                                                        setImageUrl2(''); selectShape(null); setImageFilledIndex((prev) => prev.filter((i) => i != 2))
                                                    }} className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'>
                                                        <Dustbin /> Remove
                                                    </div>
                                                    <div>
                                                        <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full' htmlFor="upload2">
                                                            <UploadIcon /> Upload</label>
                                                        <input onChange={(e) => { handleImageUpload(e, 2) }} type="file" className='hidden' id='upload2' />
                                                    </div>
                                                </div>
                                                {/* Upload image 3 */}
                                                <div className={`items-center ${uploadAlignments[2] ? '' : 'd-none'}  flex gap-2`}>
                                                    Image 3 :
                                                    <input onChange={(e) => { handleImageUpload(e, 3) }} type="file" className='hidden' id='upload3' />
                                                    <label htmlFor="upload3">
                                                        <img src={imageUrl3 ? imageUrl3 : require('../Assest/emptyimage.jpg')} className='w-16 object-contain h-16 ' alt="" /> </label>
                                                    <div onClick={() => {
                                                        setImageUrl3(''); selectShape(null); setImageFilledIndex((prev) => prev.filter((i) => i != 3))
                                                    }} className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'>
                                                        <Dustbin /> Remove
                                                    </div>
                                                    <div>
                                                        <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full' htmlFor="upload3">
                                                            <UploadIcon /> Upload</label>
                                                        <input onChange={(e) => { handleImageUpload(e, 3) }} type="file" className='hidden' id='upload3' />
                                                    </div>
                                                </div>
                                                {/* Upload image 4 */}
                                                <div className={`items-center ${uploadAlignments[3] ? '' : 'd-none'}  flex gap-2`}>
                                                    Image 4 :
                                                    <input onChange={(e) => { handleImageUpload(e, 4) }} type="file" className='hidden' id='upload4' />
                                                    <label htmlFor="upload4">
                                                        <img src={imageUrl4 ? imageUrl4 : require('../Assest/emptyimage.jpg')} className='w-16 object-contain h-16 ' alt="" /> </label>
                                                    <div onClick={() => {
                                                        setImageUrl4(''); selectShape(null); setImageFilledIndex((prev) => prev.filter((i) => i != 4))
                                                    }} className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'>
                                                        <Dustbin /> Remove
                                                    </div>
                                                    <div>
                                                        <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full' htmlFor="upload4">
                                                            <UploadIcon /> Upload</label>
                                                        <input onChange={(e) => { handleImageUpload(e, 4) }} type="file" className='hidden' id='upload4' />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                                <div className='bg-white rounded shadow p-3 my-2'>
                                    <h6 className='fw-semibold'> Text Editing : </h6>
                                    <div className='flex flex-wrap gap-2'> {
                                        textDetails && textDetails.map((x, index) => (
                                            <div className='my-2' key={index}>
                                                <p className='fw-semibold'>  Text {index + 1} : </p>
                                                <input type='text'
                                                    value={x.text}
                                                    className=' mx-2 px-1 outline-none border-b-2  '
                                                    onChange={(e) => {
                                                        setTextDetails((prev) => prev.map((y) =>
                                                            y.id == x.id ? { ...y, text: e.target.value } : y))
                                                    }} />
                                            </div>
                                        ))
                                    }
                                    </div>
                                    {
                                        textDetails && textDetails.length == 0 &&
                                        <div>
                                            <p className=''>
                                                Text here :

                                                <input type="text" className='my-2 mx-2 px-1 outline-none border-b-2  ' onChange={handleTextChange} value={freeText.text} />
                                            </p>
                                        </div>
                                    }

                                    {/* <button onClick={addText}>Add Text</button> */}

                                    {/* Get a Image Button */}
                                    {/* Font editing */}
                                    {<div className='' >
                                        {/* <p>Font Editor</p> */}
                                        {/* Font size */}
                                        <div className='my-2 flex gap-2 items-center'>
                                            <p className=' my-0'>Font Family : </p>
                                            <select onChange={(e) => {
                                                // console.log(e.target.value);
                                                if (selectedId == null || selectedId == 'freetext') {
                                                    setFreeText((prev) => ({
                                                        ...prev,
                                                        fontFamily: e.target.value
                                                    }))
                                                    return
                                                }
                                                if (selectedId == null || selectedId == 'text1') {
                                                    setTextDetails((prev) => {
                                                        const updateArry = [...prev]
                                                        updateArry[0] = { ...updateArry[0], 'fontFamily': e.target.value }
                                                        return updateArry
                                                    })
                                                }
                                                if (selectedId == 'text2') {
                                                    setTextDetails((prev) => {
                                                        const updateArry = [...prev]
                                                        updateArry[1] = { ...updateArry[1], 'fontFamily': e.target.value }
                                                        return updateArry
                                                    })
                                                }
                                                if (selectedId == 'text3') {
                                                    setTextDetails((prev) => {
                                                        const updateArry = [...prev]
                                                        updateArry[2] = { ...updateArry[2], 'fontFamily': e.target.value }
                                                        return updateArry
                                                    })
                                                }
                                            }} className='outline-none ' name="" id="">
                                                {/* <option value="">Select</option> */}
                                                {
                                                    allFont && allFont.map((x) => {
                                                        return (
                                                            <option style={{ fontFamily: `${x}` }} className={`${x}`} value={x}>{x}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                        </div>
                                        <div className='flex  items-center gap-2'>
                                            <p className=' my-2'>Color Picker : </p>
                                            <input type="color" className=' ' onChange={(e) => {
                                                if (selectedId == null || selectedId == 'freetext') {
                                                    setFreeText((prev) => ({
                                                        ...prev,
                                                        color: e.target.value
                                                    }))
                                                    return
                                                }
                                                if (selectedId == null || selectedId == 'text1') {
                                                    setTextDetails((prev) => {
                                                        const updateArry = [...prev]
                                                        updateArry[0] = { ...updateArry[0], 'color': e.target.value }
                                                        return updateArry
                                                    })
                                                }
                                                if (selectedId == 'text2') {
                                                    setTextDetails((prev) => {
                                                        const updateArry = [...prev]
                                                        updateArry[1] = { ...updateArry[1], 'color': e.target.value }
                                                        return updateArry
                                                    })
                                                }
                                                if (selectedId == 'text3') {
                                                    setTextDetails((prev) => {
                                                        const updateArry = [...prev]
                                                        updateArry[2] = { ...updateArry[2], 'color': e.target.value }
                                                        return updateArry
                                                    })
                                                }
                                            }} />
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>}
                            {/* CLipath second image start  */}
                            {
                                clipath1 && <div className={` py-3 ${flipside == 'flip2' ? '' : 'hidden'} `}>
                                    {/* Image Upload icon */}
                                    <h4>Second Flip </h4>
                                    <div className='border-1 shadow rounded bg-white p-2'>
                                        <h6 className='fw-semibold '>Upload  Image</h6>
                                        {uploadAlignments1 && uploadAlignments1.length == 0 &&
                                            <div className='flex justify-around items-center'>
                                                <div>
                                                    <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full' htmlFor="uploadSE">
                                                        <img src={uploadImageUrl1 ? uploadImageUrl1 : require('../Assest/emptyimage.jpg')} className='w-28 h-28 object-contain ' alt="" />
                                                    </label>
                                                    <input id='uploadSE' type='file' accept='image/*' className='mx-2 hidden '
                                                        onChange={handleImageUploadSecond} />
                                                </div>
                                                {uploadImageUrl1 &&
                                                    <div onClick={() => setuploadImageUrl1('')} className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'>
                                                        <Dustbin />
                                                        Remove
                                                    </div>}
                                                <div className='my-3 cursor-pointer w-fit '>
                                                    <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'
                                                        htmlFor="uploadSE">
                                                        <UploadIcon size={20} /> {uploadImageUrl1 ? "Replace" : "Upload"} </label>
                                                    <input id='uploadSE' type='file' accept='image/*' className='mx-2 hidden '
                                                        onChange={handleImageUploadSecond} />
                                                </div>
                                            </div>
                                        }
                                        {/* For the multiple image and layouts */}
                                        <div className=''>
                                            {
                                                uploadAlignments1 && uploadAlignments1.length > 0 &&
                                                <div className='my-3 cursor-pointer w-fit flex-wrap flex gap-3 '>
                                                    {/* Upload image 1 */}
                                                    <div className={`items-center ${uploadAlignments1[0] ? '' : 'd-none'}  flex gap-2`}>
                                                        Image 1 :
                                                        <input onChange={(e) => { handleImageUploadSecond(e, 1) }} type="file" className='hidden' id='upload1SE' />
                                                        <label htmlFor="upload1SE">
                                                            <img src={imageUrl11 ? imageUrl11 : require('../Assest/emptyimage.jpg')} className='w-16 object-contain h-16 ' alt="" />
                                                        </label>
                                                        <div onClick={() => {
                                                            setImageUrl11(''); selectShape1(null); setImageFilledIndex1((prev) => prev.filter((i) => i != 1))
                                                        }} className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'>
                                                            <Dustbin /> Remove
                                                        </div>
                                                        <div>
                                                            <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full' htmlFor="upload1SW">
                                                                <UploadIcon /> Upload</label>
                                                            <input onChange={(e) => { handleImageUploadSecond(e, 1) }} type="file" className='hidden' id='upload1SW' />
                                                        </div>
                                                    </div>
                                                    {/* Upload image 2 */}
                                                    <div className={`items-center ${uploadAlignments1[1] ? '' : 'd-none'}  flex gap-2`}>
                                                        Image 2 :
                                                        <input onChange={(e) => { handleImageUploadSecond(e, 2) }} type="file" className='hidden' id='uploadSE' />
                                                        <label htmlFor="uploadSE">
                                                            <img src={imageUrl12 ? imageUrl12 : require('../Assest/emptyimage.jpg')} className='w-16 object-contain h-16 ' alt="" /> </label>
                                                        <div onClick={() => {
                                                            setImageUrl12(''); selectShape1(null); setImageFilledIndex1((prev) => prev.filter((i) => i != 2))
                                                        }} className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'>
                                                            <Dustbin /> Remove
                                                        </div>
                                                        <div>
                                                            <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full' htmlFor="upload2SD">
                                                                <UploadIcon /> Upload</label>
                                                            <input onChange={(e) => { handleImageUploadSecond(e, 2) }} type="file" className='hidden' id='upload2SD' />
                                                        </div>
                                                    </div>
                                                    {/* Upload image 3 */}
                                                    <div className={`items-center ${uploadAlignments1[2] ? '' : 'd-none'}  flex gap-2`}>
                                                        Image 3 :
                                                        <input onChange={(e) => { handleImageUploadSecond(e, 3) }} type="file" className='hidden' id='upload3SD' />
                                                        <label htmlFor="upload3SD">
                                                            <img src={imageUrl13 ? imageUrl13 : require('../Assest/emptyimage.jpg')} className='w-16 object-contain h-16 ' alt="" /> </label>
                                                        <div onClick={() => {
                                                            setImageUrl13(''); selectShape1(null); setImageFilledIndex1((prev) => prev.filter((i) => i != 3))
                                                        }} className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'>
                                                            <Dustbin /> Remove
                                                        </div>
                                                        <div>
                                                            <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full' htmlFor="upload3SD">
                                                                <UploadIcon /> Upload</label>
                                                            <input onChange={(e) => { handleImageUploadSecond(e, 3) }} type="file" className='hidden' id='upload3SD' />
                                                        </div>
                                                    </div>
                                                    {/* Upload image 4 */}
                                                    <div className={`items-center ${uploadAlignments1[3] ? '' : 'd-none'}  flex gap-2`}>
                                                        Image 4 :
                                                        <input onChange={(e) => { handleImageUploadSecond(e, 4) }} type="file" className='hidden' id='upload4SD' />
                                                        <label htmlFor="upload4SD">
                                                            <img src={imageUrl14 ? imageUrl14 : require('../Assest/emptyimage.jpg')} className='w-16 object-contain h-16 ' alt="" /> </label>
                                                        <div onClick={() => {
                                                            setImageUrl14(''); selectShape1(null); setImageFilledIndex1((prev) => prev.filter((i) => i != 4))
                                                        }} className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full'>
                                                            <Dustbin /> Remove
                                                        </div>
                                                        <div>
                                                            <label className='cursor-pointer hover:text-teal-500 flex flex-col items-center justify-center p-2 rounded-full' htmlFor="upload4SD">
                                                                <UploadIcon /> Upload</label>
                                                            <input onChange={(e) => { handleImageUploadSecond(e, 4) }} type="file" className='hidden' id='upload4SD' />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='bg-white rounded shadow p-3 my-2'>
                                        <h6 className='fw-semibold'> Text Editing : </h6>
                                        <div className='flex flex-wrap gap-2'> {
                                            textDetails1 && textDetails1.map((x, index) => (
                                                <div className='my-2' key={index}>
                                                    <p className='fw-semibold'>  Text {index + 1} : </p>
                                                    <input type='text'
                                                        value={x.text}
                                                        className=' mx-2 px-1 outline-none border-b-2  '
                                                        onChange={(e) => {
                                                            setTextDetails1((prev) => prev.map((y) =>
                                                                y.id == x.id ? { ...y, text: e.target.value } : y))
                                                        }} />
                                                </div>
                                            ))
                                        }
                                        </div>
                                        {
                                            textDetails1 && textDetails1.length == 0 &&
                                            <div>
                                                <p className=''>
                                                    Text here :

                                                    <input type="text" className='my-2 mx-2 px-1 outline-none border-b-2  ' onChange={handleTextChange1} value={freeText1.text} />
                                                </p>
                                            </div>
                                        }

                                        {/* <button onClick={addText}>Add Text</button> */}

                                        {/* Get a Image Button */}
                                        {/* Font editing */}
                                        {<div className='' >
                                            {/* <p>Font Editor</p> */}
                                            {/* Font size */}
                                            <div className='my-2 flex gap-2 items-center'>
                                                <p className=' my-0'>Font Family : </p>
                                                <select onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    if (selectedId1 == null || selectedId1 == 'freetext') {
                                                        setFreeText1((prev) => ({
                                                            ...prev,
                                                            fontFamily: e.target.value
                                                        }))
                                                        return
                                                    }
                                                    if (selectedId1 == null || selectedId1 == 'text1') {
                                                        setTextDetails1((prev) => {
                                                            const updateArry = [...prev]
                                                            updateArry[0] = { ...updateArry[0], 'fontFamily': e.target.value }
                                                            return updateArry
                                                        })
                                                    }
                                                    if (selectedId1 == 'text2') {
                                                        setTextDetails1((prev) => {
                                                            const updateArry = [...prev]
                                                            updateArry[1] = { ...updateArry[1], 'fontFamily': e.target.value }
                                                            return updateArry
                                                        })
                                                    }
                                                    if (selectedId1 == 'text3') {
                                                        setTextDetails1((prev) => {
                                                            const updateArry = [...prev]
                                                            updateArry[2] = { ...updateArry[2], 'fontFamily': e.target.value }
                                                            return updateArry
                                                        })
                                                    }
                                                }} className='outline-none ' name="" id="">
                                                    {/* <option value="">Select</option> */}
                                                    {
                                                        allFont && allFont.map((x) => {
                                                            return (
                                                                <option style={{ fontFamily: `${x}` }} className={`${x}`} value={x}>{x}</option>
                                                            )
                                                        })
                                                    }
                                                </select>

                                            </div>
                                            <div className='flex  items-center gap-2'>
                                                <p className=' my-2'>Color Picker : </p>
                                                <input type="color" className=' ' onChange={(e) => {
                                                    if (selectedId1 == null || selectedId1 == 'freetext') {
                                                        setFreeText1((prev) => ({
                                                            ...prev,
                                                            color: e.target.value
                                                        }))
                                                        return
                                                    }
                                                    if (selectedId1 == null || selectedId1 == 'text1') {
                                                        setTextDetails1((prev) => {
                                                            const updateArry = [...prev]
                                                            updateArry[0] = { ...updateArry[0], 'color': e.target.value }
                                                            return updateArry
                                                        })
                                                    }
                                                    if (selectedId1 == 'text2') {
                                                        setTextDetails1((prev) => {
                                                            const updateArry = [...prev]
                                                            updateArry[1] = { ...updateArry[1], 'color': e.target.value }
                                                            return updateArry
                                                        })
                                                    }
                                                    if (selectedId1 == 'text3') {
                                                        setTextDetails1((prev) => {
                                                            const updateArry = [...prev]
                                                            updateArry[2] = { ...updateArry[2], 'color': e.target.value }
                                                            return updateArry
                                                        })
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            }
                            {/* Second image end */}
                            <div className='flex bg-white shadow p-3 rounded gap-3'>
                                <h6 className='fw-semibold'>Message:</h6>
                                <textarea name="" value={message} className='outline-none border-1 w-full p-2 rounded '
                                    placeholder='Enter your additional requests' onChange={(e) => setMessage(e.target.value)} rows={5} id=""></textarea>
                            </div>
                            {/* Save Button */}
                            <button onClick={handleSave} disabled={loading} className='block w-fit mx-auto p-2 px-3 bg-slate-700 rounded text-white my-2'>{loading ? 'loading' : " Add to the Cart"} </button>
                            {/* <FontSelector/> */}
                            {downloadUrl && (
                                <div>
                                    <a href={uploadImageUrl} download="Original_image.png" >
                                        <button className='bg-amber-700 rounded text-white p-2 px-3'>Download the Original Image</button>
                                    </a>
                                    <a href={downloadUrl} download='canvas_image.png'>
                                        <button className='text-white rounded mx-3 p-2 px-3 bg-red-800'> Download Image</button>
                                    </a>
                                    <img src={`${downloadUrl}`} alt="" />
                                </div>
                            )}

                        </div>
                    </>
                }
            </div ></>
    );
};

export default PersonalizeClipath
