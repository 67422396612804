import React, { useContext, useEffect, useState } from 'react'
import NavBar from '../Component/NavBar'
import TitleBanner from '../Component/TitleBanner'
import axios from 'axios'
import { hostname } from '../App'
import { useNavigate, useParams } from 'react-router'
import { Storage } from '../Context/StateStore'

const Variety = (props) => {
  const { category, model, brand } = useParams()
  let navigate=useNavigate()
  const {getBanners,banners}=useContext(Storage)
  const [products, setProducts] = useState()
  const [variety,setvariety]=useState()
  // console.log(model + brand);
  useEffect(() => {
    getBanners()
    axios.get(`${hostname}/api/varietyMB?model=${model}&brand=${brand}`).then((response) => {
      setProducts(response.data)
      // console.log(response.data);
    }).catch((error) =>{ 
      // console.log(error)

    })
    axios.get(`${hostname}/api/variety`).then((response)=>{
      setvariety(response.data)
    }).catch((error)=>{
      // console.log(error)

    })
  }, [])
  if(banners){
    var banner=[...banners].find((obj)=>obj.bannerName=='Shop')
  }
  return (
    <div>
      <NavBar />
     {banner&& <TitleBanner img={banner.imageUrl} page="Variety" />}
      {/* Variety */}
      <div className=' my-10'>
        <h4 className='text-center'>Varieties Available for this model</h4>
        {
          products != undefined && products.length>0 &&variety!=undefined? <>
          <div className='flex flex-wrap container mx-auto my-5 gap-4 justify-around sm:justify-start '>
            {
              variety.map((x)=>{
                // console.log(x);
                // console.log(products);
                const value=products.some(obj=>obj.vatriety==x._id)
                return(
                  <div onClick={()=>navigate(`/availablemodels/${brand}/${model}/${x._id}`)} 
                  className={`rounded-2xl w-[280px] cursor-pointer hoverin border-1 overflow-hidden 
                  ${value?'':'d-none'} `}>
                       <img className='w-full h-[280px] object-contain ' src={x.image} alt="variety image" />
                      <h4 className='poppins text-center my-2'>
                      {x.variety}
                      </h4>  
                  </div>
                )
              })
            }
          </div>
          </> : <div>
            <p>No Variety has been added in the Category</p>
          </div>
        }

      </div>

    </div>
  )
}

export default Variety
