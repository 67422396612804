import React, { useContext, useEffect, useState } from 'react'
import { Storage } from '../Context/StateStore'
import {useNavigate} from 'react-router'
import axios from 'axios'
import {Modal} from 'react-bootstrap'
import { hostname } from '../App'
const EditAccount = () => {
    const {userData,getUserData,setMessageDetails}=useContext(Storage)
    let user=JSON.parse(localStorage.getItem('user'))
    const navigate=useNavigate()
    const [recievedOTP,setrecievedOTP]=useState()
    const [showModal,setShow]=useState()
    let [loading,setLoading]=useState(false)
    let [showPassword,setShowPassword]=useState(false)
    let [errorMessage,seterrorMessage]=useState()
    const [enteredOTP,setEnteredOTP]=useState()
    let [passwordEnter,setPasswordEnter]=useState()
    const [userValue,setUserValue]=useState({
        id:user,
        email:'',
        phone:null,
        username:null,
        password:null,
    })
    const [registeredEmail,setRegisterEmail]=useState()
    const handleChanges=(e)=>{
        let {value,name}=e.target 
        setUserValue((prev)=>({
            ...prev,
            [name]:value
        }))
    }
    const handleSave=()=>{
        if(enteredOTP==recievedOTP)
        {axios.put(`${hostname}/api/user/${user}`,userValue).then((response)=>{
            // console.log(response.data);
            setShow(false)
            seterrorMessage('')
            setMessageDetails({
                visible: true,
                message: 'User Details has been Updated.',
                page: 'User updating',
                status: 'success',
                title: ''
              })

            
        }).catch((error)=>{
            // console.log(error);
        })}
        else{
            // console.log(recievedOTP +" "+enteredOTP);
            seterrorMessage('*Enter the correct OTP')
        }
    }
    const getOtp=()=>{
        axios.post(`${hostname}/api/userUpdate`,{username:userValue.username,email:registeredEmail}).then((response)=>{
               setrecievedOTP(response.data.otp)
               setShow(true)
        }).catch((error)=>{
            // console.log(error);
        })
    }
    useEffect(()=>{
        window.scrollTo(0,0)
       if(user){
        axios.get(`${hostname}/api/userId/${JSON.parse(localStorage.getItem('user'))}`).then((response) => {
            setUserValue(response.data)
            setRegisterEmail(response.data.email)
            // console.log(response.data);
          }).catch((error) => {
            // console.log(error);
          })
       }
    },[user])
    let changePassword=()=>{
       if(passwordEnter!='') {setLoading(true)
        axios.put(`${hostname}/api/user/${user}`,{
            password:passwordEnter
        }).then((response)=>{
            setLoading(false)
            setPasswordEnter('')
            setShowPassword(false)
            setMessageDetails({
                visible: true,
                message: 'User Password has been Updated.',
                page: 'User updating',
                status: 'success',
                title: ''
              })
            // console.log(response.data);
        }).catch((error)=>{
            setLoading(false)
            // console.log(error);
        })}
    }
  return (
    <div className='my-5'>
        <p className='text-center text-xl fw-semibold'>Edit Account</p>
       {
         userValue!=null &&<>
        
        <div className='container mx-auto'>
            <h4>Your Personal details</h4>
            <hr />
            <div>
                Full Name : 
                <input type="text" name='username' onChange={handleChanges}
                className='w-full rounded outline-none border-1 p-2 my-1' 
                value={userValue.username} />
            </div>
            <div>
                Email :
                <input type="text" name='email'  onChange={handleChanges} value={userValue.email} className='w-full rounded outline-none border-1 p-2 my-1' />
            </div>
            <div>
                Phone :
                <input type="number" name='phone' onChange={handleChanges} value={userValue.phone} className='w-full rounded outline-none border-1 p-2 my-1' />
            </div>
            <button onClick={()=>setShowPassword(true)} className='p-2 px-3 rounded my-2 bg-blue-500 text-white'> Change Password</button>
            <div className='flex justify-between my-2'>
                <button onClick={()=>navigate('/profile') } className='p-2 px-3 bg-slate-500 text-white rounded'>Back </button>
                <button onClick={getOtp} className='p-2 px-3 bg-green-500 text-white rounded'>Save </button>
            </div>

        </div>
        </>
       }
       {
        showModal && 
        <Modal centered show={showModal} onHide={()=>setShow(false)}>
            <Modal.Header closeButton>
                Enter the OTP 
            </Modal.Header>
            <Modal.Body>
                Giving the correct OTP change the data 
                <div className='my-2'>
                    Enter the OTP :
                    <input type="text" value={enteredOTP} onChange={(e)=>setEnteredOTP(e.target.value)} className='outline-none block p-2 border-1  ' />
                </div>
                <p className='text-red-600 text-center h-[30px]'>{errorMessage} </p>
                <div className='my-3 flex justify-end'>
                    <button onClick={handleSave} className='p-2 px-3 rounded border-1 mx-2 bg-blue-500 text-white '> Send </button>
                    <button onClick={getOtp} className='p-2 px-3 rounded border-1 bg-slate-500 text-white'> Generate OTP </button>
                </div>
            </Modal.Body>
        </Modal>
       }
       {
        showPassword && <Modal centered show={showPassword} onHide={()=>setShowPassword(false)} >
            <Modal.Header closeButton>
                Change the password
            </Modal.Header>
            <Modal.Body>
                Enter the Password : 
                <input type="text" className='p-2 mx-2 outline-none border-2 rounded' 
                value={passwordEnter} onChange={(e)=>setPasswordEnter(e.target.value)}/>
                <button disabled={loading} onClick={changePassword} className='flex ms-auto p-2 mx-2 bg-green-500 rounded my-3 text-white hover:bg-green-600'>
                    {loading?"loading...":"Save password"}
                </button>
            </Modal.Body>

        </Modal>
       }
    </div>
  )
}

export default EditAccount
