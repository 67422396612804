import React, { useContext, useEffect, useState } from 'react'
import HomeBanner from '../Component/HomeBanner'
import TitleBanner from '../Component/TitleBanner'
import NavBar from '../Component/NavBar'
import UserLogin from '../User/Login'
import axios from 'axios';
import { Storage } from '../Context/StateStore'
import Dustbin from '../SVG/Dustbin'
import StarIcon from '../SVG/StarIcon'
import { hostname } from '../App'
import Rupee from '../SVG/Rupee'
import Footer from '../Component/Footer'
import { useNavigate } from 'react-router'
import { Modal } from 'react-bootstrap'
import SideProduct from '../Component/SideProduct'
import { BestSeller } from '../Component/Data'
import HomeBestSeller from '../Component/HomeBestSeller'
const CartList = () => {
  let navigate = useNavigate()
  const [confirmation, setconfirmation] = useState(false)
  let [selectedId, setSelectedId] = useState()
  const { setshowmain, cartList, getCartList, getBanners, setMessageDetails, banners } = useContext(Storage)
  let imageurl
  if (banners) {
    imageurl = [...banners].find((x) => x.bannerName == 'Cart');
    // console.log(imageurl);
  }
  let user = JSON.parse(localStorage.getItem('user'))
  let [orderPriceDetails, setOrderPriceDetails] = useState({
    totalAmount: 0,
    totalQuantity: 0
  })
  let [orderProducts, setOrderProducts] = useState([])
  useEffect(() => {
    getCartList()
    getBanners()
    window.scrollTo(0, 0)

  }, [])
  useEffect(() => {
    let subvalue = (orderProducts.reduce((subtotal, item) => subtotal + (item.productId.price * item.quantity), 0))
    setOrderPriceDetails({
      totalAmount: Number(subvalue) < 500 ? Number(subvalue) : Number(subvalue),
      totalQuantity: orderProducts.reduce((ttl, item) => ttl + (item.quantity), 0)
    })
    localStorage.setItem('selectedItem', JSON.stringify(orderProducts))

  }, [orderProducts])
  let arry = new Array(30).fill(0)

  let handleDelete = () => {
    axios.delete(`${hostname}/api/cartList/${selectedId}`).then((response) => {
      setMessageDetails({
        visible: true,
        message: response.data.message,
        title: '',
        page: 'Cart List',
        status: 'success'
      })
      getCartList()
      setconfirmation(false)
    }).catch((error) => {
      setMessageDetails({
        visible: true,
        message: error.response.data.message,
        title: '',
        page: 'Cart List',
        status: 'error'
      })
    })

  }
  return (
    <div className=' poppins'>
      <NavBar />
      {imageurl && <TitleBanner page="Cart List" img={`${imageurl.imageUrl}`} />}
      <UserLogin />
      {
        user == null && <div className='text-center my-10'>
          <p className='text-xl'>  User hav'nt Logged in </p>
        </div>
      }
      {
        cartList != undefined && <>
          <h6 className='text-center text-2xl sm:text-4xl mt-5'>Cart List [{cartList.length} Item] </h6>

          <div className='my-10 row container mx-auto'>
            <section className='col-lg-8 h-[80vh] scrollbar overflow-y-scroll '>  
              {cartList != undefined && cartList.length > 0 ?
                cartList.map((x) => {
                  return (
                    <div className={`relative ${x.productId == undefined || x.ordered ? 'd-none' : ''} 
                    p-3 sm:px-5 flex my-5 flex-wrap ${x.productId&& x.productId.quantity==0?'bg-white':'bg-white' } justify-around rounded-xl mx-3 shadow`}>
                      <input type="checkbox" onChange={() => {
                        // console.log(orderProducts);
                        if (orderProducts.some((obj) => obj._id === x._id)) {
                          // console.log("Removed");
                          // Remove the product from the orderProducts array
                          setOrderProducts((prevOrderProducts) =>
                            prevOrderProducts.filter((obj) => obj._id !== x._id)
                          );
                        } else {
                          // console.log("Added");
                          // Add the product to the orderProducts array
                          setOrderProducts((prevOrderProducts) => [...prevOrderProducts, x]);
                        }
                      }} checked={orderProducts.some((obj) => obj._id == x._id)}
                        className={`absolute right-10 w-6 h-6 border-2 ${x.productId.quantity<=0&& 'd-none' } `} />
                      {
                        x.previewImage && x.previewImage.map((img)=>(
                          <img className=' shadow mx-4 mx-sm-0' src={img} alt="PreviewImage" width={80} />
                        ))
                      }
                      <div className='mx-3 my-2'>
                        <p>{x.productId && x.productId.productName} </p>
                        <p>{x.productId && x.productId.vatriety} </p>
                        <p className=''> <strike className={`text-slate-400 ${x.productId && x.productId.wholePrice != undefined ? '' : 'd-none'} `} > <Rupee /> {x.productId && x.productId.wholePrice * x.quantity}</strike>
                          <Rupee /> {x.productId && (x.productId.price) * x.quantity} <span className={`${x.quantity > 1 ? '' : 'd-none '} text-slate-400`}> (each <Rupee />{x.productId && x.productId.price} ) </span> </p>
                        <p className='text-slate-400'>Your Saving : ({x.productId && (x.productId.wholePrice - x.productId.price) * x.quantity})</p>
                      </div>
                      {/* Third coloumn */}
                      <div className='my-2'>
                        Quantity :
                        <select name="quantity" value={x.quantity} className='outline-none bg-transparent w-16'
                          onChange={(e) => {
                            axios.post(`${hostname}/api/quantityCart`, {
                              id: x._id,
                              quantity: e.target.value
                            }).then((response) => {
                              getCartList()
                            }).catch((error) => {
                              // console.log(error);
                            })
                          }} id="">
                          {
                            arry.map((x, index) => {
                              return (
                                <option value={index + 1}>{index + 1} </option>
                              )
                            })
                          }
                        </select>
                      </div>
                      {/* Buttons */}
                      <div className='my-auto'>
                        <button onClick={() => { setconfirmation(true); setSelectedId(x._id) }} className='flex items-center gap-3 p-2 px-3 text-lg text-red-500'>
                          <Dustbin /> Remove Product
                        </button>
                        <h4 className='text-center text-red-700'>
                          {x.productId.quantity<=0 && 'Out of Stock' }
                        </h4>
                        {/* <button className='flex items-center gap-3 p-2 px-3 text-lg text-blue-500'>
                          <StarIcon /> Move to Wishlist
                        </button> */}

                      </div>
                    </div>
                  )
                }) :
                <div className='flex h-hull min-h-[50vh]  m-auto'>
                  <div className='m-auto'>
                    <img className=' ' width={220} src={require('../Assest/CartImage.png')} alt="Cart Image" />
                   <p className='text-center'> Cart is empty !!! Shop now !!!</p>
                   <button onClick={()=>navigate('/shop')} className='mx-auto flex p-2 px-3 bg-purple-600 rounded text-white'>Take me  </button>
                  </div>

                </div>
              }
            </section>
            {/* Order Section */}
            {cartList != undefined &&
              <div className='col-lg-4'>
                <section className='bg-white border-2 my-5 border-slate-200 rounded-xl p-3 h-fit w-full' >
                  <p className=' text-xl text-center fw-semibold' >Order Deatils</p>
                  <hr />
                  {/* Sub total */}
                  <div className='flex justify-between my-2 text-lg'>
                    Sub Total : <span className='flex items-center'> <Rupee />
                      {orderProducts.reduce((subtotal, item) => subtotal + (item.productId.price * item.quantity), 0)}
                    </span>
                  </div>
                  <div className='flex justify-between my-2 text-lg'>
                    Shipping fee : <span className='flex items-center'>
                      {(orderProducts.reduce((subtotal, item) => subtotal + (item.productId.price * item.quantity), 0)) < 500 ? <>
                        free
                      </> : <> <strike className='mx-1 text-slate-500'></strike> Free

                      </>}
                    </span>
                  </div>
                  {/* Total */}
                  <hr />
                  <div className='flex fw-semibold justify-between my-2 text-lg'>
                    Total :
                    <span>{orderPriceDetails.totalAmount}  </span>
                  </div>
                  <button onClick={() => navigate('/checkout')} disabled={orderPriceDetails.totalQuantity <= 0 ? true : false} className={`w-full rounded p-3 bg-violet-600 text-white
                   ${orderPriceDetails.totalQuantity <= 0 ? 'bg-opacity-50' : 'opacity-100'} `}>Proceed to Buy ({orderPriceDetails.totalQuantity} Item)</button>
                </section>
                {/* <section className=' ms-auto border-2 my-5 border-slate-200 rounded-xl p-3 h-fit w-full' >
                      <h5 className='text-center  fw-semibold'> Products to check </h5>
                  <SideProduct />

                </section> */}
              </div>
            }


          </div>

        </>
      }
      {
        confirmation && <Modal centered show={confirmation} onHide={() => setconfirmation(false)} >
          <Modal.Header closeButton>
            Confirmation for the deletion
          </Modal.Header>
          <Modal.Body>
            Are you really want to delete ?
            <div className='my-3 flex justify-end'>
              <button onClick={() => handleDelete()} className='p-2 px-3 rounded mx-3 bg-red-600 text-white '> Yes </button>
              <button onClick={() => setconfirmation(false)} className='p-2 px-3 rounded bg-green-600 text-white '> No </button>

            </div>
          </Modal.Body>

        </Modal>

      }
      {/* <BestSeller/> */}
      <HomeBestSeller />

    </div>
  )
}

export default CartList
