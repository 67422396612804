import React, { useContext, useEffect, useState } from 'react'
import NavBar from '../Component/NavBar'
import TitleBanner from '../Component/TitleBanner'
import Product from './Product'
import Filter from './Filter'
import { Storage } from '../Context/StateStore'
import axios from 'axios'
import { hostname } from '../App'
import { useParams } from 'react-router'

const Shop = (props) => {
  let { Allproduct,getCartList,getAllProduct, getBanners,banners } = useContext(Storage)
  let { category, model, brand, variety } = useParams()
  let [product, setproduct] = useState()
 
  let [filterObj,setFilterObj]=useState()
  useEffect(() => {
    console.log("Category changed:", category);
    window.scrollTo(0,0)
    getBanners()
    if(brand==undefined&&category==undefined&&variety==undefined&&model==undefined){
     setproduct(Allproduct)}
    //Getting the product from the DB with the brand and model and variety
    else if (brand != undefined && model != undefined && variety != undefined) {
      axios.get(`${hostname}/api/productMBV?brand=${brand}&model=${model}&variety=${variety}`).then((response) => {
        setproduct(response.data)
        // console.log(response.data);
      }).catch((error) => { 
        // console.log(error); setproduct([]) 
        })
    }
    // Callin the category which are not mobile redirected form the Brands component
    else if (category != undefined) {
      axios.get(`${hostname}/api/productCategory?category=${category}`).then((response) => {
        setproduct(response.data)
      }).catch((error) => {
        setproduct([])
        // console.log(error);
      })
    }
    //List the products based on the  model name which has the params redirected from the 
    else if (model != undefined) {
      axios.get(`${hostname}/api/productModel/${model}`).then((response) => {
        // console.log(response.data);
        setproduct(response.data)
      }).catch((error) => {
        // console.log(error);
        setproduct([])
      })
    }
    else
      setproduct(Allproduct)
  }, [category, brand, variety, model,Allproduct])
  if(banners){
    var banner=[...banners].find((obj)=>obj.bannerName=='Shop')
  }
  return (
    <section id='home' className=''>
      <NavBar />
     {banner&& <TitleBanner name='Shop' img={banner.imageUrl} />}
      <div className='flex flex-col lg:flex-row'>
        <div className='pt-4 ps-3 pt-lg-0 ps-lg-0'>
          <Filter product={product} setproduct={setproduct} />
        </div>
        {
          product != undefined && product.length > 0 ? <Product products={product} className="flex-1" /> :
            <div className='h-[40vh] flex w-full '>
              <p className='m-auto w-fit text-center'>No Product in this Category {category}</p>
            </div>
        }
      </div>


    </section>
  )
}

export default Shop