import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import { hostname } from '../App'

export const Storage = createContext()
const StateStore = (props) => {
  const [showmain, setshowmain] = useState()
  const [Allproduct, setAllproduct] = useState()
  const [Allcaetgory, setcategory] = useState()
  const [user, setuser] = useState()
  const [addressModal, setAddressModal] = useState()
  const [userData, setUserData] = useState()
  const [wishList, setwishlist] = useState()
  const [cartList, setCartList] = useState()
  const [addressArry, setAddressArry] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [banners, setbanners] = useState()
  const [couponDB, setcouponDb] = useState()
  let [messageDetails, setMessageDetails] = useState({
    visible: false,
    message: '',
    page: '',
    status: '',
    title: ''
  })
  let userSet = () => {
    setuser(JSON.parse(localStorage.getItem('user')))
    if (JSON.parse(localStorage.getItem('user'))) {
      axios.get(`${hostname}/api/wishList/${JSON.parse(localStorage.getItem('user'))}`).then((response) => {
        setwishlist(response.data)
      }).catch((error) => {
        // console.log(error); 
      })
    }
  }
  let getCartList = () => {
    if (JSON.parse(localStorage.getItem('user'))) {
      axios.get(`${hostname}/api/cart/${JSON.parse(localStorage.getItem('user'))}`).then((response) => {
        // console.log(response.data);
        setCartList(response.data.filter((x) => x.ordered == false || x.ordered == null))
      }).catch((error) => {
        // console.log(error);
      })
    }
  }
  let wishlisthandler = (e, pid) => {
    e.preventDefault()
    if (user != null) {
      const obj = {
        userId: user,
        productId: pid
      }
      axios.post(`${hostname}/api/addWishlist`, obj).then((response) => {
        // console.log(response.data);
        userSet()
        alert(response.data.message)

      }).catch((error) => {
        // console.log(error);
      })
    }
    else
      setshowmain(true)
  }
  let getAllAddress = () => {
    axios.get(`${hostname}/api/userAddress/${JSON.parse(localStorage.getItem('user'))}`).then((response) => {
      setAddressArry(response.data)
      // console.log(response.data);
    }).catch((error) => {
      // console.log(error);
    })
  }
  const getUserData = () => {
    axios.get(`${hostname}/api/userId/${JSON.parse(localStorage.getItem('user'))}`).then((response) => {
      setUserData(response.data)
      // console.log(response.data);
    }).catch((error) => {
      // console.log(error);
    })
  }
  const allFont = ['Satisfy', 'Zeyada', 'Roboto Mono', 'Poppins', 'Permanent Marker', 'Inter', 'Orbitron', 'Jersey 25 Charted', 'Shojumaru', 'DotGothic16',
    'Macondo', 'Mountains of Christmas', 'Danfo', 'Oleo Script', 'Mate SC', 'Sedgwick Ave Display', 'Ephesis', 'Workbench',
    'Luckiest Guy', 'Sacramento', 'Yatra One', 'Monoton', 'Silkscreen', 'Special Elite', 'Audiowide', 'Protest Guerrilla',
    'Tapestry', 'Rubik Maze']
  let getAllProduct = () => {
    axios.get(`${hostname}/api/product`).then((response) => {
      setAllproduct(response.data)
    }).catch((error) => {
      //  console.log(error);
    })
  }
  useEffect(() => {
    getBanners()
    if (JSON.parse(localStorage.getItem('user'))) {
      getUserData()
      getAllAddress()
    }
    axios.get(`${hostname}/api/product`).then((response) => {
      setAllproduct(response.data)
    }).catch((error) => {
      // console.log(error);

    })
    axios.get(`${hostname}/api/category`).then((response) => {
      setcategory(response.data)
    }).catch((error) => {
      // console.log(error)

    })
  }, [JSON.parse(localStorage.getItem('user'))])
  let getBanners = () => {
    axios.get(`${hostname}/api/banner`).then((response) => {
      // console.log(response.data);
      setbanners(response.data)
    }).catch((error) => {
      // console.log(error);
    })
  }
  let getCoupon = () => {
    axios.get(`${hostname}/api/couponActive`).then((response) => {
      // setcouponDb(response.data)
    }).catch((error) => {
      // console.log(error);
    })
  }
  const valueExport = {
    userData, getUserData, setcouponDb, couponDB, getCoupon, allFont, banners, getBanners, getAllAddress, addressArry, setAddressArry, Allproduct, addressModal,
    setAddressModal, messageDetails, getAllProduct, setMessageDetails, cartList, getCartList, selectedProduct, setSelectedProduct, wishList, wishlisthandler, setwishlist, Allcaetgory, userSet, user, setuser, showmain, setshowmain
  }
  return (
    <Storage.Provider value={valueExport}>
      {props.children}
    </Storage.Provider>
  )
}

export default StateStore
