import React, { useContext, useEffect, useState } from 'react'
import Slider from 'react-slick'
import ProductCard from './ProductCard'
import axios from 'axios'
import { hostname } from '../App'
import StateStore, { Storage } from '../Context/StateStore'

const RecentProducts = () => {
  let { Allproduct } = useContext(Storage)
  let [bestsellerDB, setbestsellerDB] = useState()
  let getBestSellers = () => {
    axios.get(`${hostname}/api/bestseller/true`).then((response) => {
      setbestsellerDB(response.data)
    }).catch((error) => {
      // console.log(error);
    })
  }
  useEffect(() => {
    getBestSellers()
  }, [])
  let [tooltip, settooltip] = useState(false);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    arrows: false,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="xl:min-h-[89vh] sm:flex py-5 container">
      <div className="my-auto  mx-auto">
        <h3 className="poppins fw-bold text-center">Recent Products</h3>
        <p className="text-center">
          Discover our latest arrivals, featuring cutting-edge technology and innovative designs to enhance your everyday life. <br />
          Stay ahead with the newest trends and top-rated products now available.
        </p>
        {/* Card */}
        {Allproduct && Allproduct.length > 2 && <Slider {...settings} className=" container mx-auto my-5 p-2">
          {Allproduct && [...Allproduct].reverse().slice(0, 6).map((value) => {
            return (
              <ProductCard value={value} />
            );
          })}
        </Slider>}
      </div>
    </div>
  );
}

export default RecentProducts