import React, { useEffect } from 'react';
import NavBar from '../../Component/NavBar';

const AboutUs = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <div className="about-us p-6 pt-0 bg-white rounded shadow-lg">
      <NavBar/>
      <h1 className="text-2xl font-bold mb-4">Welcome to Printlele.com! </h1>
      <p className="mb-4">At Printlele (Aaglecha Enterprises) , we believe in the power of personalization. Whether you're looking to create a unique gift for a loved one or personalize your own belongings, we are here to help you bring your creative ideas to life.</p>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">Our Story</h2>
        <p className="mb-4">Founded in 2018, Printlele.com was born out of a passion for creativity and a desire to provide high-quality, customized products to customers around the world. What started as a small venture has grown into a trusted brand known for its commitment to excellence and innovation.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">Our Mission</h2>
        <p className="mb-4">Our mission is to make customization easy, fun, and accessible for everyone. We strive to offer a wide range of products that can be personalized to reflect your individual style and personality. From custom apparel and accessories to home decor and unique gifts, we have something for everyone.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">What We Offer</h2>
        <ul className="list-disc list-inside ml-4 mb-4">
          <li>Wide Range of Products: Explore our extensive collection of customizable items, including t-shirts, mugs, phone cases, tote bags, and more.</li>
          <li>High-Quality Materials: We use only the best materials to ensure that your customized products are durable and long-lasting.</li>
          <li>Easy Customization: Our user-friendly design tool allows you to create your own designs or choose from our wide selection of templates.</li>
          <li>Fast and Reliable Shipping: We understand that time is of the essence, which is why we offer fast processing and shipping to get your personalized items to you as quickly as possible.</li>
          <li>Exceptional Customer Service: Our dedicated customer service team is always here to help you with any questions or concerns you may have.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">Why Choose Printlele.com?</h2>
        <ul className="list-disc list-inside ml-4 mb-4">
          <li>Creativity Unleashed: At Printlele, you are the designer. Our platform gives you the freedom to create products that are uniquely yours.</li>
          <li>Affordable Prices: We believe that customization should be affordable. We offer competitive prices without compromising on quality.</li>
          <li>Customer Satisfaction: Your satisfaction is our top priority. We are committed to providing you with a seamless shopping experience and products that you will love.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">Join Our Community</h2>
        <p className="mb-4">We love seeing our customers’ creativity come to life! Join our community by following us on social media and sharing your custom creations with <span className="font-bold">#Printlele</span>. Stay up-to-date with the latest products, promotions, and design tips.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">Get in Touch</h2>
        <p className="mb-2">Have a question or need assistance? Our friendly customer service team is here to help! Contact us at:</p>
        <ul className="list-none ml-4 mb-4">
          <li>Email: <a href="mailto:info@printlele.com" className="text-blue-600 underline">info@printlele.com</a></li>
          <li>Phone: <a href="tel:8147474848">8147474848</a> </li>
          <li>Address: PrintLele, No.01, Office no.01, 2nd Floor, Sree Krishna Building, Tent Bus Stop, Begur Main Road, Hongasandra, Bengaluru- 560068.</li>
        </ul>
      </section>

      <p className="mb-4">Thank you for choosing Printlele.com. We look forward to helping you create something special!</p>
      <p className="font-bold">Printlele.com – Where Creativity Meets Quality</p>
    </div>
    </>
  );
};

export default AboutUs;
