import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { hostname } from '../../App'
import NavBar from '../../Component/NavBar'

const WorkflowInfo = () => {
    let [allWorks, setAllworks] = useState()
    useEffect(() => {
        axios.get(`${hostname}/api/workflow`).then((response) => {
            setAllworks(response.data);
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    return (
        <div>
            <NavBar/>
            <main className='container'>
                <h5>Workflow of the Application </h5>
                {
                    allWorks && allWorks.map((obj) => {
                        return (
                            <div className={`${obj.category == 'mobile' && 'd-sm-none '} ${obj.category == 'desktop' && 'd-none d-sm-block '} my-3 `}>

                                {obj.type_of_file == 'image' && <img className={`${obj.category == 'mobile' && 'd-sm-none'}`} 
                                src={obj.fileUrl} alt='Files' />}
                                {obj.type_of_file == 'video' && <video className={`${obj.category == 'mobile' && 'd-sm-none '}`} 
                                controls muted autoPlay src={obj.fileUrl} alt='Files' />}
                                <p className='text-xl'>{obj.description} </p>



                            </div>
                        )
                    })
                }

            </main>


        </div>
    )
}

export default WorkflowInfo