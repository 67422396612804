import React, { useContext, useEffect, useState } from 'react'
import NavBar from '../../Component/NavBar'
import { Storage } from '../../Context/StateStore'
import { useNavigate } from 'react-router'
import Rupee from '../../SVG/Rupee'
import axios from 'axios'
import { hostname } from '../../App'
const AddressChoose = () => {
  let navigate = useNavigate()
  let { getAllAddress, addressArry, couponDB, userData, getUserData, getCoupon, getCartList, setMessageDetails } = useContext(Storage)
  let [shippingAddress, setShippingAddress] = useState()
  let [orderPrice, setOrderPriceDetails] = useState({
    totalAmount: null,
    totalQuantity: null
  })
  let [orderCreated, setorderCreated] = useState()
  let [shipRocketCredentials, setShiprocketCredentials] = useState({
    email: null,
    password: '',
    pickupaddress: '',
    token: '',
    length: null,
    breadth: null,
    height: null,
    weight: null,
  })
  let [loading, setloading] = useState(false)
  let [couponId, setCouponId] = useState()
  let [text, setText] = useState()
  let orderProducts = JSON.parse(localStorage.getItem('selectedItem'))
  //place order in the ShipRocket
  let addShipment_id = (oid, shipment_id) => {
    axios.put(`${hostname}/api/Shipment_id`, {
      id: oid,
      spid: shipment_id
    }).then((response) => {
      setMessageDetails({
        visible: true,
        message: "Your Order has been placed!! ",
        title: '',
        page: 'Order Placing',
        status: 'success'
      })
      navigate('/profile/orders')
      // console.log(response.data);
    }).catch((error) => {
      // console.log(error);
    })
  }
  let handleOrderShiprocket = (oid, odate, orid) => {
    // console.log(shippingAddress);
    // console.log(userData);
    // console.log(shipRocketCredentials);
    // console.log(orderProducts);
    // console.log(orderPrice);
    // console.log(orderProducts.map((x) => {
    //   return {
    //     "name": x.productId.productName,
    //     "sku": x.productId.sku,
    //     "units": x.quantity,
    //     "selling_price": x.productId.price,
    //   }
    // }));

    let SRobjec = {
      "order_id": oid,
      "order_date": odate,
      "pickup_location": shipRocketCredentials.pickupLocation,
      "channel_id": "",
      "comment": "",
      "billing_customer_name": shippingAddress.fullName,
      "billing_last_name": "",
      "billing_address": shippingAddress.line1 ? shippingAddress.line1 : '',
      "billing_address_2": shippingAddress.line2 ? shippingAddress.line2 : '' + ',' +
        shippingAddress.landMark ? shippingAddress.landMark : '',
      "billing_city": shippingAddress.district,
      "billing_pincode": shippingAddress.pincode,
      "billing_state": shippingAddress.state,
      "billing_country": shippingAddress.country,
      "billing_email": userData.email,
      "billing_phone": shippingAddress.phone,
      "shipping_is_billing": true,
      "shipping_customer_name": "",
      "shipping_last_name": "",
      "shipping_address": "",
      "shipping_address_2": "",
      "shipping_city": "",
      "shipping_pincode": "",
      "shipping_country": "",
      "shipping_state": "",
      "shipping_email": "",
      "shipping_phone": "",
      "order_items": orderProducts.map((x) => {
        return {
          "name": x.productId.productName,
          "sku": x.productId.sku,
          "units": x.quantity,
          "selling_price": x.productId.price,
        }
      }),
      "payment_method": "Prepaid",
      "shipping_charges": 0,
      "giftwrap_charges": 0,
      "transaction_charges": 0,
      "total_discount": 0,
      "sub_total": orderPrice.totalAmount,

      "length": shipRocketCredentials.length,
      "breadth": shipRocketCredentials.breadth,
      "height": shipRocketCredentials.height,
      "weight": shipRocketCredentials.weight

    }
    // console.log(SRobjec);
    // console.log(shipRocketCredentials.token);
    //https://apiv2.shiprocket.in/v1/external/orders/create/adhoc
    axios.post(`${hostname}/api/shiprocketOrderPost`, 
    {data:SRobjec,token:shipRocketCredentials.token}).then((response) => {
      addShipment_id(orid, response.data.shipment_id)
      navigate('/profile/orders')
      // console.log(response.data);
    }).catch((error) => {
      // console.log(error);
    })
  }
  // Save method
  let handleSave = (pyid) => {
    axios.post(`${hostname}/api/order`, {
      userId: JSON.parse(localStorage.getItem('user')),
      shippingAddress: shippingAddress._id,
      productDetails: orderProducts.map((x) => x._id),
      totalQuantity: orderPrice.totalQuantity,
      amountPaid: orderPrice.totalAmount,
      status: 'order placed',
      payment_Id: pyid,
      couponUsed: couponId
    }).then((response) => {
      // console.log(response.data);
      setorderCreated(response.data)
      handleOrderShiprocket(response.data.orderId, response.data.orderedTime, response.data._id)
      getCartList()

    }).catch((error) => {
      // console.log(error);
    })
  }
  const handlePay = async () => {
    let id
    let amnt = orderPrice.totalAmount * 100
    // let amnt = 1 * 100

    setloading(true)
    await axios.post(`${hostname}/api/paymnetorder`, {
      amnt: amnt,
      receipt: JSON.parse(localStorage.getItem('user'))
    }).then((response) => {
      // console.log(response.data);
      id = response.data.id
    }).catch((error) => {
      // console.log(error);
    })
    var options = {
      "key": "rzp_live_ZhLfPMR06YREHH", // Enter the Key ID generated from the Dashboard
      // "one_click_checkout": true,
      // 'key_secret':'hfVmljUeVUBkjDSlSWTkayCj',
      "amount": amnt,
      'description': 'Order from the website PrintLele ',
      "name": "PrintLele", //your business name
      "order_id": id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "show_coupons": true, // default true; false if coupon widget should be hidden
      "handler": function (response) {
        const body = { ...response }
        axios.post(`${hostname}/api/paymentValidate`, body).then((response) => {
          // console.log(response.data);
          handleSave(response.data.payment_id)
        }).catch((error) => {
          // console.log(error);
        })
      },
      "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        "name": userData.username, //your customer's name
        "email": userData.email,
        "contact": userData.phone  //Provide the customer's phone number for better conversion rates 
      },
      "notes": {
        "address": "Razorpay Corporate Office"
      }
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      setMessageDetails({
        visible: true,
        message: "Something went wrong , try again Later",
        title: '',
        page: 'Payment',
        status: 'error'
      })
    });
    setloading(false)
    rzp1.open();
  }

  useEffect(() => {
    getCoupon()
    getUserData()
    let subvalue = (orderProducts.reduce((subtotal, item) => subtotal + (item.productId.price * item.quantity), 0))
    setOrderPriceDetails({
      totalAmount: Number(subvalue) < 500 ? Number(subvalue) : Number(subvalue),
      totalQuantity: orderProducts.reduce((ttl, item) => ttl + (item.quantity), 0)
    })
    // console.log(subvalue);
    // getAllAddress()
    window.scrollTo(0, 0)
    if (addressArry) {
      // console.log(addressArry);
      setShippingAddress(addressArry.find((obj) => obj.defaultStatus == true))
    }
  }, [addressArry])
  let [errorCoupon, setErrorCoupon] = useState()
  const checkCoupon = () => {
    // Find the coupon that matches the input text
    // console.log(couponDB);
    // console.log("....");
    const coupon = couponDB.find((x) => x.code === text);
    // If the coupon is found
    if (coupon) {
      if (orderPrice.totalAmount > coupon.amount) {
        // Calculate the new price with the discount applied
        let newPrice = (orderPrice.totalAmount * (1 - coupon.discountPercent / 100)).toFixed(2);

        // Update the order price details and clear any coupon error
        setOrderPriceDetails((prev) => ({
          ...prev,
          totalAmount: newPrice
        }));
        setCouponId(coupon._id);
        setErrorCoupon('');
      } else {
        // Set error if the order amount is less than the required amount for the coupon
        setErrorCoupon(coupon.description);
      }
    } else {
      // Set error if no coupon was found
      setErrorCoupon('* Coupon not found');
    }
  };

  // to disable the right click feature
  // useEffect(() => {
  //   const disableRightClick = (event) => {
  //     event.preventDefault();
  //   };
  //   document.addEventListener('contextmenu', disableRightClick);
  //   return () => {
  //     document.removeEventListener('contextmenu', disableRightClick);
  //   };
  // }, []);
  let getShipRocketToken = async (email, password) => {
    await axios.post(`https://apiv2.shiprocket.in/v1/external/auth/login`, {
      email: email,
      password: password,
    }).then((response) => {
      // console.log(response.data);
      setShiprocketCredentials((prev) => ({
        ...prev,
        token: response.data.token
      }))
    }).catch((error) => {
      // console.log(error);
    })
  }
  let getCreadentials = async () => {
    await axios.get(`${hostname}/api/shipRouter`).then((response) => {
      // console.log(response.data);
      setShiprocketCredentials(response.data)
      getShipRocketToken(response.data.email, response.data.password)
    }).catch((error) => {
      // console.log(error);
    })
  }
  useEffect(() => {
    getCreadentials()
  }, [])
  return (
    <div>
      <NavBar />
      {/* CheckOut Address */}
      <div className='container min-h-[70vh] mx-auto'>
        <button onClick={() => navigate('/profile/address')} className='flex ms-auto p-2 px-3 bg-slate-500 text-white rounded '>Add new Address </button>
        {addressArry && <>
          <p className='ms-2 my-3'>Choose the shipping address </p>
          <section className='row '>
            <div className='flex col-lg-9 col px-3 flex-wrap mb-3 h-fit justify-center sm:justify-between gap-3'>
              {shippingAddress &&
                addressArry.map((x) => {
                  return (
                    <div onClick={() => { setShippingAddress(x); }}
                      className={`w-[18rem] min-h-[14rem] p-3 border-1 rounded  `}>
                      <div className='cursor-pointer'>
                        <input type="radio" className='mx-2' checked={shippingAddress._id == x._id} id='shipping' name='a' />
                        <label className='mx-2' htmlFor="shipping">Shipping Address</label>
                      </div>
                      <p className='m-0 fw-semibold'>{x.fullName}</p>
                      <address className='m-0'>
                        {x.line1 + ", " + x.line2 + ", " + x.state + ", " + x.district + ", "}
                        <span className='block'>{x.country + " - " + x.pincode}</span>
                      </address>
                      <p className='m-0'>Phone no : <span> {x.phone} </span> </p>
                    </div>
                  )
                })}
            </div>
            {/* Payment */}
            {orderProducts &&
              <div className='col-sm-5 col-lg-3 border-1 h-fit'>
                <button onClick={() => {
                  if (shippingAddress) { 
                    handlePay()
                    // handleSave()
                   }
                  else {
                    setMessageDetails({
                      visible: true,
                      message: "Address is not added , Add a default address.",
                      title: '',
                      page: 'Order Placing',
                      status: 'error'
                    })
                  }
                }} disabled={loading} className='bg-teal-600 my-3 w-full p-2 rounded text-white'>{loading ? 'Loading...' : "Proceed to Buy"} </button>
                <hr />
                <p className='flex justify-between text-slate-500 text-xl'>SubTotal :
                  <span className='flex items-center'><Rupee />
                    {orderProducts.reduce((subtotal, item) => subtotal + (item.productId.price * item.quantity), 0)}
                  </span> </p>

                <p className='flex justify-between text-slate-500 text-xl'> Shipping Charge :
                  <span className='flex items-center'>
                    {orderProducts.reduce((subtotal, item) => subtotal + (item.productId.price * item.quantity), 0) > 500 ? <>
                      Free
                    </> : 'Free'}
                  </span> </p>
                <p className='flex justify-between text-xl fw-semibold'>Total Quantity :
                  <span className='flex items-center'> {orderPrice.totalQuantity} Products </span> </p>
                <p className='flex justify-between text-xl fw-semibold'>Total Amount :
                  <span className='flex items-center'><Rupee /> {orderPrice.totalAmount} </span> </p>
                <p className='h-[20px] text-green-600'>{couponId && '* coupon applied'} <span className='text-red-600'>{errorCoupon}</span> </p>
                {
                  couponDB && couponDB.length > 0 && <>
                    <p className='text-slate-500 flex text-xl' >
                      Coupon code : <input type="text" onChange={(e) => setText(e.target.value)} className='outline-none ms-auto border-b-2 px-1 w-32 ' />
                    </p>
                    <button onClick={checkCoupon} className='p-2 px-3 bg-slate-500 text-white rounded my-2 ms-auto flex ' type='button' > check </button>
                  </>
                }
              </div>
            }

          </section>
        </>
        }
      </div>
    </div>
  )
}

export default AddressChoose
