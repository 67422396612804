import React from 'react'
import { contactImg, locationimg, orderBox, profileEdit } from '../Media/ImageUse'
import {useNavigate} from 'react-router'
const ProfileCards = () => {
    let navigate=useNavigate()
    return (
        <div className='poppins w-full my-10'>
            <div className='flex justify-content-between w-full flex-wrap container  gap-3'>
                {/* Card one */}
                <article onClick={()=>navigate('/profile/orders')} className='p-3 w-[24rem] h-[10rem] border-1 cursor-pointer flex gap-2 items-center border-slate-300 rounded-lg bg-slate-50 hover:bg-slate-100 '>
                    <img src={orderBox} width={120} alt="Order box" />
                    <div>
                        <p className='fw-bold '>Your Orders</p>
                        <p>Track, check the Order</p>
                    </div>
                </article>
                <article onClick={()=>navigate('/profile/editAccount')} className='p-3   w-[24rem] h-[10rem] border-1 cursor-pointer flex gap-4 items-center border-slate-300 rounded-lg bg-slate-50 hover:bg-slate-100 '>
                    <img src={profileEdit} width={90} alt="Order box" />
                    <div>
                        <p className='fw-bold '>Your Profile Details</p>
                        <p>View and Edit Your personal details</p>
                    </div>
                </article>

                {/* <article className='p-3   w-[24rem] h-[10rem] border-1 cursor-pointer flex gap-4 items-center border-slate-300 rounded-lg bg-slate-50 hover:bg-slate-100 '>
                    <img src={profileEdit} width={90} alt="Order box" />
                    <div>
                        <p className='fw-bold '>Your Profile Details</p>
                        <p>View and Edit Your personal details</p>
                    </div>
                </article> */}

                <article onClick={()=>navigate('/profile/address')} className='p-3   w-[24rem] h-[10rem] border-1 cursor-pointer flex gap-4 items-center border-slate-300 rounded-lg bg-slate-50 hover:bg-slate-100 '>
                    <img src={locationimg} width={80} alt="Order box" />
                    <div>
                        <p className='fw-bold '>Your Addresses</p>
                        <p>Edit , Add , Delete Address </p>
                    </div>
                </article>

                {/* <article className='p-3   w-[24rem] h-[10rem] border-1 cursor-pointer flex gap-4 items-center border-slate-300 rounded-lg bg-slate-50 hover:bg-slate-100 '>
                    <img src={profileEdit} width={90} alt="Order box" />
                    <div>
                        <p className='fw-bold '>Your Profile Details</p>
                        <p>View and Edit Your personal details</p>
                    </div>
                </article> */}
                <article onClick={()=>navigate('/contact-us')} className='p-3   w-[24rem] h-[10rem] border-1 cursor-pointer flex gap-4 items-center border-slate-300 rounded-lg bg-slate-50 hover:bg-slate-100 '>
                    <img src={contactImg} width={90} alt="Order box" />
                    <div>
                        <p className='fw-bold '>Contact us</p>
                        <p>Enquiry About Products , Orders</p>
                    </div>
                </article>
                <article onClick={()=>{navigate('/');
                    localStorage.removeItem('user');
                    window.location.reload();
                }} className='p-3   w-[24rem] h-[10rem] border-1 cursor-pointer flex gap-4 items-center border-slate-300 rounded-lg bg-slate-50 hover:bg-slate-100 '>
                    <img src={require('../Assest/turn-off.png')} width={80} alt="Order box" />
                    <div>
                        <p className='fw-bold '>Log Out</p>
                        <p>Signing out , Keeping browsing</p>
                    </div>
                </article>
            </div>
        </div>
    )
}

export default ProfileCards
