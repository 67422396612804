import React, { useContext, useEffect, useState } from "react";
import NavBar from "../Component/NavBar";
import { useNavigate, useParams } from "react-router";
import TitleBanner from "../Component/TitleBanner";
import { Carousel, Col, Row } from "react-bootstrap";
import RelatedProduct from "./RelatedProduct";
import axios from "axios";
import { hostname } from "../App";
import { Storage } from "../Context/StateStore";
import Slider from "react-slick";
import SliderRight from "../Component/SliderRight";
import SliderLeft from "../Component/SliderLeft";
import ProductDescription from "../Component/ProductDescription";
import PlayIcon from "../SVG/PlayIcon";

const ProductPage = () => {
  let { wishList, userSet, wishlisthandler, getBanners, banners } = useContext(Storage)
  const { selectedProduct, setSelectedProduct } = useContext(Storage)
  let { name } = useParams();
  let navigate = useNavigate()
  let [quantity, setquantity] = useState(1);
  let [product, setproduct] = useState()
  let [imageshowing, setimageshowing] = useState()
  let [videoShowing, setVideoShow] = useState()

  useEffect(() => {
    window.scrollTo(0, 0)
    userSet()
    getBanners()
    axios.get(`${hostname}/api/productID?id=${name}`).then((response) => {
      setproduct(response.data)
      setSelectedProduct(response.data)
      setimageshowing(response.data.mainImage)
      // console.log(response.data);
    }).catch((error) => {
      // console.log(error)

    })
  }, [name])
  if (banners) {
    var banner = [...banners].find((obj) => obj.bannerName == 'Shop')
  }
  let settings = {

    speed: 500,
    slidesToShow: 4,
    // rtl: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,

    nextArrow: <SliderRight size={26} />,
    prevArrow: <SliderLeft size={26} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <section id="home" className="bg-slate-00">

      <NavBar />
      {
        product != null ? <>
          {banner && imageshowing && <TitleBanner name={product.productName} img={banner.imageUrl} />}


          <Row className="container justify-around  mx-auto py-5">
            <Col lg={5} className="my-3 min-h-[20rem]  rounded-3xl ">
              {!videoShowing && <img
                className="w-full h-[30rem]  object-contain "
                src={imageshowing}
                alt="productImage" />}
              {
                videoShowing && <video className="w-full h-[30rem]  object-contain " src={videoShowing} controls autoPlay   ></video>
              }
              {product.imageUrl && product.videoObj && product.imageUrl.length + product.videoObj.length > 3
                && <Slider {...settings} className="my-4 h-fit">
                  <img onClick={() => { setimageshowing(product.mainImage); setVideoShow(null) }}
                    className="w-[11rem] h-[8rem] transition duration-200 hover:scale-[1.04]  cursor-pointer object-contain " src={product.mainImage} alt="Mainimage" />
                  {
                    product.imageUrl && product.imageUrl.map((x, index) => (
                      <img onClick={() => { setimageshowing(x); setVideoShow(null) }}
                        className="w-[11rem] transition duration-200 hover:scale-[1.04] cursor-pointer h-[8rem] object-contain " src={x} alt="Image" />
                    ))
                  }
                  {
                    product.videoObj && product.videoObj.map((x, index) => (
                      <div onClick={() => setVideoShow(x.videoUrl)} className=" relative">
                        <video className="w-[11rem] transition duration-200 hover:scale-[1.04] cursor-pointer h-[8rem] object-contain"
                          src={x.videoUrl} alt='Video' ></video>
                        <div className="absolute bg-slate-950 bg-opacity-50 top-0 items-center justify-center text-slate-100 flex w-full h-full ">

                          <PlayIcon size={20} />
                        </div>
                      </div>
                    ))
                  }
                </Slider>}
              {product.imageUrl && product.videoObj && product.imageUrl.length + product.videoObj.length < 3 &&
                <div className="flex flex-wrap gap-3">
                  <img onClick={() => { setimageshowing(product.mainImage); setVideoShow(null) }}
                    className=" w-[10rem] h-[8rem] transition duration-200 hover:scale-[1.04]  cursor-pointer object-contain " src={product.mainImage} alt="Mainimage" />
                  {
                    product.imageUrl && product.imageUrl.map((x, index) => (
                      <img onClick={() => { setimageshowing(x); setVideoShow(null) }}
                        className="w-[10rem] transition duration-200 hover:scale-[1.04] cursor-pointer h-[8rem] object-contain " src={x} alt="Image" />
                    ))
                  }
                  {
                    product.videoObj && product.videoObj.map((x, index) => (
                      <div onClick={() => setVideoShow(x.videoUrl)} className=" relative">
                        <video className="w-[11rem] transition duration-200 hover:scale-[1.04] cursor-pointer h-[8rem] object-contain"
                          src={x.videoUrl} alt='Video' ></video>
                        <div className="absolute bg-slate-950 bg-opacity-50 top-0 items-center justify-center text-slate-100 flex w-full h-full ">

                          <PlayIcon size={20} />
                        </div>
                      </div>
                    ))
                  }

                </div>
              }
            </Col>
            <Col lg={6} className="my-3">
              <p className={` ${product.quantity > 0 ? 'text-green-400' : 'text-red-400'} fw-semibold poppins uppercase `}>
                {product.quantity > 0 ? "In Stock" : " Out of Stock"}
              </p>
              <h5 className="fw-bold text-2xl xl:text-3xl">{product.productName} </h5>
              <div className="flex gap-2 items-center">
                <img
                  className="w-20 h-fit"
                  src={require("../Assest/rating (2).png")}
                  alt="Rating"
                />
                (15 reviews)
              </div>

              <p className="fw-bold my-1 text-xl">Price : {product.price}</p>
              <p className="text-sm"> M.R.P :<strike className='fw-normal text-base'>{product.wholePrice} </strike> </p>
              <hr />
              <p className=" fw-semibold text-xl " >About this Product </p>
              {
                product.descriptionPoints.filter((obj) => obj != '').map((x, index) => (
                  <li>
                    {x}
                  </li>
                ))
              }
              <p className="text-sm ">
                {product.description}
              </p>
              <div className="flex flex-wrap flex-sm-nowrap  gap-3 fw-bold">
                {/* <div className="p-2 bg-slate-300 mx-auto flex items-center gap-3 rounded-full ">
                  <button
                    onClick={() => {
                      setquantity((prev) => {
                        if (prev == 1) return 1;
                        else return (prev -= 1);
                      });
                    }}
                    className="mb-0 cursor-pointer h-fit rounded-full w-8 p-1 text-center hover:bg-slate-50"
                  >
                    -
                  </button>
                  <p className="mb-0 w-10 mx-auto text-center"> {quantity}</p>
                  <button
                    onClick={() => setquantity((prev) => (prev += 1))}
                    className="mb-0  h-fit rounded-full  cursor-pointer w-8 p-1 text-center hover:bg-slate-50"
                  >
                    +
                  </button>
                </div> */}
                <button onClick={() => navigate(`/clipath/${product._id}`)} className="p-2 w-full px-3 order-2 order-sm-0  rounded-full bg-slate-950 text-slate-50  hover:bg-purple-700">
                  Customize
                </button>
                {/* <button onClick={() => navigate(`/clipath/${product._id}`)} className="p-2 px-3 mx-auto order-1  order-sm-0 rounded-full bg-purple-700 text-slate-50 w-32 ">
                  
                </button> */}
              </div>
              {/* Wishlist */}
              <div onClick={(e) => wishlisthandler(e, name)} className={`${wishList != undefined && wishList.some(obj => obj._id == name) ? 'text-amber-600' : ''} my-4 cursor-pointer flex items-center gap-2 text-slate-950`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-star"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                </svg>
                {wishList != undefined && wishList.some(obj => obj._id == name) ? 'Added in the Wishlist' : "Add to Wishlist"}
              </div>
              <hr />
            </Col>
          </Row>
          <ProductDescription category={product.category} />
          <RelatedProduct category={product.category} />
        </> : ""
      }
    </section>
  );
};

export default ProductPage;
