import React from 'react';

const SliderLeft = ({ size, className, style, onClick }) => {
    return (
        <div className={`transform -translate-y-1/2 tclr  absolute left-0 top-[50%] z-10 cursor-pointer`} onClick={onClick}>
            <svg onClick={onClick}
                xmlns="http://www.w3.org/2000/svg"
                width={size ? size : "16"}
                height={size ? size : "16"}
                fill="currentColor"
                viewBox="0 0 16 16"
                 // Custom styles applied directly to the SVG element
            >
                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
            </svg>
        </div>
    );
};

export default SliderLeft;
