import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { hostname } from '../App'

const ProductDescription = (props) => {
  const { category } = props
  const [descriptionobject, setDescription] = useState({
    category: null,
    desktopimages: null,
    mobileimages: null,
  })
  useEffect(() => {
    if (category) {
      axios.get(`${hostname}/api/productdescription/${category}`).then((res) => {
        setDescription(res.data)
      }).catch((error) => {
        // console.log(error);
      })
    }
  }, [category])
  return (
    <div className=''>

      <div className='container mx-auto'>
        {
          descriptionobject && <>
            <h4 className='mx-auto w-fit text-2xl sm:text-5xl poppins '>Product Details</h4>
            {/* Desktop image */}
            {
              descriptionobject.desktopimages &&
              <div>
                {
                  descriptionobject.desktopimages.map((img) => (
                    <img src={img} className={`my-3 ${descriptionobject.mobileimages&&descriptionobject.mobileimages.length<=0?'':'d-none d-sm-block  ' } `} alt="Desktop image" />
                  ))
                }
              </div>
            }
            {/* Mobile Image */}
            {
              descriptionobject.mobileimages && descriptionobject.mobileimages.length > 0 && descriptionobject.mobileimages.map((img) => (
                <img src={img} className='my-3 d-sm-none' alt="Mobile Image" />
              ))
            }
          </>
        }
        {/* <img className='rounded-3xl d-none d-sm-block mx-auto' src={require('../Assest/descriptionLap.webp')} alt="Description of the Laptp" />
        <img className='rounded-3xl my-2 d-sm-none mx-auto' src={require('../Assest/descriptionmobile1.webp')} alt="Description" />
        <img className='rounded-3xl my-2 d-sm-none  mx-auto' src={require('../Assest/description.webp')} alt="Description" />
        <img className='rounded-3xl my-2 d-sm-none  mx-auto' src={require('../Assest/description3.webp')} alt="Description" />
       */}
      </div>
    </div>
  )
}

export default ProductDescription