import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router'
import { hostname } from '../App'
import { Storage } from '../Context/StateStore'
import { emptyOrder, orderBox } from '../Media/ImageUse'
import SearchIcon from '../SVG/SearchIcon'
import Rupee from '../SVG/Rupee'
import Loading from '../Component/Loading'
import SideProduct from '../Component/SideProduct'
import RecentProducts from '../Component/RecentProducts'
const OrderPage = () => {
    let navigate = useNavigate()
    let { setshowmain } = useContext(Storage)
    const [orderDetails, setOrderDetails] = useState()
    let [filterArray, setfilterArry] = useState()
    let user = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        window.scrollTo(0,0)
        if (user) {
            axios.get(`${hostname}/api/order/${user}`).then((response) => {
                // console.log(response.data);
                setOrderDetails(response.data)
            }).catch((error) => {
                // console.log(error);
            })
        }
    }, [])
    useEffect(() => {
        if (orderDetails) { setfilterArry([...orderDetails].reverse().slice(0, 5)) }
    }, [orderDetails])
    let filterArryFun = (value) => {
        let datas = [...orderDetails].reverse()
        if (value == '5') {
            setfilterArry(datas.slice(0, 5))
        }
        if (value == '10') {
            setfilterArry(datas.slice(0, 10))
        }
        if (value == '0') {
            setfilterArry(datas)
        }
    }
    return (
        <div className='row container mx-auto'>
            <div className='max-h-[120vh] overflow-y-scroll scrollbar col-md-10 col-lg-8 '>

                {user != undefined ?
                    orderDetails != undefined && orderDetails.length > 0 ?
                        //Product is there Section
                        <div>
                            <section className='poppins mx-auto'>
                                <p className='text-2xl'>Your Orders</p>
                                <select name="" id="" className='outline-none ' onChange={(e) => filterArryFun(e.target.value)} >
                                    <option value="5">Last 5 Orders </option>
                                    <option value="10">Last 10 Orders </option>
                                    <option value="0">All Orders </option>

                                </select>
                                {/* <div className='flex gap-3 justify-end '>
                            <div className='p-2 flex gap-2 w-fit shadow items-center rounded-xl border-1 bg-inherit'>
                                <SearchIcon />
                                <input type="text" className='outline-none ' placeholder='Search the product' />
                            </div>
                            <button className='p-2 px-3 bg-slate-800 text-white rounded'>Search</button>
                        </div> */}
                                {/* Card create for the Order  */}
                                {filterArray && [...filterArray].map((x) => {
                                    // console.log(x);
                                    return (
                                        <div className='border-1 border-slate-400 my-3 mx-auto rounded  '>
                                            <section className='bg-slate-50 rounded flex-wrap gap-3 py-2 item-center flex justify-between px-3'>
                                                <div className='flex flex-wrap col-lg-7 justify-between gap-4'>
                                                    <div className='fw-light '>
                                                        Order Placed
                                                        <p className='m-0'>{x.orderedTime && x.orderedTime.slice(0, 10)} </p>
                                                    </div>
                                                    <div className='fw-light '>
                                                        Total
                                                        <p className='m-0'><Rupee /> {x.amountPaid} </p>
                                                    </div>
                                                    <div className='fw-light '>
                                                        Ship to
                                                        <p className='m-0'>{x.shippingAddress.line1}.. </p>
                                                    </div>
                                                </div>


                                                <div>
                                                    Order #{x.orderId}
                                                    <p onClick={() => navigate(`/profile/orders/${x.orderId}`)} className='block text-teal-500 cursor-pointer m-0' >View order Details </p>
                                                </div>
                                            </section>
                                            <div className='p-3'>
                                                <p> {x.status} </p>
                                                <div className='flex flex-wrap gap-3 justify-between w-full '>

                                                    {/* Image and the Name */}
                                                    {
                                                        x.productDetails.map((x) => {
                                                            return (
                                                                <div className='flex items-center my-1 gap-3'>
                                                                    { x.previewImage && x.previewImage.map((img)=>(
                                                                      
                                                                        <img width={60} src={img} alt="PreviewImage" />  
                                                                    ))
                                                                    }
                                                                    <a onClick={() => navigate(`/product/${x.productId._id}`)} className='cursor-pointer ' > {x.productId && x.productId.productName} </a>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    <div>
                                                        {/* <button onClick={() => navigate(`/profile/tracking/${x.orderId}`)} className='shadow bg-yellow-400 rounded p-1 w-56  '> Track Package </button> */}

                                                        <a href={`https://printlele.shiprocket.co/tracking/order/${x.orderId}`} target='_blank'><button  className='shadow bg-yellow-400 text-decoration-none text-black rounded p-1 w-56  '> Track Package </button>
                                                        </a> 
                                                        <button onClick={() => navigate(`/profile/orders/${x.orderId}`)} className='shadow p-1 block my-2 w-56 rounded px-2 border-1 '>   View order </button>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    )
                                })
                                }
                                <div>
                                </div>
                            </section>
                        </div>
                        //Empty orders
                        : orderDetails != undefined && orderDetails.length == 0 ? <div className='min-h-[50vh] poppins flex flex-col items-center justify-center '>
                            <h4 className='text-2xl my-5'>Order History </h4>
                            <img width={290} src={emptyOrder} alt="Empty Box" />
                            <p>You have not made any, Previous order !! </p>
                            <button onClick={() => navigate('/shop')} className='p-3 px-3 rounded my-2 bg-orange-600 text-white'>Go to Shopping 😉</button>
                        </div>

                            : <div className='h-[90vh] flex justify-center align-middle '>
                                <Loading />
                            </div>
                    : // Not Log in
                    <div className='h-[40vh] flex flex-col justify-center items-center'>
                        <h4>User havn't Logged in  </h4>
                        <button onClick={() => setshowmain(true)} className='p-2 px-3 rounded bg-slate-500 text-white '>Log IN </button>
                    </div>
                }

            </div>
            <div className='col-lg-4'>
                <section className=' ms-auto border-2 my-5 border-slate-200 rounded-xl p-3 h-fit w-full' >
                    <h5 className='text-center my-3'> Most ordered Products </h5>
                    <SideProduct />
                </section>
                <section className=' ms-auto border-2 my-5 border-slate-200 rounded-xl p-3 h-fit w-full' >
                    <h5 className='text-center my-3'> Frequently checked Products </h5>
                    <SideProduct />
                </section>
            </div>
            <RecentProducts />
        </div>
    )
}

export default OrderPage
