import React, { useState } from 'react'
import FilterBar from './FilterBar'
import { Offcanvas } from 'react-bootstrap'

const Filter = (props) => {
  const {product,setproduct}=props
  let [show,setshow]=useState()
  return (
    <>
      <button onClick={()=>setshow(!show)} className='poppins flex gap-3 items-center d-lg-none'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
  <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"/>
</svg>   Filter

      </button>
      <Offcanvas show={show} onHide={()=>setshow(false)} className='overflow-y-scroll overflow-x-hidden scrolledit pt-4 pe-2'>
        {/* Close button */}
        <button className=' ms-auto sticky-top' onClick={()=>setshow(false)} >
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
        </button>
        <FilterBar product={product} setproduct={setproduct} />


      </Offcanvas>

      <div className='d-none d-lg-block border-1 py-4 '>
        {/* Search button */}
        <FilterBar product={product} setproduct={setproduct}/>
      </div>
    </>
  )
}

export default Filter