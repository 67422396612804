import React, { useEffect, useState } from 'react'
import NavBar from '../../Component/NavBar'
import HomeIcon from '../../SVG/HomeIcon'
import { useNavigate } from 'react-router'

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  let navigate = useNavigate()
  return (
    <div className='min-h-[70vh] '>
      <NavBar />
      <h4 className='uppercase mx-auto text-center '> Get in touch with us </h4>
      <div className='container  '>
        <p className='mx-auto w-fit poppins text-slate-500 sm:w-4/5 my-4 text-center'>
          We’re here to help! Whether you have a question about our products, need assistance with your order, or just want to share your feedback, our
          dedicated customer service team is ready to assist you.</p>
        <div className='row container justify-between  inter mx-auto'>
          <section className='text-center col-sm-3'>
            <img className='mx-auto w-16 h-16 object-contain' src={require('../../Assest/Locatio.png')} alt="Location" />
            <p className='text-xl fw-semibold text-slate-900 '>Address</p>
            <p className='text-sm ' >
              Printlele (Aaglecha Enterprises), <br /> No.01, Office no.02 2nd Floor, Sree Krishna Building, Begur Rd, Maruthi Layout, Mico Layout, Hongasandra, Bengaluru, Karnataka 560068
            </p>
          </section>
          <section className='text-center col-sm-3'>
            <img className='mx-auto w-12 h-14 object-contain ' src={require('../../Assest/phone-call.png')} alt="Phone" />
            <p className='text-xl fw-semibold text-slate-900 '>Get In Touch</p>
            <a className='text-sm text-decoration-none ' href='tel:+918147474848' >
              <p className='text-sm text-decoration-none text-slate-950 '> Mobile : +91 8147474848</p> </a>
            <a className='text-sm text-decoration-none text-slate-950   ' href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCJlKFmbDgsgmFdQtpGlZQVqJRxQcHxsfgWnCSfPGqpkDwJbLhmpbLslWrkVdRkrLSBbSZGq" target='_blank'>
              <p className='text-sm text-decoration-none text-slate-950   ' >
                Email : info@printlele.com </p></a>
          </section>
          <section className='text-center col-sm-3'>
            <img className='mx-auto w-16 h-16 object-contain' src={require('../../Assest/clock.png')} alt="Clock" />
            <p className='text-xl fw-semibold text-slate-900 '>Hour of operation</p>
            <p className='text-sm ' >
              Monday - Saturday : 10am-6pm </p>
          </section>

        </div>
        <div className='h-[60vh] lg:h-[65vh] xl:h-[70vh]  rounded-3xl my-4 '>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.251688394473!2d77.6233976108857!3d12.891530987364037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15a656c6644b%3A0xbc50f10a14b0a179!2sPrintLele%20(Aaglecha%20Enterprises)!5e0!3m2!1sen!2sin!4v1715851571361!5m2!1sen!2sin"
            className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        {/* Customer Support */}
        <div className='poppins my-3'>
          <h6>Customer Support:</h6>
          •	Order Inquiries: Have a question about your order? Please include your order number and details about your inquiry in your email or when you call. <br />
          •	Customization Help: Need help designing your perfect product? Our team is here to assist you with any customization questions or issues. <br />
          •	Returns & Exchanges: For information on returns and exchanges, please refer to our <span className='text-blue-600 cursor-pointer' onClick={() => navigate('/return-policy')}> Return Policy.</span> <br />

        </div>
        {/* FeedBack */}
        <div className='poppins my-3'>
          <p className=''> <span className='fw-semibold'> Feedback:</span> We value your feedback and are always looking for ways to improve our products and services.
            Please share your thoughts and suggestions with us at [feedback@printlele.com].</p>
          <p className=''> <span className='fw-semibold'> FAQ:</span>  For quick answers to common questions, check out our <span className='text-blue-500 cursor-pointer '>FAQ page.</span> </p>
          <p>Thank you for choosing Printlele.com. We look forward to assisting you!</p>
        </div>
      </div>
    </div>
  )
}

export default ContactUs