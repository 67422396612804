import React, { useContext } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { Storage } from "../Context/StateStore";
import { useNavigate } from "react-router";

const HomeBanner = () => {
  const { banners } = useContext(Storage)
  let navigate = useNavigate()
  let carousel
  if (banners) {
    carousel = [...banners].filter((x) => x.bannerName == 'Home');
  }
  return (
    <div className=" bg-blue-200 ">
      <Carousel>
        {carousel && carousel.map((data, index) => {
          // console.log(data);
          return (
            <Carousel.Item key={index}
              className="relative"
            // style={{
            //   backgroundImage: `url(${data.imageUrl})`,
            //   backgroundSize: 'cover',
            //   backgroundPosition: 'center',
            //   width: '100%',
            // }} 
            >

              {<img src={data.imageUrl} alt="Image url"
                className={`object-cover absolute top-0 w-full h-full ${data.mobileImageurl!='' ? '' : ' d-none d-sm-flex'} `} />}

              {data.mobileImageurl&& <img src={data.mobileImageurl} alt="mobile Image url"
                className={`object-cover absolute top-0 w-full h-full ${data.mobileImageurl!='' ? ' d-sm-none ' : 'd-none '} `} />}

              <Row className="h-[60vh] relative container mx-auto ">
                <Col lg={6} className="fade-in-up py-5 flex flex-col ">
                  <div className=" mt-auto sm:my-auto h-fit mx-auto">
                    <h2 style={{ color: `${data.headingclr}` }} className="fw-bold display-2 poppins lg:pe-10">{data.heading} </h2>
                    <p style={{ color: `${data.textClr}` }} className="">{data.text}
                    </p>
                    <button onClick={() => navigate(`/category/${data.category}`)} className="text-white bg-violet-700 hover:bg-slate-900 transition duration-500 p-3 px-5 rounded-s-full rounded-e-full">
                      Shop now
                    </button>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default HomeBanner;
