import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Spinner } from 'react-bootstrap';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import LandingPage from './Component/LandingPage';
import { Suspense, lazy, useState } from 'react';
import './Component/Style.css'
import './Shoping/shopping.css'
import ProductPage from './Shoping/ProductPage';
import DesignerPage from './DesingEdit/DesignerPage';
import Myaccount from './Component/Myaccount';
import WishList from './Shoping/WishList';
import Brands from './Shoping/Brands';
import Modelshowing from './Shoping/Modelshowing';
import Variety from './Shoping/Variety';
import Shop from './Shoping/Shop';
import Personalize from './Pages/Personalize';
import CartList from './Pages/CartList';
import MobileBottomNav from './Component/MobileBottomNav';
import ProfilePage from './Pages/ProfilePage';
import Footer from './Component/Footer';
import ProfileRouting from './Pages/ProfileRouting';
import UserLogin from './User/Login';
import MessageCom from './Component/MessageCom';
import CheckOutRouter from './Pages/Ordering/CheckOutRouter';
import PersonalizeClipath from './Pages/ClipathPersonalize';
import './Font/font.css'
import ContactUs from './Pages/Info/ContactUs';
import TermsCondition from './Pages/Info/TermsCondition';
import ReturnRefund from './Pages/Info/ReturnRefund';
import ShippingPolicy from './Pages/Info/ShippingPolicy';
import PrivacyPolicy from './Pages/Info/PrivacyPolicy';
import AboutUs from './Pages/Info/AboutUs';
import WorkflowInfo from './Pages/Info/WorkflowInfo';
// Host name
// export const hostname = "http://localhost:3020"
export const hostname='https://backendapi.printlele.com'
function App() {
  let [user, setuser] = useState({})
  let Landing = lazy(() => import('./Component/LandingPage'))
  let Shopping = lazy(() => import('./Shoping/Shop'))
  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={
          <div className="flex mx-auto w-fit h-[100vh] my-auto align-items-center">
            <Spinner animation="border" />
          </div>}>
          <Routes>
            <Route path='/*' element={<Landing />} />
            <Route path='/shop' element={<Shopping />} />
            <Route path='/product/:name' element={<ProductPage />} />
            <Route path='/design/:type' element={<DesignerPage />} />
            <Route path='/myaccount' element={<Myaccount />} />
            <Route path='/wishlist' element={<WishList />} />
            <Route path='/cartlist' element={<CartList />} />
            <Route path='/checkout/*' element={<CheckOutRouter />} />
            <Route path='/profile/*' element={<ProfileRouting />} />
            <Route path='/personalize/:id' element={<Personalize />} />
            <Route path='/clipath/:id' element={<PersonalizeClipath />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/terms-condition' element={<TermsCondition />} />
            <Route path='/shipping-condition' element={<ShippingPolicy />} />
            <Route path='/about-us' element={< AboutUs/>} />


            <Route path='/order-workflow' element={< WorkflowInfo/>} />


            
            
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />       
            <Route path='/return-policy' element={<ReturnRefund />} />
            <Route path='/category/:category' element={<Brands />} />
            <Route path='/availablemodels/:brand' element={<Modelshowing />} />
            <Route path='/availablemodels/:brand/:model' element={<Variety />} />
            <Route path='/availablemodels/:brand/:model/:variety' element={<Shop />} />
          </Routes>
          <Footer />
          <MessageCom />
          <MobileBottomNav />
          <UserLogin />

        </Suspense>

      </BrowserRouter>


    </div>
  );
}

export default App;
