import React, { useEffect, useState } from 'react'
import NavBar from '../../Component/NavBar'
import { hostname } from '../../App'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router'
import HomeCategory from '../../Component/HomeCategory'
import HomeBestSeller from '../../Component/HomeBestSeller'
import CheckIcon from '../../SVG/CheckIcon'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const TrackingProcess = () => {
  let { id } = useParams()
  let navigate = useNavigate()
  let [orderDetails, setOrderDetails] = useState()
  let getParticularOrder = () => {
    axios.get(`${hostname}/api/particularOrderID/${id}`).then((response) => {
      // console.log(response.data);
      setOrderDetails(response.data)
    }).catch((error) => {
      // console.log(error);
    })
  }
  useEffect(() => {
    getParticularOrder()
  }, [])
  const steps = [
    'order placed',
    'process',
    'shipped',
    'out for delivery',
    'delivered'
  ];
  return (
    <div>
      {orderDetails &&
        <div className='col-lg-9 container mx-auto '>
          <div className='flex justify-between items-center'>
            <p className='fw-semibold'>Order Id : <span className='block sm:inline fw-normal   '>{orderDetails.orderId} </span> </p>
            <a onClick={() => navigate('/profile/orders')} className='text-teal-600 cursor-pointer text-decoration-none '>See all orders </a>
          </div>
          <div className='flex flex-wrap'>
            {
              orderDetails.productDetails.map((x) => (
                <img src={x.previewImage} width={50} alt="PrivewImage" />

              ))
            }
            
          </div>
          <hr className='border-2' />
          <h5 className='w-fit mx-auto'>{orderDetails.status} </h5>

          <Box sx={{ width: '100%' }} className="my-5" >
            <Stepper    alternativeLabel >
              {steps.map((label, index) => (
                <Step key={label} completed={index < steps.indexOf(orderDetails.status.toLowerCase())+1}>
                  <StepLabel className=''>
                    <p className={` sm:text-xl fw-semibold `} > {label} </p>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>


          {/* Address details */}
          <div className='flex flex-wrap gap-3 justify-around'>
            <div className=' border-4 w-[350px] p-3 ' >
              <p className='fw-semibold text-xl '>Shipping Address </p>
              <p className='text-sm text-slate-600 w-[300px]'>
                {orderDetails.shippingAddress.fullName}, <br />
                {orderDetails.shippingAddress.line1} , <br />
                {orderDetails.shippingAddress.line2 ? orderDetails.shippingAddress.line2 : ''} ,
                {orderDetails.shippingAddress.landMark ? orderDetails.shippingAddress.landMark : ''} ,
                {orderDetails.shippingAddress.district} ,<br />
                {orderDetails.shippingAddress.state} -
                {" " + orderDetails.shippingAddress.pincode}
              </p>

            </div>
            <div className='border-4 p-3 h-fit w-[350px] '>
              <p className='fw-semibold text-xl  '>Order Info </p>
              <button onClick={() => navigate(`/profile/orders/${orderDetails.orderId}`)} className='text-teal-600 '>View order </button>
            </div>

          </div>



        </div>
      }

      <HomeBestSeller />
      <HomeCategory />

    </div>
  )
}

export default TrackingProcess