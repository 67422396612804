import React, { useContext, useEffect, useState } from 'react'
import StateStore, { Storage } from '../Context/StateStore'
import axios from 'axios'
import { hostname } from '../App'
import { keyboard } from '@testing-library/user-event/dist/keyboard'

const FilterBar = (props) => {
    const { setproduct, product } = props
    let categoryList = [
        'Mobile Case', 'Cup', 'Tshirt', 'Book'
    ]
    let { Allcaetgory, Allproduct } = useContext(Storage)
    let [selectedcolor, setselectedcolor] = useState([])
    let handlecolor = (clr) => {
        setselectedcolor((prev) => {
            if (prev.indexOf(clr) > -1)
                return prev.filter((color) => color !== clr)
            else
                return [...prev, clr]
        })
    }
    let [search, setsearch] = useState()
    let [category, setcategory] = useState('')
    let [selectedPrice, setSelectedPrice] = useState([])
    let getBasedOnCategory = () => {
        axios.get(`${hostname}/api/productCategory?category=${category}`).then((response) => {
            setproduct(response.data)
        }).catch((error) => {
            // console.log(error);
            setproduct([])
        })
    }
    useEffect(() => {
        if (category == '') {
            setproduct(Allproduct)
        }
        else
            getBasedOnCategory()
    }, [category])
    let handleGlobalSearch = (e) => {
        axios.get(`${hostname}/api/productSearch?search=${search}`).then((response) => {
            setproduct(response.data)
            // console.log(response.data);
        }).catch((error) => {
            // console.log(error);
        })
    }
    let handlePrice = (st) => {
        if (selectedPrice.indexOf(st) == -1) {
            setSelectedPrice((prev) => [...prev, st])
        }
        else {
            setSelectedPrice((prev) => prev.filter((x) => x != st))
        }
    }
    useEffect(() => {
        if (selectedPrice.length === 0) {
            setproduct(Allproduct);
        } else {
            filterPrice();
        }
    }, [selectedPrice]);

    const filterPrice = () => {
        let newProducts = [];
        selectedPrice.forEach((st) => {
            if (st.indexOf('-') !== -1) {
                let [minPrice, maxPrice] = st.split('-').map(Number);
                // alert(minPrice+maxPrice);
                let filteredArray = Allproduct.filter((x) => x.price >= minPrice && x.price <= maxPrice);
                newProducts.push(...filteredArray);
            } else {
                let minPrice = Number(st.slice(0, st.indexOf('+')));
                let filteredArray = Allproduct.filter((x) => x.price >= minPrice);
                newProducts.push(...filteredArray);
            }
        });
        setproduct(newProducts);
    };
    return (
        <div>
            {/* Search box */}
            <div className='shadow poppins my-5 bg-slate-50 m-2 rounded-3xl e w-[20rem] p-4'>
                <h5>Search</h5>
                <div className=' p-2 px-3 my-1 items-center w-fit rounded-full border-1 flex outline-none focus-within:border-violet-700'>
                    <input value={search}
                        onKeyUp={(e) => {
                            if (e.key == 'Enter') {
                                handleGlobalSearch()
                            }
                        }}
                        onChange={(e) => setsearch(e.target.value)}
                        className='outline-none border-0 bg-transparent' type="text" />
                    {/* Search icon */}
                    <button onClick={handleGlobalSearch} className=''>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                    </button>
                </div>

            </div>
            {/* Product Category  */}
            <div className='shadow my-5 bg-slate-50 poppins m-2 rounded-3xl e w-[20rem] p-4' >
                <h5 className='mb-4'>Product Categories</h5>
                {Allcaetgory != undefined ?
                    Allcaetgory.map((value, index) => {
                        return (
                            <p key={index} onClick={() => {
                                if (category == value.category)
                                    setcategory('')
                                else
                                    setcategory(value.category)
                            }}
                                className={`cursor-pointer ${category == value.category ? 'text-violet-600' : ''}  text-slate-600 w-fit hover:text-violet-600 `}>{value.category} </p>
                        )
                    }) : ""
                }
            </div>
            {/* Filter price */}
            <div className={`shadow my-5 bg-slate-50 poppins m-2 rounded-3xl e w-[20rem] p-4`}>
                <h5>Filter by price</h5>
                <div className='flex flex-wrap gap-2  justify-between'>
                    <button onClick={() => handlePrice('100-300')} className={` ${selectedPrice.indexOf('100-300') != -1 ? 'text-violet-600' : ''} hoverup text-slate-600 w-fit hover:text-violet-600 `}>
                        100 - 300
                    </button>
                    <button onClick={() => handlePrice('301-500')} className={`${selectedPrice.indexOf('301-500') != -1 ? 'text-violet-600' : ''} hoverup text-slate-600 w-fit hover:text-violet-600 `}>
                        301 - 500
                    </button>
                    <button onClick={() => handlePrice('500+')} className={`${selectedPrice.indexOf('500+') != -1 ? 'text-violet-600' : ''} hoverup text-slate-600 w-fit hover:text-violet-600 `}>
                        500+
                    </button>
                </div>

            </div>
            {/* Products */}
            {/* <div className={`shadow my-5 bg-slate-50 poppins m-2 rounded-3xl e w-[20rem] p-4`}>
                <h5>Products</h5>
                <div className='flex my-2'>
                    {product && product.map((x, index) => {
                        return (
                            <div>

                            </div>
                        )
                    })

                    }
                    <img className='w-20' src={require('../Assest/badge.png')} alt="" />
                    <div className='w-fit ms-1'>
                        <p>Badge</p>
                        <p>&#8377; 234 </p>

                    </div>
                </div>


            </div> */}
            {/* Filter by color */}
            {/* <div className={`shadow my-5 bg-slate-50 poppins m-2 rounded-3xl e w-[20rem] p-4`}>
                <h5>Filter by Color</h5>
                <div className='flex justify-around gap-2 mt-4'>
                    Colors red
                    <div onClick={() => handlecolor('red')}
                        className={`${selectedcolor.includes('red') ? 'border-black border-2  p-1' : 'border-0'}  rounded-full cursor-pointer `}>
                        <p className={`${selectedcolor.includes('red') ? 'w-5 h-5 ' : 'w-8 h-8'} mb-0 rounded-full  bg-red-600 border-0 `}></p>
                    </div>
                    Colors yellow
                    <div onClick={() => handlecolor('yellow')}
                        className={`${selectedcolor.includes('yellow') ? 'border-black border-2  p-1' : 'border-0'}  rounded-full cursor-pointer `}>
                        <p className={`${selectedcolor.includes('yellow') ? 'w-5 h-5 ' : 'w-8 h-8'} mb-0 rounded-full  bg-yellow-600 border-0 `}></p>
                    </div>
                    Colors blue
                    <div onClick={() => handlecolor('blue')}
                        className={`${selectedcolor.includes('blue') ? 'border-black border-2  p-1' : 'border-0'}  rounded-full cursor-pointer `}>
                        <p className={`${selectedcolor.includes('blue') ? 'w-5 h-5 ' : 'w-8 h-8'} mb-0 rounded-full  bg-blue-600 border-0 `}></p>
                    </div>
                    Colors pink
                    <div onClick={() => handlecolor('pink')}
                        className={`${selectedcolor.includes('pink') ? 'border-black border-2  p-1' : 'border-0'}  rounded-full cursor-pointer `}>
                        <p className={`${selectedcolor.includes('pink') ? 'w-5 h-5 ' : 'w-8 h-8'} mb-0 rounded-full  bg-pink-400 border-0 `}></p>
                    </div>
                    Colors black
                    <div onClick={() => handlecolor('black')}
                        className={`${selectedcolor.includes('black') ? 'border-black border-2  p-1' : 'border-0'}  rounded-full cursor-pointer `}>
                        <p className={`${selectedcolor.includes('black') ? 'w-5 h-5 ' : 'w-8 h-8'} mb-0 rounded-full  bg-slate-950 border-0 `}></p>
                    </div>


                </div>
            </div> */}
        </div>
    )
}

export default FilterBar