import React, { useContext, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Storage } from '../Context/StateStore'
import { useNavigate } from 'react-router'

const HomeDummy2 = () => {
  let navigate = useNavigate()
  const { couponDB, getCoupon } = useContext(Storage)
  useEffect(() => {
    getCoupon()
    // console.log(couponDB);
  }, []);
  return (
   couponDB && couponDB.length>0 && <div className='my-10 xl:my-0 flex xl:min-h-[60vh]'>
      <Row className=' justify-around  container mx-auto my-auto  '>
        {
          couponDB && couponDB.map((x, index) => {
            return (
              <Col lg={5} key={index}
                // style={{backgroundImage:`url(${x.imageUrl})`}}
                className='overflow-hidden cardhover p-0 object-cover h-[17rem] my-5 relative rounded-3xl '>
                <img className=' rounded-3xl object-cover w-full h-full ' src={x.imageUrl} alt="tshirt poster" />
                <div style={{ backgroundColor: 'rgba(0,0,0,0.3)' }} className='absolute w-100  flex justify-end  h-100 right-0  top-0 '>
                  <div
                    className='flex flex-col justify-around pe-5'>
                    <p className='text-white fw-bolder text-2xl mb-0'>Flat {x.discountPercent} %</p>
                    <h4 className='text-white  text-2xl sm:text-4xl fw-bolder w-3/5 '>#{x.code} </h4>
                    <button onClick={() => navigate('/shop')} className='p-2 w-fit px-4 rounded-full fw-semibold bg-white'>Shop now</button>
                  </div>
                </div>
              </Col>
            )
          })
        }
      </Row>
    </div>
  
  )
}

export default HomeDummy2