import React, { useEffect } from 'react';
import NavBar from '../../Component/NavBar';

const PrivacyPolicy = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <NavBar/>

    <div className="privacy-policy container pt-0 p-6 bg-white rounded">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <section className="mb-6">
        <h2 className="text-xl font-semibold">1. Introduction</h2>
        <p>
          Welcome to printlele.com. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.printlele.com, purchase our customized products, or use our services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
        </p>
      </section>
      <section className="mb-6">
        <h2 className="text-xl font-semibold">2. Information We Collect</h2>
        <p>We may collect and process the following types of information:</p>
        <h3 className="text-lg font-semibold">Personal Information:</h3>
        <ul className="list-disc list-inside ml-4">
          <li>Name</li>
          <li>Email address</li>
          <li>Mailing address</li>
          <li>Phone number</li>
          <li>Payment information (processed through a secure third-party payment processor)</li>
        </ul>
        <h3 className="text-lg font-semibold mt-4">Non-Personal Information:</h3>
        <ul className="list-disc list-inside ml-4">
          <li>Browser type and version</li>
          <li>Operating system</li>
          <li>Referring website</li>
          <li>Pages you viewed on our site</li>
          <li>Time and date of visit</li>
          <li>Clickstream data</li>
        </ul>
        <h3 className="text-lg font-semibold mt-4">Customization Information:</h3>
        <ul className="list-disc list-inside ml-4">
          <li>Product preferences</li>
          <li>Uploaded images or designs</li>
          <li>Custom text or messages for products</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">3. How We Use Your Information</h2>
        <ul className="list-disc list-inside ml-4">
          <li>To process and fulfill your orders</li>
          <li>To personalize your experience and customize products</li>
          <li>To send you order confirmations, updates, and support messages</li>
          <li>To respond to customer service requests and support needs</li>
          <li>To improve our website and customer service</li>
          <li>To send you promotional emails (you can opt-out at any time)</li>
          <li>To comply with legal obligations</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">4. How We Share Your Information</h2>
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:</p>
        <ul className="list-disc list-inside ml-4">
          <li>
            <strong>Service Providers:</strong> We may share your information with third-party vendors who assist us in operating our website, conducting our business, or servicing you, provided those parties agree to keep this information confidential.
          </li>
          <li>
            <strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">5. Data Security</h2>
        <p>We use various security measures to protect your personal information. All payment transactions are processed through secure gateways and are not stored or processed on our servers. Despite these measures, we cannot guarantee absolute security.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">6. Your Rights</h2>
        <p>Depending on your location, you may have the following rights regarding your personal data:</p>
        <ul className="list-disc list-inside ml-4">
          <li>Access: You can request a copy of your personal data we hold.</li>
          <li>Rectification: You can request correction of any inaccurate or incomplete data.</li>
          <li>Deletion: You can request the deletion of your personal data under certain conditions.</li>
          <li>Objection: You can object to the processing of your data in some cases.</li>
          <li>Data Portability: You can request the transfer of your data to another service provider.</li>
        </ul>
        <p>To exercise any of these rights, please contact us at <a href="mailto:info@printlele.com" className="text-blue-600 underline">info@printlele.com</a>.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">7. Cookies and Tracking Technologies</h2>
        <p>
          Our website may use cookies and similar tracking technologies to enhance your experience. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your web browser that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">8. Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of such websites. We encourage you to read the privacy statements of each website you visit.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">9. Changes to This Privacy Policy</h2>
        <p>
          We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold">10. Contact Us</h2>
        <p>If you have any questions about this privacy policy, please contact us at:</p>
        <ul className="list-none ml-4">
          <li>Email: <a href="mailto:info@printlele.com" className="text-blue-600 underline">info@printlele.com</a></li>
          <li>Address: PrintLele, No.01, Office no.01, 2nd Floor, Sree Krishna Building, Tent Bus Stop, Begur Main Road, Hongasandra, Bengaluru- 560068.</li>
        </ul>
      </section>
    </div>
    </>
  );
};

export default PrivacyPolicy;
